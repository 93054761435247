<template>
	<div class="curve">
		<div class="erc-modal-content quaian">
		
		 <ul class="flex flex-x-s plr-20">
				<li class="mr-20">
					<Select v-model="group_name" style="width:100px" v-if="device.params" @on-change="equipQx">
						<Option :value="item.name" v-for="item in device.params.list_pgroup">{{item.name}}</Option>
					</Select>
				</li>
				<li class="  mr-20">
					<Select v-model="sc_key" style="width:200px" placeholder="在线状态" @on-change="equipQx1">
						<Option :value="item" v-for="(item,index) in canshow" :key="index">{{item}}</Option>
					</Select>
				</li>
				<li style="width:300px">
					<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end"
						placeholder="请选择日期" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
				</li>
				<button @click="fullPage" style="background-color: #48CBCC;color: #fff;height: 30px;line-height: 30px;padding: 0 10px;margin-left: 5px;border-radius: 3px;">搜索</button>
			</ul>
			<div id="chart3" class="area-box plr-20 "></div>
		</div>
	</div>
</template>

<script>
import { EleResize} from '../../public/a.js'
export default {
  data () {
    return {
		waring_num:0,
		waing_show:false,
		windowWidth: document.documentElement.clientWidth,
		height:document.documentElement.clientWidth>1024?268:110,
			columns1: [
					{
							title: '故障说明',
							key: 'description'
					},
					{
							title: '故障时间',
							key: 'create_time'
					},
					{
							title: '恢复时间',
							slot: 'update_time',
							key: 'update_time'
					},
			],
			is_show_sj: false, // 节能模式
			device_time: '',
			quan_show:false,
			is_show_qt: false, // 风速模式
			is_show_gz: false,
			is_show_ds: false,
			is_show_wd: false,
			is_show_zt: false,
			work: 0,
			wind: 0, // 风速模式
			data1: [],
			device_id: 0,
			device: {
			
				
			},
			list_err: [],
			list_optiontask: [],
			list_timetask: [],
			list_user: [],
			num_errs: 0,
			is_show: false,
			status: 0, // 定时状态
			time_range: '', // 定时时间
			task_id: 0, // 设备定时任务id
			dew: 0, // 当地天气
			icon: 0, // 当地天气图标
			setting_param: {}, // 工作模式
			options: {},
			status_set: 1,
			time_set: '',
			task_id_set: '',
			work_temp: 0, // 工作温度
			power: 0, // 下面是其他设置的一些内容
			qita: {},
			is_show_jz: false,
			basics: {}, // 下面是实时曲线
			group_name: '公用',
			time: [],
			date_range: '',
			sc_key: '',
			canshow: [],
			waring:[],
			token: localStorage.getItem('token'),
			device_title: '',
		}
  },
  //获取屏幕款第
 watch: {
	windowWidth(val) {
		let that = this;
		if(val<=1024){
			that.height = 100
		}
		console.log("实时屏幕高度：",val, that.windowWidth  );
	}

  },
  created () {
	  let that = this;
	  that.device_id = localStorage.getItem('device_id_s');
	  this.device_title = localStorage.getItem('device_title')||'历史曲线';
	  that.tqData()
	},

	mounted () {
		if(this.token){
            this.equipDetail()
			this.equipQx();
			
			
			this.fullPage()
			
		}
		  
	},
	
	
  methods: {
		
		
		
		
		
		// 修改设备定时
		showtimetask (task_id,time_range,status) {
			// console.log(task_id,time_range)
			let _this = this
			_this.task_id = task_id
			_this.time_range = time_range.split('-')
			_this.status = status
			_this.changeModal('is_show')
		},
		async submittimetask () {
			let _this = this,
					task_id = _this.task_id,
					status = _this.status,
					device_id = _this.device_id,
					time_range = _this.time_range.join('-');
			const res = await _this.post('device.operate/timetask_power', { task_id, status, device_id, time_range} )
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show')
			_this.equipDetail()
		},
		// 修改节能模式状态
		changeenergy (device_time) {
		
			this.device_time = device_time
			this.changeModal('is_show_sj')
		},
		async submitsjms () {
			let _this = this
			var opt = {device_time: _this.device_time}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_sj')
			_this.equipDetail()
		},
		// 修改风速状态
		changewind (wind) {
			this.qita.desc = wind.desc
			this.qita.name = wind.name
			this.qita.range = wind.range
			this.qita.value = wind.value
			this.changeModal('is_show_qt')
		},
		changeqita () {
			let _this = this,
					max = _this.qita.range[1],
					min = _this.qita.range[0];
			if (this.qita.value  < min) {
				return _this.util.showMsg('warning', '输入值低于最小参数' + min, 1.5, false)
			}
			if (this.qita.value  > max) {
				return _this.util.showMsg('warning', '输入值高于最大参数' + max, 1.5, false)
			}
		},
		async submitwind () {
			let _this =this
			let opt = {[_this.qita.name]: this.qita.value}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_qt')
			_this.equipDetail()
		},
		// 修改工作模式
		changework (work) {
			this.work = work + ''
			this.changeModal('is_show_gz')
		},
		async submitwork () {
			let _this =this
			let opt = {work_mode: this.work}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_gz')
			_this.equipDetail()
		},
		// 修改定时模式
		changeDs (item) {
			if (item) {
				this.options = JSON.parse(item.options)
				this.status_set = item.status
				this.time_set = item.time_set
				this.task_id_set = item.id
				this.changeModal('is_show_ds')
			} else {
				this.task_id_set = ''
				this.options = {}
				this.status_set = 1
				this.time_set = ''
				this.changeModal('is_show_ds')
			}
		},
		async submitDsms () {
			let _this = this,
					opt = JSON.stringify(_this.options),
					status = _this.status_set,
					time_set = _this.time_set,
					task_id = _this.task_id_set;
			const res = await _this.post('device.operate/timetask_option', {
				device_id: _this.device_id,opt,status,time_set,task_id
			})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_ds')
			_this.equipDetail()
		},
		// 修改温度
		changewd () {
			this.work_temp = this.device.status_info.work_mode==2?this.device.status_info.heat_temp:this.device.status_info.cold_temp
			this.changeModal('is_show_wd')
		},
		async submitwd () {
			let _this =this
			if (_this.device.status_info.work_mode==2) {
				var opt = {heat_temp: this.work_temp}
			} else {
				var opt = {cold_temp: this.work_temp}
			}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_wd')
			_this.equipDetail()
		},
		// 修改开关机状态
		changezt () {
			this.power = this.device.power
			this.changeModal('is_show_zt')
		},
		async submitzt () {
			let _this =this
			let opt = {power: this.power}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_zt')
			_this.equipDetail()
		},
		
		// 设备详情
		async equipDetail () {
			let _this = this,
					device_id = _this.device_id;
					_this.util.spinShow()
			const res = await _this.get('device/detail', {device_id,is_view:1});
			
			
			_this.list_user = res.list_user;
		
			_this.device = res.device;
			// console.log('device',res.device);
			 _this.list_err = res.list_err;
			 
			 _this.list_optiontask = res.list_optiontask;
			 _this.list_timetask = res.list_timetask;
			_this.waring_num= res.waring_num;
			_this.waring = res.waring;
			
			 _this.num_errs = res.num_errs;
			_this.setting_param = res.setting_param;
			_this.$Spin.hide()
		},
		
		showbasics () {
			
		},
		// 获取基础信息
		async basicsinfo (sn,pgid) {
			let _this = this
			const res = await _this.post('device/get_dmonvalues', {sn,pgid})
			_this.basics = res
			_this.changeModal('is_show_jz')
		},
		
		
		// 历史曲线
		async equipQx () {
			let _this = this;
			let {device_id,date_range,group_name} = _this;
			let sc_key = '';
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
			_this.canshow= res.canshow
			_this.sc_key = res.canshow[0];
			
			this.areaData3(res.data_x,res.data_y)
		},

		// 历史曲线
		async equipQx1 () {
			let _this = this,
					{device_id,date_range,group_name,sc_key} = _this;
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
			
			this.areaData3(res.data_x,res.data_y)
		},

			// 历史曲线
		async equipQx2 () {
			let _this = this,
					{device_id,date_range,group_name,sc_key} = _this;
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
			
			this.areaData3(res.data_x,res.data_y)
		},

		async exports(){
			let _this = this,
			{device_id,date_range,group_name,sc_key} = _this;
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key,is_export:1})
			var tempwindow=window.open('_blank');
            tempwindow.location=res.file_url;
		},
		

		// 获取当前温度
		async tqData () {
			const res = await this.get('basic/weather', {device_id: this.device_id})
			this.dew = res.temp
			this.icon = res.icon
		},
		
		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		changedate (e) {
			this.date_range = e[0] + ' - ' +e[1];
			
			//this.equipQx2()
		},
		//详情跳转
		  getNewsDetail(url) {
			  var tempwindow=window.open('_blank');
              tempwindow.location=url;
           
		  },
		  //fullPage全屏
		  async fullPage(){
			 
				let _this = this;
				_this.loading = true
				let {device_id,date_range,group_name,sc_key} = _this;
				const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
				_this.canshow= res.canshow
				this.areaData3(res.data_x,res.data_y)
		  },
		
		
	    areaData3 (xx, yy) {
			let _this = this
			var myChart1 = this.$echarts.init(document.getElementById("chart3"), 'shine');
			var option = {
						title: {
								text: ''
						},
						tooltip: {
								trigger: 'axis',
								axisPointer: {
										type: 'cross',
										label: {
												backgroundColor: '#6a7985'
										}
								}
						},
						legend: {
								show: false
						},
						grid: {
							left: '12%',
							top: '10%',
							bottom: '20%',
							right: 0,
						},
						dataZoom: [{
						        type: 'slider',
						        show: true,
						        start: 0,
						        end: 50,
						        handleSize: 8
						    },
						    {
						        type: 'inside',
						        start: 0,
						        end: 50
						    }
						],
						xAxis: {
								data: xx,
								axisLine: {
									show: true,
									lineStyle: {
											type: 'solid',
											color:'#2772E9',
											opacity: 0.6
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12
								},
								boundaryGap: ['00%','0%']
						},
		
						yAxis: {
								splitLine: {
									show: true,
									lineStyle: {
											type: 'dashed',
											color:'#2772E9',
											opacity: 0.4
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12,
								}
						},
						series: [
								{
										name: '当日',
										type: 'line',
										stack: '总量',
										smooth: true,
										lineStyle: {
												width: 1
										},
										showSymbol: false,
										areaStyle: {
												opacity: 1,
												color: {
												    type: 'linear',
												    x: 0,
												    y: 0,
												    x2: 0,
												    y2: 1,
												    colorStops: [{
												        offset: 0, color: 'rgba(25,104,255,1)' // 0% 处的颜色
												    }, {
												        offset: 1, color: 'rgba(255,255,255,0)' // 100% 处的颜色
												    }],
												    global: false // 缺省为 false
												}
										},
										emphasis: {
												focus: 'series'
										},
										data: yy
								}
						]
		  };
		  
			myChart1.setOption(option, true);
			var listener = () => {
			  myChart1.resize()
			}
			EleResize.on(document.getElementById("chart3"), listener)
		},
		
		roamMap(flag) {
		      const that = this;
			  var myChart_sd = this.$echarts.init(document.getElementById("chart3"), 'shine');
		      const currentZoom = myChart_sd.getOption().series[0].zoom // 当前的缩放比例
		      let increaseAmplitude = 1.2 // 点击按钮每次 放大/缩小 比例
		      if (flag === 1) {
		        increaseAmplitude = 0.8
		      }
		     myChart_sd.setOption({
		        series: {
		          zoom: currentZoom * increaseAmplitude
		        }
		      })
		    },
		    add() {
		      this.roamMap(0)
		    },
		    sub() {
		      this.roamMap(1)
		    },
	


	}
}
</script>

<style lang="less" >
	 // .meau-box{display: none;}
	.select_new{width: 100px;}
	.new_table{height: 281px;}
	.relative{
		position: relative;
	}
	.device_title{
		position: absolute;
		left: 32rem;
		top: -60rem;
		color: #fff;
		font-size: 20rem;
		font-weight: bold
		
	}
	.eq-detail {
		.eq-detail-info {
			width: 400rem;
			height: 473rem;
			overflow: auto;
			border: 1px solid #10BDC4;
			.title {
				height: 66rem;
				line-height: 66rem;
				background: linear-gradient(180deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
			}
		}
		.eq-detail-mx {
			border: 1px solid #10BDC4;
			.title {
				height: 52rem;
				background: rgba(0, 77, 83, 0.7);
			}
			.mx-img {
				width: 400rem;
				height: 309rem;
			}
		}
		.eq-detail-jk {
			max-width: 660rem;
			flex: 1;
			border: 1px solid #10BDC4;
			.title {
				height: 48rem;
				background: rgba(0, 77, 83, 0.7);
			}
			.mx-img {
				width: 100%;
				height: 476rem;
			}
		}
		.eq-zngk {
			// width: 500rem;
			// height: 580rem;
			border: 1px solid rgba(16, 189, 196, 0.4980392156862745);
			background: linear-gradient(1deg, rgba(3, 9, 9, 0.14) 0%, rgba(0, 77, 83, 0.7) 100%);
			.title {
				height: 50rem;
				// background: rgba(0, 77, 83, 0.7);
			}
			.day {
				// width: 312rem;
				height: 80rem;
				background: #3E80EA;
			}
			.baojin {
				height: 80rem;
				background:#FE9E47;
			}
			.baojin.ms {
				background: #6C45FE;
			}
			.baojin.wd {
				background: #37D3D2;
			}
			.baojin.tq {
				background: #36D4A1;
			}
			.icon-img {
				width: 42rem;
				height: 42rem;
			}
		}
		.eq-detail-ds {
			// width: 148rem;
			height: 38rem;
			line-height: 38rem;
			background: rgba(1, 169, 170, 0.2);
			border: 1px solid #10BDC4;
			.time {
				background: rgba(72,203,204,.5);
			}
		}
		.eq-detail-gg {
			width: 33.33%;
		}
		
		.state-icon {
			height: 24rem;
			padding: 0 13rem;
			line-height: 24rem;
		}
		.state-icon.success {
			 background: #5EB95E;
		}
		.state-icon.warning {
			 background: #DD514C;
		}
		.title-icon {
			width: 17rem;
			height: 17rem;
		}
		.jizu-icon {
			width: 80rem;
			line-height: 38rem;
			height: 38rem;
			background: rgba(72,203,204,.5);
		}
		.magnify {
			width: 18rem;
			height: 18rem;
		}
		// .area-box1 {
		// 	width: 80% !important;
		// 	height: 180rem !important;
		// }
		.area-box1 {
			width: 100%;
			height: 180rem;
		}
		.ivu-table th {
			height: 44px!important;
			background-color: rgba(0, 0,0, .1)!important;
			border-bottom: none;
			color: #fff;
			font-size: 14rem;
			font-weight: bold;
		}
		.ivu-table td {
			height: 44rem;
			background: rgba(1, 169, 170, 0.2);
			// border-bottom: 5px solid #111727;
			border-bottom: none;
			color: #fff;
			font-size: 14rem;
		}
		.ivu-table {
			background-color: rgba(0,0,0,0);
		}
		.ivu-table:before {
			background-color: rgba(0, 0,0, .1)!important;
		}
		.table-info-row {
			td {
				border-bottom: none!important;
				background: rgba(1, 169, 170, 0.24)!important;
			}
		}
		.table-error-row {
			td {
				border-bottom: none!important;
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}

	}
	
	
	.eq-wkq.model-pop-modal {
		height: auto;
		padding-bottom: 50rem;
	}
	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(../static/xunwen-pop.png) no-repeat center;
		background-size: 100% 100%;
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	
	}
	// 弹窗
	.model-pop-modal {
		width: 710rem;
		height: 478rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, #030909 0%, #004D53 100%);
		.title-text {
			width: 226rem;
			height: 43rem;
			line-height: 43rem;
			padding-left: 40rem;
			
			background: url(../static/title-bg.png) no-repeat center;
			background-size: 100% 100%;
		}
		textarea {
		   resize: none;  
		}
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	}
	
	.ivu-modal-body {
		padding: 0;
	}
	.ivu-modal-content {
		background-color: rgba(0,0,0,0);
	}
	.ivu-date-picker {
		border: 1px solid #01A9AA;
	}


// 弹窗
.model-pop-jc {
	width: 710rem;
	// height: 478rem;
	border: 1px solid #01A9AA;
	background: linear-gradient(360deg, #030909 0%, #004D53 100%);
	.info {
		width: 50%;
	}
	.title-text {
		width: 226rem;
		height: 43rem;
		line-height: 43rem;
		padding-left: 40rem;
		
		background: url(../static/title-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
}
.new_detail{
	color: #48CBCC;
	cursor: pointer;
}
.erc-modal-content{
	position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
	pointer-events: auto;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
	padding: 30px;
	position: relative;
}
.erc-modal-content .area-box{
	width: 108%;
	height:70vh;
	margin-left: -10%
}
.quaian canvas{
	    // left: -122px !important;
}

.erc-modal-content .quan1{
	position: absolute;
	right: 20px;
	font-size: 20px;
	top: 20px
}

@media (max-width:1024px){
		.title-icon{
			position: relative;
			img{
				position: absolute;
				top: -6rem;
			}
		}
		.eq-detail .eq-detail-info .title{height: 50rem;line-height: 50rem;}
		.eq-detail .eq-detail-info{height: 270rem;}
		.eq-detail .eq-detail-mx .mx-img{height: 220rem;}
		.device_title{top: -52rem;}
		.eq-detail .eq-detail-jk .mx-img{height: 300rem;}
		.new_table{height: 100px;overflow: auto;}
		.ivu-table-cell{padding: 4px !important;}
		.eq-detail .eq-zngk_new{height: 285rem;overflow: auto;}
		.select_new{width: 70px;}
		.day {padding-left: 10rem;padding-right: 10rem;}
		.meau-box{height: 630rem !important;}
	}
</style>

