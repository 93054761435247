<template>
  <div>
		
			<div class="map-box  flex">
				<!-- 左侧列表 -->
				<div class="map-list mr-15">
					<!-- 标签 -->
					<ul class="tabs-title flex flex-x-s t-c shou">
						<li class="active" @click="navigation('/equip/map')">项目用户</li>
						<li @click="navigation('/equip/map/list')">普通用户</li>
					</ul>
					<div class="pl-15 pr-20 mt-25">
						<Input v-model="value13" class="form-ipt" placeholder="请输入名称">
								<Button slot="append" icon="ios-search" @click="projectList"></Button>
						</Input>	
					</div>
					<div class="plr-30 mt-20 f-16 bold c-10b">项目设备（{{total}}）</div>
					<div class="map-pro mt-10">
						<ul class="f-14 pl-15 " style="padding-left: 30rem;">
							<li v-for="item,index in prolist" :key="index" @click="changeNav(index)" :class="project_id == item.project_id?'c-48c active relative':' c-ff'">{{item.name}}</li>
						</ul>
						<!-- 分页 -->
						<div class="flex flex-x-e" style="margin-top: 200rem;">
							<p class="f-14 c-ff mr-20 mt-10">总记录：{{total}}</p>
							<Page  :simple="true" :page-size="per_page" :total="total" @on-change="changepage" />
						</div>
					</div>
				</div>
				<!-- 右侧项目地图 -->
				<div class="flex-box map flex flex-dir-col flex-y-s">
					<div class="plr-20 ptb-15 f-16 bold c-ff">{{data1.length?'项目分布图':'暂未添加设备'}}</div>
					<div class="flex-box w100">
						<baidu-map ref="baidumap" class="map-detail" :scroll-wheel-zoom="true" v-if="data1[0]" :center="{lng: data1[0].longitude-0, lat: data1[0].latitude-0}" :zoom="15" mapType="BMAP_HYBRID_MAP"><!-- BMAP_SATELLITE_MAP -->
							
							<!-- 自定义覆盖物 -->
							<!-- 自定义覆盖物 -->
							<bm-overlay
								v-for="item,index in data1"
								:key="index"
							  ref="customOverlay"
							  :class="{sample: true}"
								:data-lng="item.longitude-0"
								:data-lat="item.latitude-0"
							  pane="labelPane"
							  @draw="draw">
							<div class="relative">
								<div @click="openpop(index)"><img src="../static/address.png" alt="" /></div>
								<div class="xm-pop-box absolute" v-if="project_pop && index == nav_index" :class="ishow_eq?'op1': ''">
									<div class="f-20 c-ff pt-30 pl-35">{{project_pop.device_title}}</div>
									<div class="absolute iconfont iconclose1 c-48c" @click="ishow_eq=false"></div>
									<ul class="plr-15 mt-25">
										<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">设备编号：</div>
											<div class="flex-box onelist c-48c">{{project_pop.code}}</div>
										</li>
										<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">创建时间：</div>
											<div class="flex-box onelist c-48c">{{project_pop.create_time}}</div>
										</li>
										<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">项目分类：</div>
											<div class="flex-box onelist c-48c" v-if="project_pop.category">{{project_pop.category.name}}</div>
										</li>
										<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">项目位置：</div>
											<div class="flex-box onelist c-48c">
											{{project_pop.province  + project_pop.city  + project_pop.region + project_pop.address}}
											</div>
										</li>
										<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">设备SN：</div>
											<div class="flex-box onelist c-48c">{{project_pop.sn}}</div>
										</li>
										<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">型号：</div>
											<div class="flex-box onelist c-48c">{{project_pop.category.code}}</div>
										</li>
								<!-- 		<li class="flex f-12 mt-15">
											<div class="mr-5 c-ff">创建人员：</div>
											<div class="flex-box onelist c-48c">{{project_pop.create_user.user.real_name?project_pop.create_user.user.real_name:'--'}}</div>
										</li> -->
									</ul>
									<div class="flex pt-30 plr-20" @click="navigationTo()">
									<!-- 	<div class="c-ff">截止时间: {{project_pop.end_time?project_pop.end_time:'--'}}</div> -->
										<div class="eq-enter-btn t-c f-18 c-ff">进入系统</div>
									</div>
								</div>
							</div>
							</bm-overlay>
						</baidu-map>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import myOverlay from './myoverly.vue'
export default {
  data () {
    return {
			ishow_eq: false,
			project_pop: null,
			nav_index: -1,
			value13:'',
			project_id: 0,
			project_name: '',
			prolist: [],
			total: 0,
			per_page: 0,
			data1: [],
		}
  },
	
	components: {
		myOverlay
	},

  created () {
	},

	mounted () {
		this.projectList()
	},
	
  methods: {
		aaabbb() {
			console.log(111);
		},
	// 分别跳转不同详情
		navigationTo () {
			localStorage.setItem('device_title',this.project_pop.device_title)
			if (this.project_pop.type == 0 || this.project_pop.type == 1) {
				this.$router.push({path: '/equip/list/detail', query: {id:this.project_pop.id}})
			} else if (this.project_pop.type == 2) {
				this.$router.push({path: '/equip/list/detail1', query: {id:this.project_pop.id}})
			} else {
				this.$router.push({path: '/equip/list/detail2', query: {id:this.project_pop.id}})
			}

		},
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		// enterPro () {
		// 	localStorage.setItem('project_id',this.project_id)
		// 	localStorage.setItem('project_name',this.project_name)
		// 	this.$router.push({path: '/equip/map/yt'})
		// },
		// 项目列表
		async projectList (page = 1) {
			let _this = this,
					{ search } = _this;
			const res = await _this.get('project/lists', {page,search:_this.value13})
			_this.prolist = res.list.data
			
			_this.total = res.list.total - 0
			_this.per_page = res.list.per_page -0
			if (res.list.data.length < 1) {
				_this.data1 = []
				return
			}
			_this.project_id = res.list.data[0].project_id
			_this.project_name = res.list.data[0].name
			_this.project_pop = res.list.data[0]
			_this.eqlistData()
		},
		
		// 获取设备列表
		async eqlistData (page = 1) {
			let _this = this,
					{  project_id } = _this;
			const res = await _this.get('device/lists_nopage', { project_id })
			
			this.data1 = res.list
			console.log('设备列表',res.list);
			
		},
		
		changepage (page) {
			this.projectList(page)
		},
		
		draw ({ el, BMap, map }) {
			
		  const pixel = map.pointToOverlayPixel(new BMap.Point(el.dataset.lng, el.dataset.lat))
		  el.style.left = pixel.x - 16 + 'px'
		  el.style.top = pixel.y - 16 + 'px'
			
		},
		
		showSBpop () {
			this.ishow_eq = !this.ishow_eq
		},
		openpop (index) {
			
			
			this.nav_index = index
			this.ishow_eq = true
			
			this.project_pop = this.data1[index]
			
		},
		changeNav (index) {
			this.nav_index = -1
			this.ishow_eq = false
			this.project_id = this.prolist[index].project_id
			this.project_name = this.prolist[index].name
			
			
			this.eqlistData()
			// let _this = this,
			// 		{  project_id } = _this;
			// const res = await _this.get('device/lists_nopage', { project_id })
			
			// _this.data1 = res.list
			// location.reload()
			// this.$nextTick()
			// console.log(this.$refs.baidumap);
		},
	}
}
</script>

<style lang="less" >
	// .map-box .ivu-page-item a, .map-box .ivu-page-next a, .map-box .ivu-page-prev a{
	// 	margin-top: 4rem;
	// }
.map-box >div{
	height: 790rem;
	
}
.flex-box.map {
		background: rgba(1, 169, 170, 0.1);
	}
.map-list {
	width: 280rem;
	background: url(../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;

}

// 标签页
.tabs-title {
	li {
		width: 120rem;
		height: 52rem;
		line-height: 52rem;
		border: 1px solid #48CBCC;
		color: #fff;
		opacity: 0.6;
	}
	li.active {
		background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
		opacity: 1;
	}
}
.map-pro {
	ul {
		color: #fff;
		opacity: .6;
		margin-top: 10rem;
	}
	li {
		margin-top: 10rem;
		cursor: pointer;
	}
	.ivu-collapse-simple,.ivu-collapse,.ivu-collapse>.ivu-collapse-item,.ivu-collapse-content {
		background-color: rgba(0,0,0,0);
		border: none;
	}
	.ivu-collapse-header {
		font-size: 16rem;
		color: #fff;
		opacity: .6;
	}
	.ivu-collapse-item-active .ivu-collapse-header{
		font-size: 16rem;
		color: #fff;
		opacity: 1;
	}
	
}
.map-detail {
	height: 100%;
	// width: 300px;
	// height: 300px;
}

.sample {
  width: 43rem;
	height: 63rem;
}
.sample.active {
  // background: rgba(0,0,0,0.75);
  // color: #fff;
}

.op1 {opacity: 1!important;}
.xm-pop-box {
	transition: opacity .3s; 
	left: 60rem;
	bottom: 0%;
	opacity: 0;
	transform: translateY(5%);
	width: 351rem;
	height: 396rem;
	background: url(../static/pro-bg.png) no-repeat center;
	background-size: 100%;
	.iconclose1 {
		top: 20rem;
		right: 12rem;
	}
	.eq-enter-btn {
		width: 129rem;
		height: 40rem;
		line-height: 40rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
	}
}
</style>
