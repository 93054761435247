<template>
  <div class="login relative">
		<vue-particles class="vue-particles" color="#dedede">
	
		</vue-particles>
		<notice ref="child"></notice>
		<div class="absolute login-box  flex-dir-col flex-c">
			<div class="login-title"><img src="../static/login-title.png" /></div>
			<div class="login-form mt-15 flex-dir-col flex-c">
				<div class="form-ipt plr-15 flex">
					<img src="../static/login-user.png" class="mr-30" alt="" />
					<input class="flex-box f-18 c-ff" placeholder="用户名" type="text" v-model="username" />
				</div>
				<div class="form-ipt plr-15 flex mt-20">
					<img src="../static/login-pwd.png" class="mr-30" alt="" />
					<input class="flex-box f-18 c-ff" placeholder="密码" type="password" @keyup.enter="bindlogin"  v-model="password" />
				</div>
				<!-- <div class="form-ipt plr-15 flex">
					<img src="../static/login-user.png" class="mr-30" alt="" />
					<input class="flex-box f-18 c-ff" placeholder="用户名" type="text" />
				</div> -->
				<div class="form-btn plr-15  mt-20 f-20 t-c c-00 shou" @click="bindlogin">
					登录
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import notice from './notice.vue'
export default {
  data () {
    return {
	    	video:'',
			username: '',
			password: '',
		}
  },
  components: {
  	notice:notice
  },

  created () {},

  methods: {
	 
		// 登录
		async bindlogin () {
			let _this = this,
					{ username, password } = _this;
					if (!username) {
						return _this.util.showMsg('warning', '请输入用户名', 2, true)
					}
					if (!password) {
						return _this.util.showMsg('warning', '请输入密码', 2, true)
					}
			const res = await _this.post('passport/login', {username,password})
			// console.log(res)
			if (!res) {
				_this.username = ''
				_this.password = ''
			}
			this.$refs.child.$emit('childmothods');
			
			localStorage.setItem('token', res.token);
			this.$refs.child.$emit('childmothods');
			localStorage.setItem('username', res.admin.username)
			localStorage.setItem('nickname', res.admin.nickname)
			localStorage.setItem('avatar', res.admin.avatar)
			_this.$router.push({path: '/home'})
		},
		refesh_list(){
			console.log('f')
		},
		enterKey () {
			var _this = this;
			document.onkeydown = function(e) {
				var key = window.event.keyCode;
				if (key == 13) {
					_this.bindlogin()
				}
			}
		},
		
	},
	
}
</script>

<style lang="less" scoped>
.login {
	overflow: hidden;
	height: 100%;
	width: calc(100vw - 10px);
	margin: 0 auto;
	background: url(../static/login-bg.png) no-repeat center;
	background-size: 100% 100%;
	.vue-particles {
		height: calc(100vh - 60px);
	}
	.login-title {
		width: 300rem;
		height: 44rem;
	}
	.login-box {
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
	}
	.login-form {
		width: 730rem;
		height: 490rem;
		background: url(../static/login-f-bg.png) no-repeat center;
		background-size: 100% 100%;
		.form-ipt {
			width: 520rem;
			height: 60rem;
			background: rgba(0,0,0,.4);
			img {
				width: 36rem;
				height: 36rem;
			}
			input::placeholder {
				color: rgba(255,255,255,.6);
				font-size: 18rem;
			}
		}
		.form-btn {
			width: 520rem;
			height: 60rem;
			line-height: 60rem;
			background: #0AFDFF;
			opacity: 0.4;
		}
	}
	
}
</style>
