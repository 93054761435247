<template>
  <div class=" site-basic data-box">
			<ul>
				<li class="flex flex-x-s flex-y-s">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">APP轮播图</div>
					<div class="flex-box">
						<Upload action="https://www.kochem.net.cn/wap/upload/image" 
						name="file" 
						:headers="{token}"
						:on-success="uploadSuccess"
						:show-upload-list="false"
						accept="image/png,image/tiff,image/jpeg"
						:format="['jpg','jpeg','png','tiff']"> 
							<div class="table-btn flex-c" style="width: 132rem;">
								<span class="iconfont iconshangjia3 f-14 c-ff"></span>
								<span class="f-14 c-ff ml-8">选择图片</span>
							</div>
						</Upload>
						<ul class="flex flex-x-s pr-40">
							<li class="upload-img mr-20 relative" v-for="item,index in banner">
								<div class="iconfont iconclose1 absolute c-48c" @click="removeImg(index)"></div>
								<img :src="item.file_path" />
							</li>
						</ul>
						<p class="f-14 c-10b">建议高度：375*190</p>
					</div>
				</li>
				<li class="flex flex-x-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">用户总数</div>
					<div><Input v-model="params.total_user" placeholder="请填写" type="number" style="width: 500px"  class="b-b" /></div>
				</li>
				<li class="flex flex-x-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">设备总数</div>
					<div><Input v-model="params.total_device" placeholder="请填写" type="number" style="width: 500px"  class="b-b" /></div>
				</li>
				<li class="flex flex-x-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">在线用户量</div>
					<div><Input v-model="params.online_user" placeholder="请填写" type="number" style="width: 500px"  class="b-b" /></div>
				</li>
				<li class="flex flex-x-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">在线设备量</div>
					<div><Input v-model="params.online_device" placeholder="请填写" type="number" style="width: 500px"  class="b-b" /></div>
				</li>
				<li class="flex flex-x-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">面积基数</div>
					<div><Input v-model="params.base_area" placeholder="请填写" type="number" style="width: 500px"  class="b-b" /></div>
				</li>
				<li class="flex flex-x-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">碳排量基数</div>
					<div><Input v-model="params.base_co2" placeholder="请填写" type="number" style="width: 500px"  class="b-b" /></div>
				</li>
			
				<li class="flex flex-x-s flex-y-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">设备视频图片</div>
					<div class="flex-box">
						<Upload action="https://www.kochem.net.cn/wap/upload/image" 
						name="file" 
						:headers="{token}"
						:on-success="uploadSuccess1"
						:show-upload-list="false"
						accept="image/png,image/tiff,image/jpeg"
						:format="['jpg','jpeg','png','tiff']"> 
							<div class="table-btn flex-c" style="width: 132rem;">
								<span class="iconfont iconshangjia3 f-14 c-ff"></span>
								<span class="f-14 c-ff ml-8">选择图片</span>
							</div>
						</Upload>
						<ul class="flex flex-x-s pr-40 mx-img mt-20" v-if="video_img">
							<img :src="video_img" />
						</ul>
						<p class="f-14 c-10b">建议高度：660*476</p>
					</div>
				</li>
				<li class="flex flex-x-s flex-y-s mt-40">
					<div class="list-lefttitle t-r f-18 c-ff must mr-20">设备模型图片</div>
					<div class="flex-box">
						<Upload action="https://www.kochem.net.cn/wap/upload/image" 
						name="file" 
						:headers="{token}"
						:on-success="uploadSuccess2"
						:show-upload-list="false"
						accept="image/png,image/tiff,image/jpeg"
						:format="['jpg','jpeg','png','tiff']"> 
							<div class="table-btn flex-c" style="width: 132rem;">
								<span class="iconfont iconshangjia3 f-14 c-ff"></span>
								<span class="f-14 c-ff ml-8">选择图片</span>
							</div>
						</Upload>
						<ul class="flex flex-x-s pr-40 mo_img mt-20" v-if="model_img">
							<img :src="model_img" />
						</ul>
						<p class="f-14 c-10b">建议高度：400*270</p>
					</div>
				</li>
				
				<li>
					<div class="table-btn submit plr-25 t-c mb-30" style="width: 132rem;" @click="bindsubmit">
						<span class="f-14 c-ff t-c">提交</span>
					</div>
				</li>
			</ul>

	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			model_img:'',
			video_img:'',
		    params:{
				total_user:'',
				total_device:'',
				online_user:'',
				online_device:'',
				base_area:'',
				base_co2:'',
				vedio_img_id:'',
				model_img_id:''
			},
			banner: [],
			token: localStorage.getItem('token')
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.setInfo()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		// 提交
		async bindsubmit () {
			let _this = this,
					banner_ids = _this.banner.map(x => x.file_id);
			_this.params.banner_ids = banner_ids.join(',')
					console.log(_this.params)
			// console.log(banner_ids)
			const res = await _this.post('site/setting', _this.params)
			_this.util.showMsg('success', '保存成功',2)
			_this.setInfo()
		},
		
		// 获取设置信息
		async setInfo () {
			let _this = this
			const res = await _this.get('site/setting')
			_this.banner = res.setting.banners;
			_this.params.total_user = res.setting.total_user;
			_this.params.total_device = res.setting.total_device;
			_this.params.online_user = res.setting.online_user;
			_this.params.online_device = res.setting.online_device;
			_this.params.base_area = res.setting.base_area;
			_this.params.base_co2 = res.setting.base_co2;
			_this.params.vedio_img_id = res.setting.vedio_img_id;
			_this.params.model_img_id = res.setting.model_img_id
			_this.video_img = res.setting.vedio_img.file_path;
			_this.model_img = res.setting.model_img.file_path
		},
		
		// 删除图片
		removeImg (index) {
			let _this = this
			_this.banner.splice(index,1);
		},
		// 上传成功
		uploadSuccess (response, file, fileList) {
			// console.log(response)
			this.banner.push(response.data)
		},

        //视频图片
		uploadSuccess1 (response, file, fileList) {
			// console.log(response)
			this.video_img  = response.data.file_path;
			this.params.vedio_img_id = response.data.file_id
		},

		//模型图片
		uploadSuccess2 (response, file, fileList) {
			// console.log(response)
			this.model_img  = response.data.file_path;
			this.params.model_img_id = response.data.file_id
		},
		
	}
}
</script>

<style lang="less">
.mx-img{
		max-width: 660rem;
		height: 476rem;
}
.mo_img{
	width: 400rem;
    height: 270rem;
}
.site-basic {
	padding-top: 70rem;
	.list-lefttitle {
		width: 250rem;
	}
	
	.list-lefttitle.must::after {
		content: '*';
		color: #F16252;
		font-size: 18rem;
		margin-left: 6rem;
	}
	.b-b {
		border-bottom: 1px solid #01A9AA;
	}
	.upload-img {
		width: 375rem;
		height: 190rem;
		margin: 18px 10px 14rem;
		.iconclose1 {
			right: -15rem;
			top: -15rem;
			width: 30rem;
			height: 30rem;
			line-height: 30rem;
			border-radius: 30rem;
			text-align: center;
			background: rgba(0,0,0,.5);
		}
	}
	.table-btn.submit {
		width: 140rem;
		height: 40rem;
		line-height: 40rem;
		margin: 40px 0 0 269rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
	}
}
.site-basic .table-btn.submit{
	margin: 40px 0 30px 269rem
}
</style>
