<template>
  <div class="refund-role">
				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20 mb-10" @click="navigation('/site/roleadd')"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
					</ul>
		
					</ul>
					<!-- 状态以及统计 -->
					<div class="table-box">
						<Table :columns="columns1" :data="list" class="" disabled-hover max-height="722">
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c shou" @click="navigation('/site/roledetail',{id: row.id})">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8 shou"  @click="sureDel(row.id)">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template>
						</Table>
					</div>
				</div>
				
				<Modal
						:footer-hide="true"
						v-model="is_show_del"
						:closable="false"
						width="471"
						:mask-closable="false"
					>
						<span slot="title"></span>
						<div class="model-pop-jn eq-jn plr-20">
							<ul class="flex pt-20 flex-y-s flex-x-e">
								<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_del')"></li>
							</ul>
							<div class="f-24  t-c pt-50 c-48c">是否确定删除？</div>
							<li class="flex flex-c mt-50" >
								<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_del')">取消</div>
								<div class="queding f-18 c-ff t-c shou" @click="submitdel">确认</div>
							</li>
						</div>
				</Modal>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			value13: '',
			is_show_del: false,
			role_id: 0,
			columns1: [
					{
							title: '角色ID',
							key: 'id'
					},
					{
							title: '角色名称',
							key: 'name',
							className: 'name-color'
					},
					{
							title: '角色说明',
							key: 'desc',
					},
					{
							title: '添加时间',
							key: 'create_time'
					},
					{
							title: '操作',
							slot: 'handle',
							width: 200
					}
					
			],
			list: []
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.rolelist()
	},

  methods: {
		// 跳转路由
		navigation (path, query) {
			this.$router.push({path,query})
		},
		
		// 获取角色列表
		async rolelist () {
			let _this = this
			const res = await _this.get('auth.role/lists')
			_this.list = res.list
		},
		
		sureDel (id) {
			this.role_id = id
			this.changeModal('is_show_del')
		},
		
		async submitdel () {
			let _this = this
			const res = await _this.post('auth.role/delete', { role_id: _this.role_id })
			_this.util.showMsg('success', '操作成功', 1.5 , false)
			this.changeModal('is_show_del')
			_this.rolelist()
		},
		
		changeModal (type) {
			this[type] = !this[type]
		},
	},
	
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.ivu-modal-content{
	background: transparent
}
.model-pop-jn{
	width: 471rem;
    height: 311rem;
    background: url(/img/xunwen-pop.a8330790.png) no-repeat 50%;
    background-size: 100% 100%;
}
.refund-role {
	.data-box {
		overflow-y: hidden;
	}
	.dight {
		width: 6rem;
		height: 6rem;
		background: #8FF38E;
		border-radius: 3rem;
		margin-right: 10rem;
	}
	.tab-state {
		width: 90rem;
		height: 32rem;
		line-height: 30rem;
		border: 1px solid #48CBCC;
		opacity: 0.6;
		transition: all .4s;
	}
	.active.tab-state {
		background-color: #48CBCC;
		opacity: 1;
	}
}
</style>
