<template>
  <div class="waste"> 
		<ul class="flex" style="flex-wrap: nowrap;">
			<li class="total-data relative  p-20">
				<ul class="flex flex-y-s">
					<li class="flex-box">
						<p class="c-55f f-16">工单状态</p>
						<div id="state1" class="state-box  mt-10"></div>
					</li>
					<li class="flex-box ml-50">
						<p class="c-55f f-16">各备件使用情况（件）</p>
						<ul class="flex">
							<li class="mt-20" style="width: 50%;">
								<i-circle
										:size="80"
										:trail-width="8"
										:stroke-width="8"
										:percent="10"
										stroke-linecap="round"
										trail-color="#04325F"
										stroke-color="#55FFEF">
										<div>
												<p class="c-55f f-16">126</p>
												<p class="c-55f" style="font-size: 8rem;">空调机盖</p>
										</div>
								</i-circle>
							</li>
							<li class="mt-20" style="width: 50%;">
								<i-circle
										:size="80"
										:trail-width="8"
										:stroke-width="8"
										:percent="10"
										trail-color="#04325F"
										stroke-linecap="round"
										stroke-color="#F79D27">
										<div>
												<p class="c-55f f-16">126</p>
												<p class="c-55f" style="font-size: 8rem;">空调机盖</p>
										</div>
								</i-circle>
							</li>
							<li class="mt-20" style="width: 50%;">
								<i-circle
										:size="80"
										:trail-width="8"
										:stroke-width="8"
										:percent="10"
										stroke-linecap="round"
										trail-color="#04325F"
										stroke-color="#E71E74">
										<div>
												<p class="c-55f f-16">126</p>
												<p class="c-55f" style="font-size: 8rem;">空调机盖</p>
										</div>
								</i-circle>
							</li>
							<li class="mt-20" style="width: 50%;">
								<i-circle
										:size="80"
										:trail-width="8"
										:stroke-width="8"
										:percent="10"
										stroke-linecap="round"
										trail-color="#04325F"
										stroke-color="#4084F1">
										<div>
												<p class="c-55f f-16">126</p>
												<p class="c-55f" style="font-size: 8rem;">空调机盖</p>
										</div>
								</i-circle>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="total-state relative p-20 ml-10">
				<p class="c-55f f-16">过往1月维修单增长趋势</p>
				<div id="state2"  class="state-box  mt-10"></div>
			</li>
		</ul>
		
		<ul class="flex mt-10 " style="flex-wrap: nowrap;">
			<li class="total-data-bottom relative  p-15">
				<p class="c-55f f-18 bold">设备备件损耗排行 TOP7</p>
				<Table :columns="columns1" :data="data1" class="mt-10" disabled-hover max-height="434">
				</Table>
			</li>
			<li class="total-state-bottom relative  p-20 ml-10">
				<p class="c-55f f-18 bold">本周设备损耗徘行 TOP7</p>
				<div id="state3"  class="state-box  mt-10" style="height: 430rem;"></div>
			</li>
		</ul>
	</div>
</template>

<script>

export default {
  data () {
    return {
			columns1: [
					{
							title: '序号',
							key: 'no'
					},
					{
							title: '编号',
							key: 'bh'
					},
					{
							title: '维修金额',
							key: 'money',
					},
					{
							title: '维修员',
							key: 'wxy'
					},
					{
							title: '创建时间',
							key: 'time',
							width: 200
					},
			],
			data1: [
					{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					}
			]
		}
  },

  created () {},
	
	mounted () {
		this.areaData()
		this.areaData2()
		this.areaData3()
	},

  methods: {
		areaData () {
			// console.log(this.$echarts)
			var myChart = this.$echarts.init(document.getElementById("state1"));
			var option = {
			    legend: {
			    //     right: 0,
							// top: '50%'
							itemStyle: {
								
							},
							textStyle: {
								color: '#55FFEF'
							},
							icon: 'pin'
			    },
			    series: [
			        {
			            name: '面积模式',
			            type: 'pie',
			            radius: ['5%', '45%'],
			            center: ['50%', '50%'],
			            roseType: 'area',
			            itemStyle: {
			                borderRadius: 4
			            },
									label: {
										color: '#55FFEF'
									},
			            data: [
			                {value: 12, name: 'rose 1'},
			                {value: 28, name: 'rose 2'},
			                {value: 42, name: 'rose 3'},
			                {value: 30, name: 'rose 4'},
			            ],
									
			        }
			    ]
			};
			myChart.setOption(option, true);
		},
	
		areaData2 () {
			let _this = this
			var myChart = this.$echarts.init(document.getElementById("state2"));
			var option = {
			    legend: {
			    		data: [
								{
									name: '空调机盖',
									icon: 'circle',
									itemStyle: {
										color: '#6997FB'
									},
									
								},
								{
									name: '过滤器',
									icon: 'circle',
									itemStyle: {
										color: '#A8E5E5'
									},
								},
								{
									name: '其他备件',
									icon: 'circle',
									itemStyle: {
										color: '#F79D27'
									},
								},
							],
			    	  textStyle: {
			    	  	color: '#55FFEF'
			    	  },
			    		right: '0'
			    },
			    tooltip: {},
			    xAxis: {
						type: 'category',
						data: ['周一','周二','周三','周四','周五','周六','周日'],
						axisLine: {
							show: true,
							lineStyle: {
									type: 'solid',
									color:'#2772E9',
									opacity: 0.6
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 12
						}
					},
			    yAxis: {
						splitLine: {
							show: true,
							lineStyle: {
									type: 'dashed',
									color:'#2772E9',
									opacity: 0.4
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 12,
						}
					},
					grid: {
						left: '5%',
						top: '14%',
						bottom: '14%',
						right: '5%',
					},
			    // Declare several bar series, each will be mapped
			    // to a column of dataset.source by default.
			    series: [
			        {
								type: 'bar',
								barWidth: 10,
								data:  ["10", "20", "12", "13","22", "23","30"],
								barGap:'80%',
								itemStyle: {
									color: '#6997FB',
									borderRadius: [5, 5, 0, 0]
								}
							},
			        {
			        	type: 'bar',
			        	barWidth: 10,
								data:  ["10", "20", "12", "13","22", "23","30"],
			        	itemStyle: {
			        		color: '#A8E5E5',
			        		borderRadius: [5, 5, 0, 0]
			        	}
			        },
			        {
			        	type: 'bar',
			        	barWidth: 10,
								data:  ["10", "20", "12", "13","22", "23","30"],
			        	itemStyle: {
			        		color: '#F79D27',
			        		borderRadius: [5, 5, 0, 0]
			        	}
			        },
			    ]
			};
			myChart.setOption(option, true);
		},
	
		areaData3 () {
			let _this = this
			// console.log(this.$echarts)
			var myChart = this.$echarts.init(document.getElementById("state3"));
			var option = {
					title: {
							text:"",
							textStyle:{
									fontSize:16,
									padding:"10px"
							}
					},
					tooltip: {},
					xAxis: {
							type: 'value',
							axisLine: {
								show: true,
								lineStyle: {
										type: 'solid',
										color:'#2772E9',
										opacity: 0.6
								}
							},
						  splitNumber: 21,
							axisTick: {
								show: false
							},
							axisLabel: {
								show: true,
								color: '#ccc',
								fontSize: 12
							},
							splitLine: {
								show: true,
								lineStyle: {
										type: 'dashed',
										color:'#2772E9',
										opacity: 0.4
								}
							},
					},
		
					yAxis: {
							type: 'category',
							data: ['过滤器','外机盖','设备1','设备2','设备3','设备4'],

							axisTick: {
								show: false
							},
							axisLine: {
								show: true,
								lineStyle: {
										type: 'solid',
										color:'#2772E9',
										opacity: 0.6
								}
							},
							axisLabel: {
								show: true,
								color: '#ccc',
								fontSize: 12,
							}
					},
					grid: {
						left: '5%',
						top: '3%',
						bottom: '8%',
						right: '3%',
					},
					series: [
							{
									barWidth: 16,
									data: [50, 100,100, 100, 100 ,100],
									name: "累计",
									type: "bar",
									itemStyle: {
											color: new this.$echarts.graphic.LinearGradient(
													1, 0, 0, 0,
													[
															{offset: 1, color: '#1376BB'},
															{offset: 0, color: '#00C5AB'}
													]
											),
											borderRadius: [0, 8, 8, 0]
									},
							}
					]
			};
			myChart.setOption(option, true);
		},
	}
}
</script>

<style lang="less" >
.waste {
	.total-data {
		width: 740rem;
		height: 277rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-data-bottom {
		height: 500rem;
		width: 740rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-data::before {
		content: '';
		position: absolute;
		left: -1rem;
		top: -1rem;
		width: 23rem;
		height: 14rem;
		background: url(../../static/data-boxicon.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
	}
	.total-data-bottom::before {
		content: '';
		position: absolute;
		right: -1rem;
		top: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateX(180deg);
	}
	.total-data-bottom::after {
		content: '';
		position: absolute;
		left: -1rem;
		bottom: -1rem;
		width: 23rem;
		height: 14rem;
		background: url(../../static/data-boxicon.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateX(180deg);
	}
	.total-data::after {
		content: '';
		position: absolute;
		right: -1rem;
		bottom: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
	}
	.money-icon, .num-icon {
		width: 98rem;
		height: 98rem;
		background: radial-gradient(circle, rgba(15, 37, 51, 0) 0%, rgba(25, 68, 92, 0.34) 45%, rgba(85, 255, 239, 0.8) 100%);
		// background: radial-gradient(circle, rgba(15, 37, 51, 0) 0%, rgba(25, 68, 92, 0.34) 60%, #4387FA 100%);
		border-radius: 50%;
	}
	.num-icon {
		background: radial-gradient(circle, rgba(15, 37, 51, 0) 0%, rgba(25, 68, 92, 0.34) 45%, #4387FA 100%);
	}
	.iconyue, .icondingdan1 {
		color: #55FFEF;
		font-size: 36rem;
	}
	.icondingdan1 {
		color: #4084F1;
	}
	.c-55f {
		color: #55FFEF;
	}
	.c-408 {
		color: #4084F1;
	}
	
	// 工单状态
	.total-state {
		// width: 307rem;
		flex: 1;
		height: 277rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-state-bottom {
		// flex: 1;
		height: 500rem;
		flex: 1;
		// width: 847rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-state-bottom::before {
		content: '';
		position: absolute;
		right: -1rem;
		bottom: -1rem;
		width: 23rem;
		height: 14rem;
		background: url(../../static/data-boxicon.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(180deg);
	}
	.total-state-bottom::after {
		content: '';
		position: absolute;
		left: -1rem;
		top: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(180deg);
	}
	.state-box {
		height: 227rem;
	}
	.total-state::before {
		content: '';
		position: absolute;
		left: -1rem;
		bottom: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateY(180deg);
	}
	.total-state::after {
		content: '';
		position: absolute;
		right: -1rem;
		top: -2rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(-90deg);
	}
	.total-qs {
		// width: 660rem;
		flex: 1;
		height: 277rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-qs::after {
		content: '';
		position: absolute;
		right: -1rem;
		top: -2rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(-90deg);
	}
	
	.ivu-table td,.ivu-table th {
		height: 48px!important;
	}
	.ivu-table td {
		border-bottom: 3px solid #111727;
	}
}
</style>
