<template>
	<div>
		<div class="data-box plr-20 pt-15">
			<ul class="flex flex-x-s">
				<!-- <li class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li> -->
			</ul>
			<ul class="flex flex-x-s mt-20">
				<li class="f-14 c-ff mr-10">创建时间：</li>
				<li class="mr-10 mb-10">
					<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end"
						placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
				</li>
				<li class="mr-10 mb-10">
					<Select v-model="category_id" style="width:160px" placeholder="项目分类">
						<Option v-for="item,index in catelist" :value="item.category_id" :key="index">{{ item.name }}
						</Option>
					</Select>
				</li>
				<li class="mr-10 mb-10">
					<Select v-model="client_id" style="width:160px" placeholder="关联客户">
						<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
					</Select>
				</li>
				<li class="mr-10 mb-10">
					<Select v-model="status_id" style="width:160px" placeholder="项目阶段">
						<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
					</Select>
				</li>
				<li class="mr-10 mb-10">
					<Select v-model="department_id" style="width:160px" placeholder="所属部分">
						<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
					</Select>
				</li>
				<li class="mr-10 mb-10">
					<Select v-model="model1" style="width:160px" placeholder="关联人员">
						<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
					</Select>
				</li>
				<li class="mr-10 mb-10">
					<Select v-model="model2" style="width:160px" placeholder="创建人">
						<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
					</Select>
				</li>
				<li class="mr-10 mb-10">
					<Input v-model="keyword" class="form-ipt" placeholder="请输入项目名称">
					<Button slot="append" @click="projectList" icon="ios-search"></Button>
					</Input>
				</li>
			</ul>
			<div class="" style="display: flex;justify-content: flex-end;">
				<div @click="clickShowAdd" style="width: 100rem;" class="table-btn flex flex-c plr-20 shou mr-10"><span
						class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></div>
			</div>
			<div class="table-box mt-25">
				<Table :columns="columns1" :data="data1" class="" disabled-hover max-height="640">
					<template slot-scope="{row}" slot="glkh">
						<ul class="f-14 c-ff ">
							<li class="onelist" v-for="item,index in row.clients" :key="index">{{item.client.phone}} |
								{{item.client.link_name}} </li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="glry">
						<ul class="f-14 c-ff ">
							<li class="onelist" v-for="item,index in row.user" :key="index">{{item.user.real_name}}
							</li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="cates">
						<ul class="f-14 c-ff">
							<li>{{row.category.name}} </li>
							<li>{{row.status.name}}</li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="ygj">
						<ul class="f-14 c-ff">
							<li>{{row.plan_follow_time.text}} </li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="cjr">
						<ul class="f-14 c-ff">
							<li>{{row.create_user.user.real_name}} </li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="handle">
						<ul class="flex flex-x-s">
							<li class="handle-edit flex-c ml-8" @click="suredao(row.project_id)">
								<span class="iconfont iconadd"></span>
								<span class="f-14">导出</span>
							</li>
							<li class="handle-del flex-c ml-8" @click="sureDel(row.project_id)">
								<span class="iconfont icondelect"></span>
								<span class="f-14">删除</span>
							</li>
						</ul>
					</template>
				</Table>

				<div class="flex flex-x-e mt-15">
					<p class="f-14 c-ff mr-20 mt-10">总记录：{{total}}</p>
					<Page :page-size="per_page" :total="total" @on-change="changepage1" />
				</div>
			</div>
		</div>
		<Modal :footer-hide="true" v-model="is_show_del" :closable="false" width="471" :mask-closable="false">
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_del')"></li>
				</ul>
				<div class="f-24  t-c pt-50 c-48c">是否确定删除？</div>
				<li class="flex flex-c mt-50">
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_del')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitdel">确认</div>
				</li>
			</div>
		</Modal>
		<Drawer :title="add_title" v-model="is_add" width="40%" :mask-closable="false">
			<ul>
				<li class="flex flex-x-s flex-y-s  mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目分类</div>
					<div class="flex-box">
						<Select v-model="addinfo.category_id" class="gz w100">
							<Option v-for="(item,index) in catelist" :value="item.category_id" :key="index">
								{{ item.name }}</Option>
						</Select>
					</div>
				</li>

				<li class="flex flex-x-s flex-y-s  mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目阶段</div>
					<div class="flex-box">
						<Select v-model="addinfo.status_id" class="gz w100">
							<Option v-for="(item,index) in jieduanlist" :value="item.project_status_id" :key="index">
								{{ item.name }}</Option>
						</Select>
					</div>
				</li>

				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目名称</div>
					<div class="flex-box"><Input v-model="addinfo.name" placeholder="请填写项目名称" class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目详情</div>
					<div class="flex-box"><Input v-model="addinfo.detail" placeholder="请填写项目详情" class="b-b w100" />
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">开始时间</div>
					<DatePicker type="date" v-model="addinfo.start_time" @on-change="changeFixDate"
						placement="bottom-end" placeholder="请选择时间" style="border: 1px solid #48CBCC;flex:1">
					</DatePicker>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">排序</div>
					<div class="flex-box"><Input v-model="addinfo.sort" placeholder="请填写序号" class="b-b w100" /></div>
				</li>
				<!-- 	<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备SN</div>
										<div class="flex-box"><Input v-model="options_add.sn" placeholder="请填写设备SN"   class="b-b w100" /></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目名称</div>
										<div class="flex-box"><Input v-model="options_add.device_title" placeholder="请填写项目名称"   class="b-b w100" /></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">安装时间</div>
										<DatePicker type="date" v-model="options_add.fix_date" @on-change="changeFixDate" placement="bottom-end" placeholder="请选择时间" style="border: 1px solid #48CBCC;flex:1"></DatePicker>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户姓名</div>
										<div class="flex-box"><Input v-model="options_add.client_name" placeholder="请填写客户姓名"   class="b-b w100" /></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户电话</div>
										<div class="flex-box"><Input v-model="options_add.client_mobile" placeholder="请填写客户电话"   class="b-b w100" /></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">省市区</div>
										<div class="flex-box"><Cascader 
												class="Cascader "
												style="width: 100%"
												size="large"
												:data="options"
												v-model="selectedOptions1"
												@on-change="addressChange1">
											</Cascader ></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">详细地址</div>
										<div class="flex-box"><Input v-model="options_add.address" placeholder="请填写详细地址"   class="b-b w100" /></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">密码</div>
										<div class="flex-box"><Input v-model="options_add.password" placeholder="请填写密码,不填表示不做修改"   class="b-b w100" /></div>
									</li>
									<li class="flex flex-x-s mt-15">
										<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">匹数</div>
										<div class="flex-box"><Input v-model="options_add.horse" placeholder="请填写匹数"   class="b-b w100" /></div>
									</li> -->







				<li class="flex flex-x-c flex-y-s  mt-50">
					<div class="submit-btn f-18 c-ff t-c mr-40 cancel shou" @click="clickHideAdd">取消</div>
					<div class="submit-btn f-18 c-ff t-c shou" @click="addProject">提交</div>
				</li>

			</ul>

		</Drawer>

       <Modal :footer-hide="true" v-model="is_show_pro" :closable="false" width="471" :mask-closable="false">
       	<span slot="title"></span>
       	<div class="model-pop-jn eq-jn plr-20">
       		<ul class="flex pt-20 flex-y-s flex-x-e">
       			<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_pro')"></li>
       		</ul>
			<li class="flex flex-x-s mt-15">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">时间</div>
				<DatePicker type="month" v-model="start_time" 
					placement="bottom-end" placeholder="请选择月份" style="border: 1px solid #48CBCC;flex:1">
				</DatePicker>
			</li>
       		
       		<li class="flex flex-c mt-50">
       			<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_pro')">取消</div>
       			<div class="queding f-18 c-ff t-c shou" @click="submitDao">导出</div>
       		</li>
       	</div>
       </Modal>
	

	</div>


</template>

<script>
	import topheader from '../../components/topheader.vue'
	export default {
		data() {
			return {
				start_time:'',
				projectId:'',
				is_show_pro:false,
				addinfo: {
					name: '',
					category_id: '',
					detail: '',
					status_id: '',
					start_time: '',
					sort: '',
				},
				add_title: '添加项目',
				is_show_del: false,
				show: true,
				is_add: false,
				time: '',
				columns1: [{
						title: 'id',
						key: 'project_id',
						width: 80
					},
					{
						title: '名称',
						key: 'name',
						className: 'name-color'
					},
					{
						title: '分类/阶段',
						slot: 'cates',
					},
					{
						title: '关联客户',
						slot: 'glkh',
					},
					{
						title: '关联人员',
						slot: 'glry',
						width: 100
					},
					{
						title: '开始时间',
						key: 'start_time',
						width: 140
					},
					{
						title: '预跟进',
						slot: 'ygj',
						width: 140
					},
					{
						title: '未跟进（天）',
						key: 'noFollowDays',
						className: 'name-color',
						width: 120
					},
					{
						title: '创建人',
						slot: 'cjr',
						width: 120
					},
					{
						title: '添加时间',
						key: 'create_time',
					},
					{
						title: '操作',
						slot: 'handle',
						width: 240
					}
				],
				data1: [],
				catelist: [],
				total: 0,
				per_page: 0,
				start_time: '',
				end_time: '',
				keyword: '',
				status_id: 0,
				client_id: 0,
				category_id: 0,
				department_id: 0,
				model1: '',
				model2: '',
				jieduanlist: [],
			}
		},

		components: {
			topHeader: topheader
		},

		created() {

		},
		watch: {
			is_add(val) {
				if (val) {
					this.addinfo = {
						name: '',
						category_id: '',
						detail: '',
						status_id: '',
						start_time: '',
						sort: '',
					}
				}

			}
		},
		mounted() {
			this.projectcate()
			this.projectList()
			this.getJieduan()
		},

		methods: {
			async submitDao(){
				let _this = this;
				if(!_this.start_time){
					_this.util.showMsg('error', '请选择日期', 1.5, false);
					return false
				}
				const res = await _this.get('project/explode', {month:_this.start_time,projectId:_this.projectId});
				window.open(res.file_url)
				this.is_show_pro = false;
			},
			suredao(row){
				this.is_show_pro = true;
				this.projectId  = row;
				
			},
			// 添加项目
			async addProject() {
				let _this = this
				let {
					name,
					category_id,
					detail,
					status_id,
					start_time,
					sort
				} = _this.addinfo
				if (!name || !category_id || !detail || !status_id || !start_time || !sort) {
					_this.util.showMsg('error', '请将信息填写完整', 1.5, false)
					return
				}
				const res = await _this.post('project/add', _this.addinfo)
				// _this.jieduanlist = res.list
				console.log("添加项目", res);
				this.is_add = false
				_this.util.showMsg('success', '操作成功', 1.5, false)
				this.projectList()
			},
			// 项目阶段列表获取
			async getJieduan() {
				let _this = this
				const res = await _this.get('project.status/lists', )
				_this.jieduanlist = res.list
				console.log("阶段列表", res);
			},
			// 获取选择的时间
			changeFixDate(e) {
				this.addinfo.start_time = e

			},
			// 点击隐藏添加弹框
			clickHideAdd() {
				this.is_add = false
			},
			// 点击展示添加弹框
			clickShowAdd() {
				this.is_add = true
			},
			// 跳转路由
			navigation(path) {
				this.$router.push({
					path
				})
			},

			async projectList(page = 1) {
				let _this = this,
					{
						search
					} = _this;
				const res = await _this.get('project/lists', {
					page
				})
				_this.data1 = res.list.data
				_this.total = res.list.total - 0
				_this.per_page = res.list.per_page - 0
			},

			// 项目类型列表获取
			async projectcate() {
				let _this = this
				const res = await _this.get('project.category/lists', )
				_this.catelist = res.list
				console.log(res.list);
			},

			sureDel(id) {
				this.project_id = id
				this.changeModal('is_show_del')
			},

			async submitdel() {
				let _this = this;
				const res = await _this.post('project/delete', {
					project_id: _this.project_id
				})
				_this.util.showMsg('success', '操作成功', 1.5, false)
				this.changeModal('is_show_del')
				_this.projectList()
			},

			changeModal(type) {
				this[type] = !this[type]
			},

			// 选择时间
			changedate(e) {
				// console.log(e)
				this.start_time = e[0]
				this.end_time = e[1]
			},
			changepage1(page) {
				this.projectList(page)
			},
	
	}
}
</script>

<style lang="less">
	.ivu-modal-content {
		background: transparent
	}

	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(/img/xunwen-pop.a8330790.png) no-repeat 50%;
		background-size: 100% 100%;
	}

	td.name-color {
		color: #48CBCC !important;
	}

	.model-line-bg {
		width: 15rem;
		height: 900rem;
		padding-top: 30rem;
	}

	.model-line-bg.right img {
		transform: rotateY(180deg);
	}

	.meau-box {
		width: 227rem;
		height: 880rem;
		background: rgba(1, 169, 170, 0.1);
		border: 1px solid #48CBCC;

		.meal {
			width: 100% !important;
			background: none;
		}

		.meau-bg {
			width: 251rem;
			height: 59rem;
			left: -13rem;
		}
	
	.meau-bg.top {
			top: -12rem;
		}

		.meau-bg.right {
			width: 251rem;
			height: 59rem;
			bottom: -12rem;
			left: -13rem;
			transform: rotateX(180deg);
		}

		.meau-detail {
			height: 56rem;
			background: url(../../static/meau-bg.png) no-repeat center;
			background-size: 100% 100%;
			color: #48CBCC;
			line-height: 56rem;
			cursor: pointer;
		}

		.meau-detail.active {
			background: url(../../static/meau-bg-active.png) no-repeat center;
			background-size: 100% 100%;
			color: #FFFFFF;
		}
	}

	.title-icon {
		width: 17rem;
		height: 17rem;
	}

	.user-head {
		width: 52rem;
		height: 52rem;
		background: url(../../static/user-head-bg.png) no-repeat center;
		background-size: 100% 100%;
	
	img {
			width: 43rem;
			height: 43rem;
			border-radius: 50%;
		}
	}

	.close-icon {
		width: 23rem;
		height: 25rem;
	}

	// 主要列表样式
	.table-box {}

	.data-box {
		height: 790rem;
		background: url(../../static/table-bg.png) no-repeat bottom right;
		background-color: rgba(1, 169, 170, 0.1);
		background-size: 655px 327rem;
	}

	.form-ipt {
		background: rgba(1, 169, 170, 0.1);
		border: 1px solid #48CBCC;
	
	.ivu-input {
			border: none;
			color: #48CBCC;
			background-color: rgba(0, 0, 0, 0)
		}

		.ivu-icon-ios-search,
		.ivu-input::placeholder {
			color: #48CBCC;
		}

		.ivu-input-group-append {
			button {
				background: rgba(1, 169, 170, 0.1);
			}

			background: rgba(1, 169, 170, 0.1);
			border: none;
			border-left: 1px solid #48CBCC;
		}
	}

	.ivu-table th {
		height: 60px !important;
		background-color: rgba(0, 0, 0, .1) !important;
		border-bottom: none;
		color: #fff;
		font-size: 14rem;
		font-weight: bold;
	}

	.ivu-table td {
		height: 72rem;
		background: rgba(1, 169, 170, 0.2);
		border-bottom: 5px solid #111727;
		color: #fff;
		font-size: 14rem;
	}

	.ivu-table {
		background-color: rgba(0, 0, 0, 0);
	}

	.ivu-table:before {
		background-color: rgba(0, 0, 0, .1) !important;
	}

	.table-type {
		background-color: #5EB95E;
		height: 24rem;
		line-height: 24rem;
		display: inline-block;
	}

	.handle-edit,
	.handle-del {
		width: 70rem;
		height: 32rem;

		.f-14 {
			margin-left: 3rem;
		}
	}

	.handle-edit {
		border: 1px solid #74B8F4;
		color: #74B8F4;
	}

	.handle-del {
		border: 1px solid #F16252;
		color: #F16252;
	}

	// 弹窗
	.model-pop {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 710rem;
		height: 478rem;
		background: url(../../static/data-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
</style>
