<template>
  <div>
		
		<top-header></top-header>
		<div class=" model-box flex flex-y-s"  v-if="0">
			<div class="model-line-bg"><img src="../static/left-line.png" alt="" /></div>
			
			<!-- 左侧导航 -->
			<div class="ml-15 meau-box relative mr-20">
				 <div class="meau-bg absolute top"><img src="../static/meal-bg.png" /></div>
				 <div class="meau-bg right absolute"><img src="../static/meal-bg.png" /></div>
				 <ul class="plr-20 relative" style="z-index: 100;">
					 <li class="mt-15">
						 <div class="meau-detail  f-16 t-c" :class="hash=='#/equip/model'?'active':''" @click="navigation('/equip/model')">型号设置</div>
					 </li>
					 <li class="mt-15">
						<div class="meau-detail f-16 t-c" :class="(hash=='#/equip/map' || hash=='#/equip/list' || hash=='#/equip/detail')?'active':''" @click="navigation('/equip/map')">设备总览</div>
							<ul class=" c-48c  t-r twolevel" :class="(hash=='#/equip/map' || hash=='#/equip/list' || hash=='#/equip/detail')?'is-show':''">
								<li class="relative two-level shou " :class="(hash=='#/equip/map')?'active':''" @click="navigation('/equip/map')">地图模式</li>
								<li class="relative mt-15 shou two-level" :class="(hash=='#/equip/list' || hash=='#/equip/detail')?'active':''" @click="navigation('/equip/list')">列表模式</li>
							</ul>
					 </li>
					 <li class="mt-15">
						 <div class="meau-detail f-16 t-c" :class="hash=='#/equip/log'?'active':''" @click="navigation('/equip/log')">操作日志</div>
					 </li>
				
					 
				 </ul>
			</div>
			<div class="flex-box mr-30">
				<!-- 页头用户信息 -->
				<div class="flex ptb-20">
					<ul class="flex">
						
						<li class="title-icon"><img src="../static/title-icon.png" /></li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/equip/model'">设备类型</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/equip/map'">地图模式</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/equip/list'">列表模式</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/equip/log'">操作日志</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/equip/detail'">项目详情</li>
					</ul>
					<ul class="flex">
						<li class="flex">
							<div class="user-head radius-all flex-c"><img :src="avatar" alt="" /></div>
							<div class="ml-8">
								<p class="f-18 c-ff">{{nickname}}</p>
								<p class="f-14 c-cc">{{username}}，欢迎您</p>
							</div>
						</li>
						<li class="close-icon ml-30"><img src="../static/close-icon.png" /></li>
					</ul>
				</div>
				<!-- 主要表格内容 -->
				<router-view></router-view>
			</div>
			<div class="model-line-bg right"><img src="../static/left-line.png" alt="" /></div>
		</div>
	</div>
</template>

<script>
import topheader from './topheader.vue'
export default {
  data () {
    return {
			hash: '#/equip/model',
			username: '',
			nickname: '',
			avatar: '../../static/moren-head.jpg',
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		let hashUrl = window.location.hash
		let index = hashUrl.indexOf('?')
		this.hash = index == -1?hashUrl:hashUrl.substr(0,index)
	},

  methods: {
		// 跳转路由
		navigation (path) {
			
			this.$router.push({path})
		}
	},
	
	
	mounted () {
			let _this = this
			_this.username = localStorage.getItem('username')
			_this.nickname = localStorage.getItem('nickname')
			_this.avatar = localStorage.getItem('avatar') || '../../static/moren-head.jpg'
	},
	
	watch: {
		$route (to,from) {
			// console.log(to,from)
			this.hash = '#' + to.path
		}
	}
}
</script>

<style lang="less" >


</style>
