<template>
  <div class="refund-records">
				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
					</ul>
					<ul class="flex flex-x-s mt-20">
						<li class="mr-10">
							<Select v-model="model1" style="width:160px" placeholder="是否故障">
									<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
							</Select>
						</li>
						<li class="mr-10">
							<Select v-model="model1" style="width:160px" placeholder="在线状态">
									<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
							</Select>
						</li>
						<li class="mr-10">
							<Select v-model="model1" style="width:160px" placeholder="全部类别">
									<!-- <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
							</Select>
						</li>
						<li class="mr-10">
							<Input v-model="value13" class="form-ipt" placeholder="请输入设备编码">
									<Button slot="append" icon="ios-search"></Button>
							</Input>
						</li>
					</ul>
					<!-- 状态以及统计 -->
					<div class="table-box">
						<Table :columns="columns1" :data="data1" class="" disabled-hover>
							<template slot-scope="{ row }" slot="type">
									<div class="f-14 c-ff t-c table-type plr-10">温控器</div>
							</template>
							<template slot-scope="{row}" slot="state">
									<div class="state-color flex-x-s flex">
										<div class="dight"></div>
										<span>在线</span>
									</div>
							</template>
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template>
						</Table>
					</div>
				</div>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			value13: '',
			show: true,
			columns1: [
					{
							title: 'ID',
							key: 'id'
					},
					{
							title: '编码',
							key: 'bm'
					},
					{
							title: '名称',
							key: 'name',
							className: 'name-color'
					},
					{
							title: '类型',
							slot: 'type',
					},
					{
							title: '库存',
							key: 'kc'
					},
					{
							title: '排序',
							key: 'px'
					},
					{
							title: '添加时间',
							key: 'timeadd'
					},
					{
							title: '状态',
							slot: 'state'
					},
					{
							title: '操作',
							slot: 'handle',
							width: 200
					}
					
			],
			data1: [
					{
							id: 'abc123456',
							bm: 'abc123456',
							name: '安徽区世家Y6-103',
							kc: '123...',
							px: '1',
							timeadd: '2016-10-03',
					}
			]
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		}
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.refund-records {
	.dight {
		width: 6rem;
		height: 6rem;
		background: #8FF38E;
		border-radius: 3rem;
		margin-right: 10rem;
	}
	.tab-state {
		width: 90rem;
		height: 32rem;
		line-height: 30rem;
		border: 1px solid #48CBCC;
		opacity: 0.6;
		transition: all .4s;
	}
	.active.tab-state {
		background-color: #48CBCC;
		opacity: 1;
	}
}
</style>
