<template>
  <div class=" site-setadmin data-box ">
			<ul class=" pl-20 pt-25 site-setadmin" >
				<li class="flex flex-x-s mt-15">
					<div class="site-setadmin-title mr-30 t-r must relative f-14 c-ff">管理员角色</div>
					<div class="flex-box">
						 <Select v-model="role_id"  class="gz w100" @on-change="changegroup">
								<Option v-for="item in list_role" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>
					</div>
				</li>
				<li class="flex flex-x-s mt-30 pr-30">
					<div class="site-setadmin-title mr-30 t-r must relative f-14 c-ff">用户名</div>
					<div class="flex-box"><Input v-model="username" placeholder="请填写用户名"   class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-30 pr-30">
					<div class="site-setadmin-title mr-30 t-r must relative f-14 c-ff">昵称</div>
					<div class="flex-box"><Input v-model="nickname" placeholder="请填写昵称"   class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-30 pr-30">
					<div class="site-setadmin-title mr-30 t-r must relative f-14 c-ff">密码</div>
					<div class="flex-box">
						<Input v-model="password" placeholder="请填写密码" type="password"   class="b-b w100" />
						<p class="f-14 c-48c mt-8">不修改请不要输入密码</p>
					</div>
				</li>
				<li class="flex flex-x-s mt-30 pr-30">
					<div class="site-setadmin-title mr-30 t-r must relative f-14 c-ff">确认密码</div>
					<div class="flex-box">
						<Input v-model="password_confirm" placeholder="请填写密码" type="password"   class="b-b w100" />
						<p class="f-14 c-48c mt-8">不修改请不要输入密码</p>
					</div>
				</li>
			</ul>
			
			
			<div class="flex flex-x-s" style="padding: 50px 0 0 230rem;">
				<div class="table-btn submit plr-25 t-c mr-20" style="width: 132rem;" @click="bindsubmit">
					<span class="f-14 c-ff t-c">提交</span>
				</div>
				<div class="table-btn submit plr-25 t-c" style="width: 132rem;" @click="navigationBack">
					<span class="f-14 c-ff t-c">返回</span>
				</div>
			</div>
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			username: '',
			nickname: '',
			password: '',
			password_confirm: '',
			role_id: 0,
			show: true,
			list_role: []
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.rolelist()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		navigationBack () {
			this.$router.go(-1)
		},
		
		// 新建yonghhu
		async bindsubmit () {
			let _this = this,
					{ role_id, username, nickname, password, password_confirm } = _this;
			if (!role_id) {
				return _this.util.showMsg('warning', '请选择管理员角色',1.5)
			}
			if (!username) {
				return _this.util.showMsg('warning', '请填写用户名',1.5)
			}
			if (!nickname) {
				return _this.util.showMsg('warning', '请填写昵称',1.5)
			}
			if (!password) {
				return _this.util.showMsg('warning', '请填写密码',1.5)
			}
			if (!password_confirm) {
				return _this.util.showMsg('warning', '请填写确认密码',1.5)
			}
			if (password_confirm != password) {
				return _this.util.showMsg('warning', '两次密码输入不一致',1.5)
			}
			const res = await _this.post('admin/add', {role_id, username, nickname, password, password_confirm})
			_this.navigation('/site/admin')
		},
		
		// 角色列表
		async rolelist () {
			let _this = this
			const res = await _this.get('admin/add')
			res.list_role.map( x => {
				x.name = x.name.replace(/&nbsp;/g, '  ')
			})
			_this.list_role = res.list_role
		},
		
		// 切换角色
		changegroup (value) {
			this.role_id = value
		},
	}
}
</script>

<style lang="less">
	.site-setadmin.data-box {
		overflow-y: hidden;
	}
	.site-setadmin {
		.ivu-input::placeholder {
			color: #fff;
			opacity: .6;
		}
		.site-setadmin-title {
			width: 180rem;
		}
		.site-setadmin-title.must {
			
		}
		.site-setadmin-title.must::after {
			content: '*';
			color: #F16252;
			margin-left: 8rem;
			font-size: 16rem;
		}
		.b-b {
			border-bottom: 1px solid rgba(1,169,170, .5);
		}
		.ivu-date-picker-focused input,.ivu-input:hover {
			box-shadow:none;
		}
		
		.gz .ivu-select-selection {
			border: none;
			border-bottom: 1px solid rgba(1,169,170, .5);
			display: block;
		}
		.upload-img {
			width: 150rem;
			height: 150rem;
			border: 1px solid #48CBCC;
			border-radius: 4rem;
		}
		.iconadd1 {
			color: #48CBCC;
			font-size: 40rem;
		}
		.submit-btn {
			width: 140rem;
			line-height: 40rem;
			height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}

	}


</style>
