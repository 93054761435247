<template>
  <div>
			<div class="map-box  flex mapyt">
				<!-- 左侧列表 -->
				<div class="map-list mr-15">
					<div class=" f-16 bold c-ff pro-name plr-25" >{{project_name}}</div>
					<div class="pl-30 mt-10">
						<ul>
							<li class="mt-25 f-16 c-ff">
								<div class="shou">在线监控 <span class="iconfont iconxiangxiajiantou f-12 bold"></span></div>
								<ul>
									<li class="pl-30 mt-20 shou"  @click="navigation('/equip/map/yt?id='+project_id)">实时云图</li>
									<li class="pl-30 mt-20 shou active  c-48c relative">实时数据</li>
									<!-- <li class="pl-30 mt-20">实时曲线</li> -->
								</ul>
							</li>
							<li class="mt-25 f-16 shou c-ff" @click="navigation('/equip/map/bj?id='+project_id)">故障报警 </li>
							<li class="mt-25 f-16 shou c-ff" @click="navigation('/equip/map/eq?id='+project_id)">设备管理 </li>
						</ul>
					</div>
				</div>
				<!-- 右侧项目地图 -->
				<div class="flex-box map">
					<div class="plr-20 ptb-15 f-16 bold c-ff flex">
						<div>实时数据</div>
						<div></div>
					</div>
					<Table :row-class-name="rowClassName"  :columns="columns1" :data="data1" class=" relative" disabled-hover >
						<template slot-scope="{row}" slot="handle">
								<ul class="flex flex-c">
									<li class="handle-edit flex-c">
										<span class="iconfont iconedit1"></span>
										<span class="f-14">编辑</span>
									</li>
								</ul>
						</template>
					</Table>
				</div>
			</div>
	</div>
</template>

<script>
	import myOverlay from './myoverly.vue'
export default {
  data () {
    return {
			value13:'',
			columns1: [
					{
							title: 'ID',
							key: 'id',
							align: 'center'
					},
					{
							title: '名称',
							key: 'name',
							align: 'center'
					},
					{
							title: '联系电话',
							key: 'phone',
							align: 'center'
					},
					{
							title: '链接设备数',
							key: 'num',
							align: 'center'
					},
					{
							title: '注册时间',
							key: 'time',
							align: 'center'
					},
					{
							title: '操作',
							align: 'center',
							slot: 'handle',
							// width: 360
					}
			],
			data1: [
					{
							id: 1,
							name: 21,
							phone: '123',
							num: 1,
							time: '2016-10-03',
							// cz: '2016-10-03'
					}
			],
			project_id: 0,
			project_name: ''
		}
  },
	
	components: {
		myOverlay
	},

  created () {
		this.project_id = localStorage.getItem('project_id')
		this.project_name = localStorage.getItem('project_name')
	},

	mounted () {
	},
	
  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},

		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
	}
}
</script>

<style lang="less" >
.map-box >div{
	height: 790rem;
	
}

.map-list {
	width: 280rem;
	background: url(../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;

}

// 标签页
.tabs-title {
	li {
		width: 120rem;
		height: 52rem;
		line-height: 52rem;
		border: 1px solid #48CBCC;
		color: #fff;
		opacity: 0.6;
	}
	li.active {
		background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
		opacity: 1;
	}
}
.map-pro {
	ul {
		color: #fff;
		opacity: .6;
		padding-left: 30rem;
	}
	li {
		margin-top: 10rem;
		cursor: pointer;
	}
	.ivu-collapse-simple,.ivu-collapse,.ivu-collapse>.ivu-collapse-item,.ivu-collapse-content {
		background-color: rgba(0,0,0,0);
		border: none;
	}
	.ivu-collapse-header {
		font-size: 16rem;
		color: #fff;
		opacity: .6;
	}
	.ivu-collapse-item-active .ivu-collapse-header{
		font-size: 16rem;
		color: #fff;
		opacity: 1;
	}
	
}
.map-detail {
	height: 100%;
}

.sample {
  width: 43rem;
	height: 63rem;
}
.sample.active {
  // background: rgba(0,0,0,0.75);
  // color: #fff;
}

.mapyt {
	.pro-name {
		width: 100%;
		height: 52rem;
		line-height: 52rem;
		background: rgba(1, 169, 170, 0.1);
	}
	.active::before {
		content: '';
		position: absolute;
		width: 6rem;
		height: 6rem;
		border-radius: 50%;
		background-color: #48CBCC;
		top: 50%;
		transform: translateY(-50%);
		left: 10rem;
	}
	.flex-box.map .bold{
			background: rgba(1, 169, 170, 0.1)!important;
		}
		.ivu-table th {
			height: 60px!important;
			background-color: rgba(0, 0,0, .1)!important;
			border-bottom: none;
			color: #fff;
			font-size: 14rem;
			font-weight: bold;
		}
		.ivu-table td {
			height: 60rem;
			background: rgba(1, 169, 170, 0.2);
			border-bottom: 5px solid #111727;
			color: #fff;
			font-size: 14rem;
		}
		.ivu-table {
			background-color: rgba(0,0,0,0);
		}
		.ivu-table:before {
			background-color: rgba(0, 0,0, .1)!important;
		}
		.table-info-row {
			td {
				border-bottom: none!important;
				background: rgba(1, 169, 170, 0.24)!important;
			}
		}
		.table-error-row {
			td {
				border-bottom: none!important;
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}
}
</style>
