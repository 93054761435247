<template>
  <div class="refund-records">
				<div class="data-box pl-20 pt-25" style="overflow-y: hidden;">
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20 mb-10" @click="navigation('/site/rule/add')"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
					</ul>
		
					</ul>
					<!-- 状态以及统计 -->
					<div class="table-box">
						<Table :columns="columns1" :data="data1" class="" disabled-hover max-height="720">
							<template slot-scope="{ row }" slot="title">
									<div class="f-14 c-ff " v-html="row.title"></div>
							</template>
							<template slot-scope="{ row }" slot="state">
									<div class="f-14 c-ff t-c table-type plr-10" v-if="row.ismenu == 1">菜单</div>
									<div class="f-14 c-ff t-c table-type fou plr-10" v-else>否</div>
							</template>
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template>
						</Table>
					</div>
				</div>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			value13: '',
			show: true,
			columns1: [
					{
							title: 'ID',
							key: 'id'
					},
					{
							title: '名称',
							slot: 'title',
					},
					{
							title: '规则',
							key: 'name',
							className: 'name-color'
					},
					{
							title: '路由',
							key: 'route',
							className: 'name-color'
					},
					{
							title: '排序',
							key: 'weigh'
					},
					{
							title: '是否菜单',
							slot: 'state'
					},
					{
							title: '添加时间',
							key: 'create_time'
					},
					// {
					// 		title: '操作',
					// 		slot: 'handle',
					// 		width: 200
					// }
					
			],
			data1: []
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.caidanInfo()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		// 菜单规则
		async caidanInfo () {
				let _this = this
				const res = await _this.get('auth.access/lists')
				_this.data1  = res.list
		},
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.refund-records {
	.dight {
		width: 6rem;
		height: 6rem;
		background: #8FF38E;
		border-radius: 3rem;
		margin-right: 10rem;
	}
	.tab-state {
		width: 90rem;
		height: 32rem;
		line-height: 30rem;
		border: 1px solid #48CBCC;
		opacity: 0.6;
		transition: all .4s;
	}
	.active.tab-state {
		background-color: #48CBCC;
		opacity: 1;
	}
	.table-type.fou {
		background-color: #FF0000;
	}
}
</style>
