<template>
	<div>
		<!-- 头部导航 -->
		<div class="relative top-header flex plr-50 pb-10 flex-y-e">
			<ul class="flex flex-box">
				<li class="top-btn f-20 t-c " @click="chooseLeftmeau(item.id, item.route)"
					v-for="(item, index) in leftarr" :key="index"
					:class="hash == '#/' + item.route ? 'active c-ff' : 'c-15e'">
					<span>{{ item.title }}</span>
				</li>
			</ul>
			<div class=" top-text  relative" style="margin: 0 130rem;">
				<img src="../static/top_text.png" />
			</div>
			<ul class="flex flex-box">
				<li class="top-btn right f-20 t-c " @click="chooseLeftmeau(item.id, item.route)"
					v-for="(item, index) in rightarr" :key="index"
					:class="hash == '#/' + item.route ? 'active c-ff' : 'c-15e'">
					<span>{{ item.title }}</span>
				</li>
			</ul>
		</div>
		<div class="pl-20 pr-20 pt-10  flex">
			<!-- 左侧数据栏 -->
			<div>
				<!-- 实时数据统计 -->
				<div class="home-real relative h-160">
					<p class="f-20 c-ff  title absolute">{{ dateinfo }}</p>
					<div class="content flex">
						<ul class="flex-box pl-20">
							<li class="flex c-15e flex-x-s mt-new20">
								<div class="icon"><img src="../static/user-icon.png" /></div>
								<span class="f-16 ml-5 mr-30">用户总数</span>
								<span class="f-20">{{ total_user }}</span>
							</li>
							<li class="c-277 mt-new15">
								<span class="f-16 ml-5 mr-30 list-title">在线用户</span>
								<span class="f-18">{{ day_online_user }}</span>
							</li>
							<li class="c-277 mt-new15">
								<span class="f-16 ml-5 mr-30 list-title">离线用户</span>
								<span class="f-18">{{ day_offline_user }}</span>
							</li>
							<li class="c-277 mt-new15">
								<span class="f-16 ml-5 mr-30 list-title">故障用户</span>
								<span class="f-18">{{ day_haserr_user }}</span>
							</li>
						</ul>
						<ul class="flex-box pl-20 mt-new20">
							<li class="flex c-15e flex-x-s">
								<div class="icon"><img src="../static/equipment-icon.png" /></div>
								<span class="f-16 ml-5 mr-30">设备总数</span>
								<span class="f-20">{{ total_device }}</span>
							</li>
							<li class="c-277 mt-new15">
								<span class="f-16 ml-5 mr-30 list-title">在线设备</span>
								<span class="f-18">{{ total_devonline }}</span>
							</li>
							<li class="c-277 mt-new15">
								<span class="f-16 ml-5 mr-30 list-title">离线设备</span>
								<span class="f-18">{{ devstatus_offline }}</span>
							</li>
							<li class="c-277 mt-new15">
								<span class="f-16 ml-5 mr-30 list-title">故障设备</span>
								<span class="f-18">{{ devstatus_haserr }}</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="home-real tabel mt-10 relative h-190">
					<p class="f-20 c-ff  title absolute">接入面积统计</p>
					<div id="chart1" class="area-box plr-20 "></div>
				</div>
				<div class="home-real tabel mt-20 relative h-190">
					<p class="f-20 c-ff  title absolute">碳排减少量统计</p>
					<div id="chart2" class="area-box plr-20 "></div>
				</div>
			</div>
			<!-- 右侧主题内容 -->
			<div class="ml-20 flex-box home-right relative">
				<div class="absolute home-right-total">
					<ul class="flex flex-y-s pt-20">
						<li class="title-bg f-20 c-ff pl-40">设备分布统计</li>
						<li class="flex pr-20">
							<div class="flex mr-20">
								<div class="user-head radius-all flex-c"><img :src="avatar" alt="" /></div>
								<div class="ml-8">
									<p class="f-18 c-ff">{{ nickname }}</p>
									<p class="f-14 c-ff">{{ username }}，欢迎您</p>
								</div>
								<div class="ml-30 mr-10">
									<p class="f-16 c-ff">{{ date }}</p>
									<p class="f-14 c-ff">{{ dew }}℃</p>
								</div>
								<div class="head-tq">
									<img v-if="icon" :src="require('../static/color-64/' + icon + '.png')" alt="" />
								</div>
							</div>
							<Row class="row">
								<Cascader :data="city" v-model="model11" @on-change="handleChange"></Cascader>
							</Row>
						</li>
					</ul>
					<ul class="flex flex-c mt-30">
						<li class="flex-dir-col flex-c" style="margin-right: 200rem;">
							<div class="home-data-bg relative">
								<img src="../static/home-title-zs.png" class="absolute icon " alt="" />
								<span class="f-18 c-15e" style="padding-left: 100rem;">设备总数</span>
							</div>
							<ul class="mt-20 flex">
								<li class="num-box f-20 mr-5" v-for="(item, index) in total_device + ''" :key="index">
									{{ item }}
								</li>
							</ul>
						</li>
						<li class="flex-dir-col flex-c">
							<div class="home-data-bg relative">
								<img src="../static/home-title-zx.png" class="absolute icon " alt="" />
								<span class="f-18 c-15e" style="padding-left: 80rem;">在线设备总数量</span>
							</div>
							<ul class="mt-20 flex">
								<li class="num-box f-20 mr-5" v-for="(item, index) in total_devonline + ''"
									:key="index">
									{{ item }}
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<baidu-map @ready="mapReady" class="map-detail" :zoom="5" :scroll-wheel-zoom="true"
					mapType="BMAP_HYBRID_MAP">
					<bm-marker v-for="(item, index) of markers" :key="index"
						:icon="{ url: item.icon, size: { width: 12, height: 15 } }"
						:position="{ lng: item.longitude, lat: item.latitude }" @click="lookDetail(item)">
						<bm-info-window :title="infoWindow.name"
							:position="{ lng: infoWindow.longitude, lat: infoWindow.latitude }"
							@close="infoWindowClose(marker)" @open="infoWindowOpen(marker)"></bm-info-window>
					</bm-marker>
				</baidu-map>

				<!-- echart导图 -->
				<div class="echart-homebox absolute" id="chart6"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import topheader from './topheader.vue'
	import city from '../util/city-data-level2.js'
	var times;
	export default {
		data() {
			return {
				cityName: '合肥市',
				model11: ['安徽省', '合肥市'],
				city: city,
				markers: [],
				infoWindow: {},
				mapCenter: {
					lng: 117.404,
					lat: 31.853
				},
				username: '',
				nickname: '',
				avatar: '../static/moren-head.jpg',
				hash: '#/home',
				hashleft: '',
				username: '',
				nickname: '',
				avatar: '',
				leftarr: [],
				rightarr: [],
				memusList: [],
				title_text: '', // 下面都是一些数据统计
				total_user: 0,
				day_online_user: 0,
				day_offline_user: 0,
				day_haserr_user: 0,
				total_device: 0,
				total_devonline: 0,
				devstatus_offline: 0,
				devstatus_haserr: 0,
				data_devarea: [],
				month_area: [],
				day_co2: [],
				dateinfo: '',
				date: '',
				dew: '',
				icon: '',

			}
		},

		components: {
			topHeader: topheader,
		},
		computed: {
			top() {
				return -this.activeIndex * 50 + 'px'
			}
		},
		created() {
			this.meaulist()
			//this.menuslist()
			let hashUrl = window.location.hash
			// console.log(hashUrl.indexOf('/home'))
			let index = hashUrl.indexOf('?')
			this.hashleft = index == -1 ? hashUrl.substr(2) : (hashUrl.substr(0, index)).substr(2)
			if (hashUrl.indexOf('/home') != -1) {
				this.hash = '#/home'
			}
			if (hashUrl.indexOf('/equip') != -1) {
				this.hash = '#/equip'
			}
			if (hashUrl.indexOf('/pro') != -1) {
				this.hash = '#/pro'
			}
			if (hashUrl.indexOf('/user') != -1) {
				this.hash = '#/user'
			}
			if (hashUrl.indexOf('/refund') != -1) {
				this.hash = '#/refund'
			}
			if (hashUrl.indexOf('site') != -1) {
				this.hash = '#/site'
			}
		},

		mounted() {
			let _this = this
			this.indexData()
			this.getdateInfo()

			// this.tqData()
			// this.areaData()
			// this.areaData2()
			_this.username = localStorage.getItem('username')
			_this.nickname = localStorage.getItem('nickname')
			_this.avatar = localStorage.getItem('avatar') || '../static/moren-head.jpg'
		},

		methods: {

			// 获取首页数据
			async indexData() {
				let _this = this
				const res = await _this.get('index/main')
				_this.total_user = res.total_user
				_this.day_online_user = res.day_online_user
				_this.day_offline_user = res.day_offline_user
				_this.day_haserr_user = res.day_haserr_user
				_this.total_device = res.total_device
				_this.total_devonline = res.total_devonline
				_this.devstatus_offline = res.devstatus_offline
				_this.devstatus_haserr = res.devstatus_haserr
				_this.data_devarea = res.data_devarea
				_this.month_area = res.month_area
				_this.day_co2 = res.day_co2;
				_this.tqData()
				_this.areaData()
				_this.areaData2()
				_this.areaData6();
				_this.getDeviceList()
			},

			// 设备列表不分页
			async getDeviceList() {
				let that = this;
				const res = await that.get('device/lists_nopage', {
					// project_id: 10001
				});
				for (let i in res.list) {
					res.list[i].icon = 'https://www.kochem.net.cn/images/icon5.png'
				}
				that.markers = res.list
				console.log(that.markers)
				// that.markers.push(res.list[0]);
				// for(let i in that.markers){
				// 	console.log(that.markers[i].latitude,that.markers[i].longitude)
				// }
				// console.log(that.markers)


			},
			// 点击点坐标赋值
			lookDetail(marker) {

				marker.showFlag = true;
				this.infoWindow = marker;
				console.log(marker);
				localStorage.setItem('device_title', marker.device_title)
				if (marker.type == 0 || marker.type == 1) {
					this.$router.push({
						path: '/equip/list/detail',
						query: {
							id: marker.id
						}
					})
				} else if (marker.type == 2) {
					this.$router.push({
						path: '/equip/list/detail1',
						query: {
							id: marker.id
						}
					})
				} else {
					this.$router.push({
						path: '/equip/list/detail2',
						query: {
							id: marker.id
						}
					})
				}
			},
			// 关闭弹窗
			infoWindowClose(marker) {
				marker.showFlag = false
			},
			// 打开弹窗
			infoWindowOpen(marker) {
				marker.showFlag = true
			},

			mapReady({
				BMap,
				map
			}) {
				// 选择一个经纬度作为中心点
				this.point = new BMap.Point(117.30794, 31.79322);
				map.centerAndZoom(this.point, 5);
				//    this.workingIcon = new BMap.Icon(require("../../assets/images/working.png"), new BMap.Size(30,30));


			},
			// 接入面积统计
			areaData() {
				let _this = this
				var myChart = this.$echarts.init(document.getElementById("chart1"), 'shine');
				var option = {
					color: ["#01A9AA", "#01A9AA"],
					title: {
						text: "",
						textStyle: {
							fontSize: 16,
							padding: "10px"
						}
					},
					legend: {
						data: ["当年", "当年"],
						textStyle: {
							color: '#00D9D5'
						},
						right: '0'
					},
					tooltip: {},
					xAxis: {
						data: _this.month_area.map(x => x.name),
						axisLine: {
							show: true,
							lineStyle: {
								type: 'solid',
								color: '#2772E9',
								opacity: 0.6
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 12
						}
					},


					yAxis: {
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#2772E9',
								opacity: 0.4
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 10,
							formatter: '{value}/万㎡'
						}
					},
					grid: {
						left: '14%',
						top: '30%',
						bottom: '12%',
						right: 0,
					},
					series: [
						// {
						// 		barWidth: 20,
						// 		data:  ["0", "0.2", "1.2", "1.3","2.2", "2.3"],
						// 		name: "当年",
						// 		type: "bar",

						// },
						{
							barGap: "-100%",
							/*这里设置包含关系，只需要这一句话*/
							barWidth: 10,
							data: _this.month_area.map(x => x.value),
							name: "当年",
							type: "bar",

						}
					]
				};
				myChart.setOption(option, true);
			},
			areaData2() {
				let _this = this
				var myChart = this.$echarts.init(document.getElementById("chart2"), 'shine');
				var option = {
					color: ['#38E293', '#38E293'],
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data: ["当月", "累计"],
						textStyle: {
							color: '#00D9D5'
						},
						right: '0'
					},
					grid: {
						left: '12%',
						top: '30%',
						bottom: '12%',
						right: 0,
					},
					xAxis: {
						data: _this.day_co2.map(x => x.name),
						axisLine: {
							show: true,
							lineStyle: {
								type: 'solid',
								color: '#2772E9',
								opacity: 0.6
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 12
						},
						boundaryGap: ['00%', '0%']
					},

					yAxis: {
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#2772E9',
								opacity: 0.4
							}
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 12,
							formatter: '{value}万吨'
						}
					},
					series: [{
							name: '当月',
							type: 'line',
							stack: '总量',
							smooth: true,
							lineStyle: {
								width: 1
							},
							showSymbol: false,
							areaStyle: {
								opacity: 1,
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
										offset: 0,
										color: 'rgba(25,104,255,1)' // 0% 处的颜色
									}, {
										offset: 1,
										color: 'rgba(255,255,255,0)' // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							},
							emphasis: {
								focus: 'series'
							},
							data: _this.day_co2.map(x => x.value)
						},
						// {
						// 		name: '累计',
						// 		type: 'line',
						// 		stack: '总量',
						// 		smooth: true,
						// 		lineStyle: {
						// 				width: 1
						// 		},
						// 		showSymbol: false,
						// 		areaStyle: {
						// 				opacity: 1,
						// 				color: {
						// 				    type: 'linear',
						// 				    x: 0,
						// 				    y: 0,
						// 				    x2: 0,
						// 				    y2: 1,
						// 				    colorStops: [{
						// 				        offset: 0, color: 'rgba(56,226,147,1)' // 0% 处的颜色
						// 				    }, {
						// 				        offset: 1, color: 'rgba(255,255,255,0)' // 100% 处的颜色
						// 				    }],
						// 				    global: false // 缺省为 false
						// 				}
						// 		},
						// 		emphasis: {
						// 				focus: 'series'
						// 		},
						// 		data: [0.3, 2.2, 1.3, 0.4, 0.1, 2.1,1.1]
						// }
					]
				};
				myChart.setOption(option, true);
			},
			// 接入面积统计
			areaData6() {
				let _this = this
				console.log(this.$echarts)
				var myChart = this.$echarts.init(document.getElementById("chart6"), 'shine');
				var option = {
					title: {
						text: "",
						textStyle: {
							fontSize: 16,
							padding: "10px"
						}
					},
					tooltip: {
						position: function(point, params, dom, rect, size) {
							// 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
							// 提示框位置
							var x = 0; // x坐标位置
							var y = 0; // y坐标位置
							// 当前鼠标位置
							var pointX = point[0];
							var pointY = point[1];
							// 提示框大小
							var boxWidth = size.contentSize[0];
							var boxHeight = size.contentSize[1];
							// boxWidth > pointX 说明鼠标左边放不下提示框
							if (boxWidth > pointX) {
								x = 5;
							} else { // 左边放的下
								x = pointX - boxWidth / 2;
							}
							// boxHeight > pointY 说明鼠标上边放不下提示框
							if (boxHeight > pointY) {
								y = 5;
							} else { // 上边放得下
								y = pointY - boxHeight;
							}
							return [x, y];
						}
					},
					xAxis: {
						data: _this.data_devarea.map(x => x.name),
						axisLine: {
							type: 'category',
							show: true,
							lineStyle: {
								type: 'solid',
								color: '#2772E9',
							},

						},


						axisTick: {
							show: false,
						},
						axisLabel: {
							show: true,
							color: '#fff',
							backgroundColor: "#2772E9",
							fontSize: 12,
							interval: 1,

							padding: 3,
							margin: 5
						}
					},

					yAxis: {
						// data: [0, 5, 10, 15, 20, 25, 30],
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#2772E9',
								opacity: 0
							}
						},
						axisTick: {
							show: false,
							interval: 0
						},
						axisLabel: {
							show: true,
							color: '#ccc',
							fontSize: 12,
						}
					},
					grid: {
						left: '5%',
						top: '0%',
						bottom: '0%',
						right: 0,
						height: '50%'
					},
					series: [{
						barGap: "-100%",
						/*这里设置包含关系，只需要这一句话*/
						barWidth: 15,

						data: _this.data_devarea.map(x => x.value),
						name: "累计",
						type: "bar",
						itemStyle: {
							color: new this.$echarts.graphic.LinearGradient(
								0, 0, 0, 1,
								[{
										offset: 1,
										color: '#1376BB'
									},
									{
										offset: 0,
										color: '#00C5AB'
									}
								]
							)
						},
					}]
				};
				myChart.setOption(option, true);
			},



			// 跳转路由
			navigation(path) {
				this.$router.push({
					path
				})
			},
			// 导航列表
			async meaulist() {
				let _this = this;
				let access_id = 0
				const res = await _this.get('basic/home_menus', {
					access_id
				})
				_this.leftarr = res.menus.filter((x, index) => index < 3)
				_this.rightarr = res.menus.filter((x, index) => index >= 3)
			},

			handleChange(e) {
				this.cityName = e[1];
				this.model11 = e;
				this.tqData()
			},

			// 获取当前温度
			async tqData() {

				const res = await this.get('basic/weather', {
					device_id: this.device_id || '',
					city: this.cityName
				});
				this.dew = res.temp
				this.icon = res.icon
			},

			// 选择左侧导航
			async chooseLeftmeau(access_id, path) {
				let _this = this;
				localStorage.setItem('access_id', access_id)
				_this.$router.push({
					path: '/' + path
				});

			},

			async menuslist() {
				let _this = this
				let access_id = localStorage.getItem('access_id')
				const res = await _this.get('basic/menus', {
					access_id
				})
				_this.memusList = res.menus
				this.routetitle()
			},

			// 通过路由去找对应的名称
			routetitle() {
				let _this = this,
					{
						hashleft,
						memusList
					} = _this;
				var title;
				memusList.forEach((item, index) => {
					if (item.route == hashleft) {
						console.log(item)
						return title = item.title
					} else {
						item.children.forEach((x, i) => {
							if (x.route == hashleft) {
								return title = x.title
							}
						})
					}
				})
				_this.title_text = title
			},

			// 获取当前时间
			getdateInfo() {
				let _this = this
				let myDate = new Date();
				_this.dateinfo = _this.util.getDate()
				_this.date = myDate.toLocaleDateString()
				times = setInterval(() => {
					_this.dateinfo = _this.util.getDate()
				}, 1000)
			}
		},


		watch: {
			$route(to, from) {
				this.hash = '#' + to.matched[0].path
				this.hashleft = to.path.substr(1)
				this.routetitle()
			}
		},

		destroyed() {
			clearInterval(times)
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.echart-homebox,
.echart-homebox[data-v-957c9522],
.echart-homebox[data-v-7f747d1e] {
	bottom: -120rem !important;
}
.ivu-row .ivu-cascader {
	border-radius: 3px;
	height: 24px !important;
	width: 165rem;
}
.ivu-cascader .ivu-input {
	height: 24px;
	padding: 0 0px 0 2px;
	color: #48cbcc;
	outline: none;
	font-size: 12px;
}
.top-header {
	// min-width: 1920rem;
	height: 90rem;
	background: url(../static/top_bg.png) no-repeat center;
	background-size: 100% 100%;
	.top-text {
		width: 544rem;
		height: 35rem;
		// left: 50%;
		// top: 50%;
		// transform: translate(-50%,-50%);
		top: -25rem;
	}
	.top-btn {
		width: 192rem;
		height: 50rem;
		background: url(../static/topbtn-bg.png) no-repeat center;
		background-size: 100% 100%;
		line-height: 50rem;
		opacity: 0.7;
		cursor: pointer;
	}
	.top-btn.right {
		background: url(../static/topbtn-bg2.png) no-repeat center;
		background-size: 100% 100%;
	}
	.top-btn.active {
		background: url(../static/topbtn-gb-sel.png) no-repeat center;
		background-size: 100% 100%;
		opacity: 1;
	}
	.top-btn.active.right {
		background: url(../static/topbtn-gb-sel2.png) no-repeat center;
		background-size: 100% 100%;
	}
}

.home-real {
	width: 490rem;
	
	background: url(../static/data-bg.png) no-repeat center;
	background-size: 100% 100%;
	.title {
		top: 28rem;
		left: 35rem;
		// padding-left: 35rem;
		// height: 84rem;
		// line-height: 84rem;
	}
	.content {
		padding-top: 80rem;
	}
	.icon {
		width: 23rem;
		height: 23rem;
	}
	.list-title {
		padding-left: 23rem;
	}
	.area-box {
		width: 100%;
		height: 230rem;
	}
}
.home-real.tabel {
	padding-top: 30rem;
}
.home-right {
	height: 820rem;
	
}
.map-detail {
	height: 100%;
	width: 100%;
}

.home-right-total {
	// width: 1369rem;
	z-index: 999;
	top: 0;
	width: 100%;
	height: 414rem;
	background: linear-gradient(360deg, rgba(3, 9, 9, 0) 0%, rgba(0, 77, 83, 0.7) 100%);
	.num-box {
		width: 32rem;
		height: 42rem;
		line-height: 42rem;
		text-align: center;
		background: url(../static/home-num-bg.png) no-repeat center;
		background-size: 100% 100%;
		font-size: 32rem;
		font-family: STYuanti-SC-Bold, STYuanti-SC;
		font-weight: bold;
		color: #ffffff;
	}
}
.title-bg {
	width: 226rem;
	height: 43rem;
	line-height: 43rem;
	background: url(../static/title-bg.png) no-repeat center;
	background-size: 100% 100%;
}
.head-tq {
	width: 40rem;
	height: 33rem;
}.head-tq {
	width: 40rem;
	height: 33rem;
}
.home-data-bg {
	width: 226rem;
	line-height: 64rem;
	height: 64rem;
	background: url(../static/home-title-bg.png) no-repeat center;
	background-size: 100% 100%;
	.icon {
		width: 30rem;
		height: 30rem;
		left: 17rem;
		top: 17rem;
	}
}

.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.echart-homebox {
	width: 100%;
	bottom: 0;
	height: 340rem;
}

.BMap_cpyCtrl {
	display: none;
}
 .contentnotice{
		   display: block !important;
	}
.mt-new20{margin-top: 10rem;}
.mt-new15{margin-top: 10rem;}
/deep/.anchorBL {
	display: none !important;
}
.h-160{height: 260rem;}
  @media (max-width:1440px){
	   .home-right {
	   	 height: 610rem !important;
		
	   }
	   .contentnotice{
		   display: none !important;
	   }
	   #chart1,
	   #chart2,
	   #chart3 {
	   	height: 120px;
	   }
	   .home-real {
	   	.title {
	   		top: 12rem !important; 
	   	}
	   }
	   .h-190 .title{top: 18rem !important;}
	   .mt-new20 {
	   	margin-top: 0rem;
	   }
	   .home-real .content{padding-top:37rem !important}
	   
	   .mt-new15 {
	   	  margin-top: 0rem;
	   }
	   
	   .h-160 {
	   	  height: 160rem !important
	   }
	   
	   .h-190 {
	     	height: 210rem !important
	   }
	   .home-right-total{
		   height: 40rem !important;
	   }
   }
</style>
