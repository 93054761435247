<template>
  <div class="userlist">

		<div class="data-box pl-20  pr-20 pt-25">
			<div class="flex">
				<div class="table-btn flex flex-c plr-20" @click="daochu"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></div>
				<ul class="flex flex-x-s">
					<li class="ml-15">
						<Input v-model="search" class="form-ipt" placeholder="请输入名称">
								<Button @click="userlist" slot="append" icon="ios-search"></Button>
						</Input>
					</li>
				</ul>
			</div>
			
			<div class="table-box">
				<Table ref="table" max-height="430" :row-class-name="rowClassName"  :columns="columns1" :data="data1" class="mt-15 relative" disabled-hover >
					<template slot-scope="{row}" slot="handle">
							<ul class="flex flex-c">
								<li class="handle-edit flex-c shou" @click="showDetail(row.id)">
									<span class="iconfont iconedit1"></span>
									<span class="f-14">详情</span>
								</li>
							</ul>
					</template>
				</Table>
			</div>
			
			<div class="flex flex-x-e mt-10">
				<p class="f-14 c-ff mr-20">总记录：{{total}}</p>
				<Page :page-size="per_page" :total="total" @on-change="changepage" />
			</div>
		</div>
	
		<!-- 客户详情 -->
		<Modal
				:footer-hide="true"
				v-model="is_show_detail"
				:closable="false"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-userdetail  ">
				<ul class="flex pt-20 flex-y-s">
					<li class="title-text c-ff f-20">设备时间</li>
					<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('is_show_detail')"></li>
				</ul>
				<!-- 下面就是主要客户详情内容 -->
				<div class="plr-20 ptb-30">
					<ul class="content">
						<li class="client-title f-16 c-ff plr-20">基本信息</li>
						<li class="client-con f-16 c-ff plr-20 flex">
							<div class="flex-box">姓名：{{row.nickname}}</div>
							<div class="flex-box">手机号：{{row.mobile}}</div>
						</li>
						<li class="client-con f-16 c-ff plr-20 flex">
							<div class="flex-box">注册时间：{{row.create_time}}</div>
						</li>
						<li class="client-title f-16 c-ff plr-20" v-show="list_device.length">连接设备</li>
						<li class="client-link plr-20 pb-20 f-16 c-ff" v-show="list_device.length">
							<ul>
								<li class="pt-20" v-for="item,index in list_device" :key="index">
									<div>{{index+1}}. 设备编号：{{item.code}}  <span class="ml-10">设备型号：{{item.category.name}} </span></div>
									<div class="mt-8">设备地址：{{item.address}}</div>
									<div class="mt-8">维修次数：总次数：0 当前进行中：0</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</Modal>
				
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			search: '',
			is_show_detail: false,
			client_id: 0,
			columns1: [
					{
							title: 'ID',
							key: 'id',
							align: 'center'
					},
					{
							title: '名称',
							key: 'username',
							align: 'center'
					},
					{
							title: '联系电话',
							key: 'mobile',
							align: 'center'
					},
					{
							title: '链接设备数',
							key: 'count_device',
							align: 'center'
					},
					{
							title: '注册时间',
							key: 'create_time',
							align: 'center'
					},
					{
							title: '操作',
							align: 'center',
							slot: 'handle',
							// width: 360
					}
			],
			data1: [],
			total: 0,
			per_page: 0,
			list_device: [], // 客户详情链接设备数
			row: {}, // 客户详情
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.userlist()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		
		async showDetail (id) {
			let _this = this
			this.client_id = id
			const res = await _this.get('user/detail', {id})
			// return console.log(res)
			_this.list_device = res.list_device
			_this.row = res.row
			this.changeModal('is_show_detail')
		},
		
		// 获取客户列表
		async userlist (page = 1) {
			let _this = this
			const res = await _this.get('user/lists', { page, search: _this.search })
			_this.data1 = res.list.data
			_this.total = res.list.total
			_this.per_page = res.list.per_page
		},
		
		changepage (page) {
			this.userlist(page)
		},
		
		// 导出数据--一页数据
		daochu () {
			this.$refs.table.exportCsv({
					filename: '客户列表',
					columns: this.columns1.filter((col, index) => index!=5),
					data: this.data1.map(item=>{
						item.create_time = "\t"+item.create_time,
						
						item.mobile = "\t"+item.mobile
						return item
					})
			})
		},
		// 切换弹窗显示模式
		changeModal (type) {
			this[type] = !this[type]
		}
	}
}
</script>

<style lang="less" >
	.userlist {
		.table-info-row {
			td {
				border-bottom: none!important;
				background: rgba(1, 169, 170, 0.24)!important;
			}
		}
		.table-error-row {
			td {
				border-bottom: none!important;
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}
	}
.model-line-bg {
	width: 15rem;
	height: 900rem;
	padding-top: 30rem;
}
.model-line-bg.right img{
	transform: rotateY(180deg);
}
.meau-box {
	width: 227rem;
	height: 880rem;
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.meal {
		width: 100%!important;
		background: none;
	}
	.meau-bg {
		width: 251rem;
		height: 59rem;
		left: -13rem;
	}
	.meau-bg.top {
		top: -12rem;
	}
	.meau-bg.right {
		width: 251rem;
		height: 59rem;
		bottom: -12rem;
		left: -13rem;
		transform: rotateX(180deg);
	}
	.meau-detail {
		height: 56rem;
		background: url(../../static/meau-bg.png) no-repeat center;
		background-size: 100%  100%;
		color: #48CBCC;
		line-height: 56rem;
		cursor: pointer;
	}
	.meau-detail.active {
		background: url(../../static/meau-bg-active.png) no-repeat center;
		background-size: 100%  100%;
		color: #FFFFFF;
	}
}

.title-icon {
	width: 17rem;
	height: 17rem;
}
.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.close-icon {
	width: 23rem;
	height: 25rem;
}

// 主要列表样式
.table-box {
	
}
.data-box {
	height: 790rem;
	background: url(../../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;
}
.form-ipt {
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.ivu-input {
		border: none;
		color: #48CBCC;
		background-color: rgba(0,0,0,0)
	}
	.ivu-icon-ios-search,.ivu-input::placeholder {
		color: #48CBCC;
	}
	.ivu-input-group-append {
		button {
			background: rgba(1, 169, 170, 0.1);
		}
		background: rgba(1, 169, 170, 0.1);
		border: none;
		border-left: 1px solid #48CBCC;
	}
}
.ivu-table th {
	height: 60px!important;
	background-color: rgba(0, 0,0, .1)!important;
	border-bottom: none;
	color: #fff;
	font-size: 14rem;
	font-weight: bold;
}
.ivu-table td {
	height: 60rem;
	background: rgba(1, 169, 170, 0.2);
	border-bottom: 5px solid #111727;
	color: #fff;
	font-size: 14rem;
}
.ivu-table {
	background-color: rgba(0,0,0,0);
}
.ivu-table:before {
	background-color: rgba(0, 0,0, .1)!important;
}
.table-type {
	background-color: #5EB95E;
	height: 24rem;
	line-height: 24rem;
	display: inline-block;
}
.handle-edit, .handle-del {
	width: 70rem;
	height: 32rem;
	.f-14 {
		margin-left: 3rem;
	}
}
.handle-edit {
	border: 1px solid #74B8F4;
	color: #74B8F4;
}
.handle-del {
	border: 1px solid #F16252;
	color: #F16252;
}
// 弹窗
.model-pop {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 710rem;
	height: 478rem;
	background: url(../../static/data-bg.png) no-repeat center;
	background-size: 100% 100%;
}

.model-userdetail {
	.content {
		border: 1px solid #027276;
	}
	width: 900rem;
	// height: 418rem;
	border: 1px solid #01A9AA;
	background: linear-gradient(360deg, #030909 0%, #004D53 100%);
	.title-text {
		width: 226rem;
		height: 43rem;
		line-height: 43rem;
		padding-left: 40rem;
		background: url(../../static/title-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
	.client-title,.client-con {
		// width: 860px;
		height: 50rem;
		line-height: 50rem;
		background: rgba(1, 169, 170, 0.24);
		border-bottom: 1px solid #027276;
	}
	.client-con {
		background: rgba(1, 169, 170, 0.08);
	}
	.client-link {
		background: rgba(1, 169, 170, 0.08);
	}
}
.ivu-modal-content,.ivu-modal-body {
	background-color: rgba(0,0,0,0);
	padding: 0;
}

</style>
