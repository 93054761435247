<template>
	<div>

		<div class="map-box  flex">
			<!-- 左侧列表 -->
			<div class="map-list mr-15">
				<!-- 标签 -->
				<ul class="tabs-title flex flex-x-s t-c shou">
					<li class="active" @click="navigation('/equip/list')">项目用户</li>
					<li @click="navigation('/equip/list/common')">普通用户</li>
				</ul>
				<div class="pl-15 pr-20 mt-25">
					<Input v-model="search_proname" class="form-ipt" placeholder="请输入名称">
					<Button slot="append" icon="ios-search" @click="projectList"></Button>
					</Input>
				</div>
				<div class="plr-30 mt-20 f-16 bold c-10b">项目设备（{{total}}）</div>
				<div class="map-pro mt-10 plr-30">
					<ul class="f-14 pl-15">
						<li v-for="(item,index) in prolist" @click="changeNav(index)"
							:class="nav_index == index?'c-48c active relative':' c-ff'">{{item.name}}</li>
					</ul>
				</div>
				<!-- 分页 -->
				<div class="flex flex-x-e" style="margin-top: 200rem;">
					<p class="f-14 c-ff mr-20 mt-10 mb-10">总记录：{{total}}</p>
					<Page :simple="true" :page-size="per_page" :total="total" @on-change="changepage" />
				</div>
			</div>
			<!-- 右侧项目地图 -->
			<div class="flex-box map flex flex-dir-col flex-y-s right-list">
				<div class="plr-20 maplist-title flex">
					<p class="f-16 c-ff bold ">{{project_name}}</p>
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20 shou mr-10" @click="addEq()"><span
								class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
						<!-- <li class="table-btn flex flex-c plr-20 shou" @click="daochu"><span class="iconfont icondownload mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></li> -->
					</ul>
				</div>
				<div class="flex-box w100 pt-15 plr-25" style="overflow-x: auto;">
					<ul class="flex flex-x-s ">
						<li class="f-14 c-ff mr-10 ">创建时间：</li>
						<li class="mr-10 mb-10">
							<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end"
								placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
						</li>
						<li class="mr-10 mb-10">
							<Select v-model="haserr" style="width:160px" placeholder="是否故障">
								<Option :value="1">故障</Option>
								<Option :value="0">正常</Option>
							</Select>
						</li>
						<li class="mr-10 mb-10">
							<Select v-model="online" style="width:160px" placeholder="在线状态">
								<Option :value="1">在线</Option>
								<Option :value="0">离线</Option>
							</Select>
						</li>
						<li class="mr-10 mb-10">
							<Select v-model="cate_id" style="width:160px" placeholder="全部类别">
								<Option v-for="(item,index) in modeldata" :value="item.id" :key="index">{{ item.name }}
								</Option>
							</Select>
						</li>
						<li class="mr-10 mb-10">
							<Input v-model="search" class="form-ipt" placeholder="请输入设备编码">
							<Button slot="append" @click="eqlistData" icon="ios-search"></Button>
							</Input>
						</li>
					</ul>

					<Table :columns="columns1" :data="data1" disabled-hover width="1340">
						<template slot-scope="{row}" slot="state">
							<div class="state-color flex-c" v-if="row.zx == '在线'">
								<div class="dight"></div>
								<span>在线</span>
							</div>
							<div class="state-color bad flex-c" v-else>
								<div class="dight"></div>
								<span>离线</span>
							</div>
						</template>
						<template slot-scope="{row}" slot="time">
							<div class="f-14 c-ff">
								<p class="onelist">{{row.remark}}</p>
								<p>{{row.create_time}}</p>
							</div>
						</template>
						<template slot-scope="{row}" slot="handle">
							<ul class="flex flex-x-s">
								<li class="handle-edit flex-c shou"
									@click="navigationTo(row.category.type, row.id,row.device_title)">
									<span class="iconfont iconwarnning"></span>
									<span class="f-14">详情</span>
								</li>
								<li class="handle-edit flex-c ml-8 shou" @click="editEq(row)">
									<span class="iconfont iconedit1"></span>
									<span class="f-14">编辑</span>
								</li>
								<li class="handle-edit flex-c ml-8 shou" @click="exports(row)" v-if="row.category_id==10">
									<span class="iconfont iconedit1"></span>
									<span class="f-14">导入</span>
								</li>
								<li class="handle-edit flex-c ml-8 shou" @click="exports_categroy(row)" v-if="row.category_id==11">
									<span class="iconfont iconedit1"></span>
									<span class="f-14">导入</span>
								</li>
								<li class="handle-edit flex-c ml-8 shou" style="width: 73rem;" @click="changeText(row)">
									<span class="iconfont iconedit1"></span>
									<span class="f-12" style="font-size: 12px;">文字替换</span>
								</li>
								<li class="handle-del flex-c ml-8 shou" @click="delEq(row.id)">
									<span class="iconfont icondelect"></span>
									<span class="f-14">删除</span>
								</li>
							</ul>
						</template>
					</Table>

				</div>
				<!-- 分页 -->
				<div class="flex flex-x-e mt-20 tosfw">
					<p class="f-14 c-ff mr-20 mt-10">总记录：{{total_pro}}</p>
					<Page :page-size="per_pro" :total="total_pro" @on-change="changepage1" />
				</div>
			</div>
		</div>



		<Drawer :title="add_title" v-model="is_add" width="40%" :mask-closable="false">
			<ul>
				<li class="flex flex-x-s flex-y-s  mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备型号</div>
					<div class="flex-box">
						<Select v-model="options_add.category_id" class="gz w100">
							<Option v-for="(item,index) in modeldata" :value="item.id" :key="index">{{ item.name }}
							</Option>
						</Select>
					</div>
				</li>
				<li class="flex flex-x-s flex-y-s  mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">所属项目</div>
					<div class="flex-box">
						<Select v-model="options_add.project_id" class="gz w100">
							<Option v-for="(item,index) in proListNew" :value="item.project_id" :key="index">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</li>

				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备编码</div>
					<div class="flex-box"><Input v-model="options_add.code" placeholder="请填写设备编码" class="b-b w100" />
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备台数</div>
					<div class="flex-box"><Input v-model="options_add.group_num" placeholder="请填写设备台数"
							class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备SN</div>
					<div class="flex-box"><Input v-model="options_add.sn" placeholder="请填写设备SN" class="b-b w100" />
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">省市区</div>
					<div class="flex-box">
						<Cascader class="Cascader " style="width: 100%" size="large" :data="options"
							v-model="selectedOptions1" @on-change="addressChange1">
						</Cascader>
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">坐标系</div>
					<div class="flex-box"><Input v-model="options_add.longitude" placeholder="点击地图拾取"
							style="width: 100px" class="b-b" /></div>
					<div class="flex-box"><Input v-model="options_add.latitude" placeholder="点击地图拾取"
							style="width: 100px" class="b-b" /></div>
				</li>
				<li class="mt-15">
					<baidu-map class="bm-view" ak="QFgFQorrB84maOZh0pPGC8kUiP0mGIhx"
						:center="options_add.province + options_add.city + options_add.region || '中国'"
						style="height: 300rem;width: 100%;" :zoom="zoom" :scroll-wheel-zoom="true" @ready="createMap">

						<bm-marker :position="{lng: options_add.longitude, lat: options_add.latitude}" />
					</baidu-map>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">详细地址</div>
					<div class="flex-box"><Input v-model="options_add.address" placeholder="请填写详细地址" class="b-b w100" />
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目名称</div>
					<div class="flex-box"><Input v-model="options_add.device_title" placeholder="请填写项目名称"
							class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">安装时间</div>
					<DatePicker type="date" v-model="options_add.fix_date" @on-change="changeFixDate"
						placement="bottom-end" placeholder="请选择时间" style="border: 1px solid #48CBCC;flex:1">
					</DatePicker>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户姓名</div>
					<div class="flex-box"><Input v-model="options_add.client_name" placeholder="请填写客户姓名"
							class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户电话</div>
					<div class="flex-box"><Input v-model="options_add.client_mobile" placeholder="请填写客户电话"
							class="b-b w100" /></div>
				</li>

				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">密码</div>
					<div class="flex-box"><Input v-model="options_add.password" placeholder="请填写密码,不填表示不做修改"
							class="b-b w100" /></div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">匹数</div>
					<div class="flex-box"><Input v-model="options_add.horse" placeholder="请填写匹数" class="b-b w100" />
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">视频展示图片</div>
					<div class="flex-box">
						<Upload action="https://www.kochem.net.cn/wap/upload/image" name="file" :headers="{token}"
							:on-success="uploadSuccess1" :show-upload-list="false"
							accept="image/png,image/tiff,image/jpeg" :format="['jpg','jpeg','png','tiff']">
							<div class="table-btn flex-c" style="width: 132rem;">
								<span class="iconfont iconshangjia3 f-14 c-ff"></span>
								<span class="f-14 c-ff ml-8">选择图片</span>
							</div>
						</Upload>
						<ul class="flex flex-x-s pr-40  mt-20" v-if="video_img">
							<img :src="video_img" style="width:200px;height:100px" />
						</ul>
						<p class="f-14 c-10b">建议高度：660*476</p>
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">模型展示图片</div>
					<div class="flex-box">
						<Upload action="https://www.kochem.net.cn/wap/upload/image" name="file" :headers="{token}"
							:on-success="uploadSuccess2" :show-upload-list="false"
							accept="image/png,image/tiff,image/jpeg" :format="['jpg','jpeg','png','tiff']">
							<div class="table-btn flex-c" style="width: 132rem;">
								<span class="iconfont iconshangjia3 f-14 c-ff"></span>
								<span class="f-14 c-ff ml-8">选择图片</span>
							</div>
						</Upload>
						<ul class="flex flex-x-s pr-40  mt-20" v-if="model_img">
							<img :src="model_img" style="width:200px;height:100px" />
						</ul>
						<p class="f-14 c-10b">建议高度：400*270</p>
					</div>
				</li>
				<li class="flex flex-x-s mt-15">
					<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">萤石云外链地址链接</div>
					<div class="flex-box"><Input v-model="options_add.vedio_url" placeholder="请填写萤石云外链地址链接"
							class="b-b w100" /></div>
				</li>



				<li class="flex flex-x-c flex-y-s  mt-50">
					<div class="submit-btn f-18 c-ff t-c mr-40 cancel shou" @click="changemodal('is_add')">取消</div>
					<div class="submit-btn f-18 c-ff t-c shou" @click="submitEq">提交</div>
				</li>

			</ul>

		</Drawer>
		<!-- <Drawer :title="add_title" v-model="is_add" width="40%" :mask-closable="false"> -->
		<!-- 	<div id="hideadd">
					<div>
						<li class="flex flex-x-s flex-y-s  mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备型号</div>
							<div class="flex-box">
								<Select v-model="options_add.category_id" class="gz w100">
									 <Option v-for="(item,index) in modeldata" :value="item.id" :key="index">{{ item.name }}</Option>
							  </Select>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备编码</div>
							<div class="flex-box"><Input v-model="options_add.code" placeholder="请填写设备编码"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备SN</div>
							<div class="flex-box"><Input v-model="options_add.sn" placeholder="请填写设备SN"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目名称</div>
							<div class="flex-box"><Input v-model="options_add.device_title" placeholder="请填写项目名称"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">安装时间</div>
							<DatePicker type="date" v-model="options_add.fix_date" @on-change="changeFixDate" placement="bottom-end" placeholder="请选择时间" style="border: 1px solid #48CBCC;flex:1"></DatePicker>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户姓名</div>
							<div class="flex-box"><Input v-model="options_add.client_name" placeholder="请填写客户姓名"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户电话</div>
							<div class="flex-box"><Input v-model="options_add.client_mobile" placeholder="请填写客户电话"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">省市区</div>
							<div class="flex-box"><Cascader 
									class="Cascader "
									style="width: 100%"
									size="large"
									:data="options"
									v-model="selectedOptions1"
									@on-change="addressChange1">
								</Cascader ></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">坐标系</div>
							<div class="flex-box"><Input v-model="options_add.longitude" placeholder="点击地图拾取" style="width: 100px"  class="b-b" /></div>
							<div class="flex-box"><Input v-model="options_add.latitude" placeholder="点击地图拾取" style="width: 100px"  class="b-b" /></div>
						</li>
						<div class="mt-15">
							 <baidu-map
									class="bm-view"
									ak="QFgFQorrB84maOZh0pPGC8kUiP0mGIhx"
									:center="options_add.province + options_add.city + options_add.region || '中国'"
									style="height: 300rem;width: 40vw;overflow: hidden;"
									:zoom="zoom"
									:scroll-wheel-zoom="true"
									@ready="createMap"
								>
											
									<bm-marker :position="{lng: options_add.longitude, lat: options_add.latitude}"/>
								</baidu-map>
						</div>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">详细地址</div>
							<div class="flex-box"><Input v-model="options_add.address" placeholder="请填写详细地址"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">密码</div>
							<div class="flex-box"><Input v-model="options_add.password" placeholder="请填写密码,不填表示不做修改"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">匹数</div>
							<div class="flex-box"><Input v-model="options_add.horse" placeholder="请填写匹数"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">视频展示图片</div>
							<div class="flex-box">
					      <Upload action="https://www.kochem.net.cn/wap/upload/image" 
									name="file" 
									:headers="{token}"
									:on-success="uploadSuccess1"
									:show-upload-list="false"
									accept="image/png,image/tiff,image/jpeg"
									:format="['jpg','jpeg','png','tiff']"> 
										<div class="table-btn flex-c" style="width: 132rem;">
											<span class="iconfont iconshangjia3 f-14 c-ff"></span>
											<span class="f-14 c-ff ml-8">选择图片</span>
										</div>
								</Upload>
								<ul class="flex flex-x-s pr-40  mt-20" v-if="video_img">
									<img :src="video_img" style="width:200px;height:100px"/>
								</ul>
								<p class="f-14 c-10b">建议高度：660*476</p>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">模型展示图片</div>
						   		<div class="flex-box">
								<Upload action="https://www.kochem.net.cn/wap/upload/image" 
								name="file" 
								:headers="{token}"
								:on-success="uploadSuccess2"
								:show-upload-list="false"
								accept="image/png,image/tiff,image/jpeg"
								:format="['jpg','jpeg','png','tiff']"> 
									<div class="table-btn flex-c" style="width: 132rem;">
										<span class="iconfont iconshangjia3 f-14 c-ff"></span>
										<span class="f-14 c-ff ml-8">选择图片</span>
									</div>
								</Upload>
								<ul class="flex flex-x-s pr-40  mt-20" v-if="model_img">
									<img :src="model_img" style="width:200px;height:100px"/>
								</ul>
								<p class="f-14 c-10b">建议高度：400*270</p>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">萤石云外链地址链接</div>
							<div class="flex-box"><Input v-model="options_add.vedio_url" placeholder="请填写萤石云外链地址链接"   class="b-b w100" /></div>
						</li>
						
						
						
						<li class="flex flex-x-c flex-y-s  mt-50">
							<div class="submit-btn f-18 c-ff t-c mr-40 cancel shou" @click="changemodal('is_add')">取消</div>
							<div class="submit-btn f-18 c-ff t-c shou" @click="submitEq">提交</div>
						</li>
						
					</div>
				</div> -->
		<!-- </Drawer> -->
		<!-- 			<div class="">
				<div id="">
					<baidu-map
													class="bm-view"
													ak="QFgFQorrB84maOZh0pPGC8kUiP0mGIhx"
													:center="options_add.province + options_add.city + options_add.region || '中国'"
													style="height: 300rem;width: 40vw;"
													:zoom="zoom"
													:scroll-wheel-zoom="true"
													@ready="createMap"
												>
									
													<bm-marker :position="{lng: options_add.longitude, lat: options_add.latitude}"/>
												</baidu-map>
				</div>
				
			</div> -->

		<Modal :footer-hide="true" v-model="is_show_del" :closable="false" width="471" :mask-closable="false">
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changemodal('is_show_del')"></li>
				</ul>
				<p class="t-c f-20 bold mt-30" style="color: #54FDF5;">友情提示</p>
				<p class="t-c f-16 c-ff mt-20">是否确定删除该设备?</p>
				<li class="flex flex-c mt-40">
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changemodal('is_show_del')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitdel">确认</div>
				</li>
			</div>
		</Modal>

		<!-- 弹窗 -->
		<Modal :footer-hide="true" v-model="is_show_jz" :closable="false" width="710" :mask-closable="false">
			<span slot="title"></span>
			<div class="model-pop-jc">
				<ul class="flex pt-20 flex-y-s">
					<li class="title-text c-ff f-20">信息展示</li>
					<li class="iconfont iconclose1 c-48c pr-20 shou" @click="is_show_jz=false"></li>
				</ul>
				<div class="add_text" @click="addTextAll"><span
								class="iconfont iconadd1 mr-5 f-12 c-ff"></span>添加字段</div>
				 <Table :columns="columns2" :data="replace_text">
				        <template #old_name="{ row, index }">
				          <Input v-model="replace_text[index].old_name" style="border: 1px solid #fff !important;" />
				        </template>
				        <template #new_name="{ row, index }">
				          <Input v-model="replace_text[index].new_name" style="border: 1px solid #fff !important;"/>
				        </template>
				        <template #action="{ row, index }">
				        <Button size="small" style="margin-right: 5px" @click="removeTable(index)">
				        	移除
				        </Button>
				        </template>
				    </Table>
			        <li class="flex flex-x-c mt-50" style="padding-bottom: 20rem;">
			        	<div class="quxiao f-18 t-c c-10b shou mr-30" @click="is_show_jz=false">取消</div>
			        	<div class="queding f-18 c-ff t-c shou" @click="submittimetask">提交</div>
			        </li>
			</div>

		</Modal>
		<!-- 弹窗1 -->
		<Modal :footer-hide="true" v-model="is_export" :closable="false" width="471" :mask-closable="false">
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changemodal('is_export')"></li>
				</ul>
				<li class="mr-10 mb-10">
					<p class="name-color mb-10">选择模板</p>
					<Upload
					     ref="uploadfiles1"
						:before-upload="beforeUpload"
						action="https://www.kochem.net.cn/wap/">
						<Button type="ghost" icon="ios-cloud-upload-outline">导入模板</Button>
					</Upload>
					<p class="name-color" @click="downTemplate('1')">默认模板格下载</p>
				</li>
				<li class="flex flex-c mt-40">
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changemodal('is_export')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="handleCom()">确认</div>
				</li>
			</div>
		</Modal>
		
		
		<Modal :footer-hide="true" v-model="is_export_ce" :closable="false" width="471" :mask-closable="false">
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changemodal('is_export_ce')"></li>
				</ul>
				<li class="mr-10 mb-10">
					<p class="name-color mb-10">选择模板</p>
					<Upload
					     ref="uploadfiles"
						:before-upload="beforeUpload"
						action="https://www.kochem.net.cn/wap/">
						<Button type="ghost" icon="ios-cloud-upload-outline">导入模板</Button>
					</Upload>
					<p class="name-color" @click="downTemplate('2')">默认模板格下载</p>
				</li>
				<li class="flex flex-c mt-40">
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changemodal('is_export_ce')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="handleCom_cate()">确认</div>
				</li>
			</div>
		</Modal>
	</div>
</template>

<script>
	import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
	import {
		regionData,
		CodeToText
	} from 'element-china-area-data'

	export default {
		data() {
			return {
				file:{},
				is_export:false,
				is_export_ce:false,
				is_show_jz: false,
				haserr: -1,
				online: -1,
				search: '',
				cate_id: 0,
				time: '',
				modeldata: [],
				proListNew: [],
				columns2: [
					// {
					// 	title: '编号',
					// 	key: 'idx'
					// },
					{
						title: '原文字',
						slot: 'old_name'
					},
					{
						title: '新文字',
						slot: 'new_name'
					},
					{
						title: '操作',
						align: 'left',
						slot: 'action',
						width: 180
					}
				],
				columns1: [{
						title: 'ID',
						key: 'id',
						width: 70,
					},
					{
						title: '编号',
						key: 'code'
					},

					{
						title: '项目名称',
						key: 'device_title',
						className: 'name-color'
					},
					{
						title: '型号',
						key: 'ccode',
						width: 100
					},
					{
						title: '设备SN',
						key: 'sn',
						align: 'center',
						width: 160
					},
					{
						title: '状态',
						slot: 'state',
						align: 'center',
						width: 120
					},
					{
						title: '备注/创建时间',
						slot: 'time',
						width: 200
					},

					{
						title: '操作',
						align: 'left',
						slot: 'handle',
						width: 430
					}
				],
				data1: [],
				search_proname: '',
				prolist: [],
				total: 0,
				per_page: 0,
				nav_index: 0,
				project_id: 0, // 项目id
				total_pro: 0,
				per_pro: 0,
				start_time: '',
				end_time: '',
				project_name: '',
				center: {
					lng: 0,
					lat: 0
				},
				zoom: 10,
				model: {
					lng: '',
					lat: '',
					address: ''
				},
				options: regionData,
				is_add: false,
				is_show_del: false,
				add_title: '新增设备',
				selectedOptions1: [],
				modeldata: [],
				replace_text: [],
				edit_device_id: 0,
				device_id_s:0,
				del_id: 0,
				model_img: '',
				video_img: '',
				token: localStorage.getItem('token'),
				options_add: {
					fix_date: '',
					device_title: '',
					category_id: 0,
					code: '',
					group_num: '',
					sn: '',
					password: '123456',
					province: '',
					city: '',
					region: '',
					address: '',
					client_mobile: '',
					client_name: '',
					longitude: 0,
					latitude: 0,
					vedio_img_id: '',
					model_img_id: '',
					vedio_url: '',
					horse: '',
					project_id: 0
				},
			}
		},


		created() {},

		components: {
			BaiduMap,

			// BmLocalSearch
		},


		mounted() {
			this.projectList()
			this.modellist();
			this.getPlist()

		},


		methods: {



			// 跳转路由
			navigation(path) {
				this.$router.push({
					path
				})
			},
			// 分别跳转不同详情
			navigationTo(type, id, device_title) {
				localStorage.setItem('device_title', device_title)
				let routeUrl = ''
				if (type == 0 || type == 1) {
					routeUrl = this.$router.resolve({
						path: '/equip/list/detail',
						query: {
							id
						}
					})
				} else if (type == 2) {
					routeUrl = this.$router.resolve({
						path: '/equip/list/detail1',
						query: {
							id
						}
					})
				} else {
					routeUrl = this.$router.resolve({
						path: '/equip/list/detail2',
						query: {
							id
						}
					})
				}

				// console.log(window.location.href);
				// console.log(window.location.href.split('#')[0]);

				// console.log(location.host);
				// console.log(routeUrl.href);
				console.log(location.host + '/' + routeUrl.href)
				window.open(window.location.href.split('#')[0] + routeUrl.href);

			},

			//视频图片
			uploadSuccess1(response, file, fileList) {
				// console.log(response)
				this.video_img = response.data.file_path;
				this.options_add.vedio_img_id = response.data.file_id
			},

			//模型图片
			uploadSuccess2(response, file, fileList) {
				// console.log(response)
				this.model_img = response.data.file_path;
				this.options_add.model_img_id = response.data.file_id
			},

			async projectList(page = 1) {
				let _this = this
				// { search } = _this;

				const res = await _this.get('project/lists', {
					page,
					search: _this.search_proname
				})
				_this.total = res.list.total - 0
				_this.per_page = res.list.per_page - 0
				_this.prolist = res.list.data
				if (res.list.data.length < 1) {
					_this.data1 = []
					return
				}
				_this.project_id = res.list.data[0].project_id
				_this.project_name = res.list.data[0].name
				_this.eqlistData()
			},

			// 获取设备列表
			async eqlistData(page = 1) {
				let _this = this,
					{
						project_id,
						haserr,
						online,
						search,
						cate_id,
						start_time,
						end_time
					} = _this;
				const res = await _this.get('device/lists', {
					page,
					project_id,
					haserr,
					online,
					search,
					cate_id,
					start_time,
					end_time
				})
				var data2 = []
				res.list.data.forEach(x => {

					let obj = {
						code: x.code,
						ccode: x.category.code,
						cname: x.category.name,
						category: x.category,
						replace_text: x.replace_text,
						sn: x.sn,
						nickname: x.nickname,
						mobile: x.mobile,
						address1: x.province + x.city + x.region + x.address,
						zx: x.online == 1 ? '在线' : '离线',
						gz: x.haserr == 1 ? '正常' : '故障',
						remark: x.remark,
						group_num: x.group_num,
						create_time: x.create_time,
						id: x.id,
						category_id: x.category_id,
						province: x.province,
						city: x.city,
						project_id: x.project_id,
						horse: x.horse,
						region: x.region,
						address: x.address,
						longitude: x.longitude,
						latitude: x.latitude,
						device_title: x.device_title,
						fix_date: x.fix_date,
						vedio_url: x.vedio_url,
						video_img: x.vedioimg ? x.vedioimg.file_path : '',
						model_img: x.modelimg ? x.modelimg.file_path : '',

					}
					data2.push(obj)
				})
				_this.data1 = data2
				// console.log('dddddd',_this.data1);
				_this.total_pro = res.list.total - 0
				_this.per_pro = res.list.per_page - 0
			},

			// 新建设备
			async submitEq() {
				let _this = this,
					{
						options_add,
						edit_device_id
					} = _this;
				let arr = Object.values(options_add)
				let is = arr.some(x => !x)
				if (is && !edit_device_id) {
					return _this.util.showMsg('warning', '请完善表单内容', 2, false)
				}
				if (edit_device_id) {
					var url = 'device/edit'
					var data = {
						...options_add,
						device_id: edit_device_id
					}
				} else {
					var url = 'device/add'
					var data = options_add
				}
				console.log('添加的数据', data);
				// return
				const res = await _this.post(url, data)
				_this.util.showMsg('success', '操作成功', 1.5, false)
				_this.eqlistData()
				_this.changemodal('is_add')
			},
			addressChange1(arr) {
				this.options_add.province = CodeToText[arr[0]]
				this.options_add.city = CodeToText[arr[1]]
				this.options_add.region = CodeToText[arr[2]]

			},
			createMap({
				BMap,
				map
			}) {
				this.zoom = this.zoom
				const viewthis = this
				map.addEventListener('click', function(e) {
					viewthis.options_add.longitude = e.point.lng
					viewthis.options_add.latitude = e.point.lat
				})

			},
			async submitdel() {
				let _this = this
				const res = await _this.post('device/delete', {
					device_id: _this.del_id
				})
				_this.util.showMsg('success', '操作成功', 1.5, false)
				_this.eqlistData()
				_this.changemodal('is_show_del')
			},

			changemodal(type) {
				this[type] = !this[type];
				this.$refs.uploadfiles.clearFiles();
				this.$refs.uploadfiles1.clearFiles();

			},
			delEq(id) {
				this.del_id = id
				this.changemodal('is_show_del')
			},
			editEq(data) {
				console.log(data);
				let _this = this;
				_this.options_add.vedio_img_id = data.vedio_img_id;
				_this.options_add.model_img_id = data.model_img_id;
				_this.options_add.vedio_url = data.vedio_url;
				_this.video_img = data.video_img;
				_this.model_img = data.model_img
				_this.options_add.category_id = data.category_id
				_this.options_add.code = data.code
				_this.options_add.sn = data.sn;
				_this.options_add.sn = data.sn
				_this.options_add.password = '';
				_this.options_add.horse = data.horse
				_this.options_add.province = data.province
				_this.options_add.city = data.city
				_this.options_add.region = data.region;
				_this.options_add.group_num = data.group_num;
				_this.options_add.address = data.address
				_this.options_add.client_mobile = data.mobile
				_this.options_add.client_name = data.nickname
				_this.options_add.longitude = data.longitude
				_this.options_add.latitude = data.latitude
				_this.edit_device_id = data.id;
				_this.options_add.project_id = data.project_id;
				_this.options_add.device_title = data.device_title
				_this.options_add.fix_date = data.fix_date
				_this.selectedOptions1 = [_this.findKey(data.province), _this.findKey(data.city), _this.findKey(data
					.region)]
				_this.changemodal('is_add')
			},
			addEq() {
				let _this = this
				_this.options_add.category_id = ''
				_this.options_add.code = ''
				_this.options_add.sn = ''
				_this.options_add.password = '123456'
				_this.options_add.province = ''
				_this.options_add.city = ''
				_this.options_add.region = ''
				_this.options_add.address = ''
				_this.options_add.client_mobile = ''
				_this.options_add.client_name = ''
				_this.options_add.longitude = ''
				_this.options_add.latitude = ''
				_this.options_add.project_id = _this.project_id
				_this.edit_device_id = 0
				_this.selectedOptions1 = []
				_this.changemodal('is_add')
			},

			findKey(value, compare = (a, b) => a === b) {
				return Object.keys(CodeToText).find(k => compare(CodeToText[k], value))
			},

			// 导出数据
			daochu() {
				this.$refs.table.exportCsv({
					filename: 'data',
					columns: this.columns2,
					data: this.data2
				});;
			},


			changeNav(index) {
				this.nav_index = index
				this.project_id = this.prolist[index].project_id
				this.project_name = this.prolist[index].name
				this.eqlistData()
			},
			changedate(e) {
				// console.log(e)
				this.start_time = e[0]
				this.end_time = e[1]
			},
			changeFixDate(e) {
				this.options_add.fix_date = e

			},
			changepage(page) {
				this.projectList(page)
			},
			changepage1(page) {
				this.eqlistData(page)
			},

			changeText(row) {
				
				this.replace_text = row.replace_text;
				this.device_id_s = row.id
				this.is_show_jz = true
			},
			async submittimetask(){
			  let that = this;  
			  const res = await that.post('device/replaceText',{device_id:that.device_id_s,data:that.replace_text});
			  that.util.showMsg('success', '操作成功', 1.5, false);
			  that.is_show_jz = false;
			  that.eqlistData() 
			},
			
			addTextAll(){
				let that = this;
				let obj = {};
				obj.old_name = '';
				obj.new_name = '';
				that.replace_text.push(obj)
				
			},
			   
			removeTable(index){
				let that = this;
				that.replace_text.splice(index,1)
				console.log(index)
			},
			// 获取基础信息
			// async basicsinfo (sn,pgid) {
			// 	let _this = this
			// 	const res = await _this.post('device/get_dmonvalues', {sn,pgid})
			// 	_this.basics = res;
			// 	console.log(_this.basics)

			// },
			// 获取类别
			async modellist() {
				let _this = this
				const res = await _this.get('device.category/lists')
				_this.modeldata = res.list.data
			},
			async getPlist() {
				let _this = this
				const res = await _this.get('project/all')
				_this.proListNew = res.list;

			},
            
			exports(row){
				this.is_export = true;
				
				this.device_ids = row.id
			},
			
			exports_categroy(row){
				this.is_export_ce = true;
				this.device_ids = row.id
			},
			 beforeUpload(file) {
				let that = this;
				that.file = file;
				
				
			},
			
			handleClose(){
				that.is_export = false
			},
			
			async handleCom(){
				let that  = this;
				console.log('that.file',that.file);
				const formdata = new FormData();
				formdata.append('iFile', that.file);
				const res = await that.post('Haiwei/import?device_id='+ that.device_ids, formdata);
				that.util.showMsg('success', res, 1.5, false);
				that.is_export = false;
				this.$refs.uploadfiles1.clearFiles();
			},
			
			async handleCom_cate(){
				let that  = this;
				
				const formdata = new FormData();
				formdata.append('iFile', that.file);
				const res = await that.post('Dtu/import?device_id='+ that.device_ids, formdata);
				that.util.showMsg('success', res, 1.5, false);
				that.is_export_ce = false;
				this.$refs.uploadfiles.clearFiles();
			},
			
			
			//模板下载
			downTemplate(type){
				if(type==1){
					window.open('https://www.kochem.net.cn/uploads/excel_demo/device_demo.csv')
				}else{
					window.open('https://www.kochem.net.cn/uploads/excel_demo/DTU模板.csv')
					
				}
				
			},
			
			
			

		}
	}
</script>

<style lang="less">
	.add_text {
		width: 120rem;
		margin: 10rem;
		text-align: center;
		line-height: 30rem;
		color: #fff;
		cursor: pointer;
		height: 32rem;
		line-height: 30rem;
		border: 1px solid #48CBCC;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0) 0%, rgba(72, 203, 204, 0.5) 100%);
	}

	#hideadd {
		width: 40vw;
		position: fixed;
		height: 100vh;
		overflow: scroll;
		z-index: 999;
		top: 0;
		right: 0;
		background-color: rgb(15, 38, 52);
	}

	.tosfw {
		width: 100%;
		box-sizing: border-box;
		justify-content: center;
		margin-top: 10rem;
		margin-bottom: 10rem;
		margin-left: 10rem;
	}

	td.name-color {
		color: #48CBCC !important;
	}

	.state-color {
		color: #8FF38E;

		.dight {
			width: 6rem;
			height: 6rem;
			background: #8FF38E;
			border-radius: 3rem;
			margin-right: 10rem;
		}
	}

	.state-color.bad {
		color: #F56E6E;

		.dight {
			width: 6rem;
			height: 6rem;
			background: #F56E6E;
			border-radius: 3rem;
			margin-right: 10rem;
		}
	}

	.map-box>div {
		height: 790rem;

	}

	// .right-list {
	// 	min-width: 1000rem;
	// 	overflow-x: auto;
	// }
	.flex-box.map {
		background: rgba(1, 169, 170, 0.1);
	}

	.map-list {
		max-width: 280rem;
		flex: 1;
		background: url(../static/table-bg.png) no-repeat bottom right;
		background-color: rgba(1, 169, 170, 0.1);
		background-size: 655px 327rem;

	}

	// 标签页
	.tabs-title {
		li {
			width: 120rem;
			height: 52rem;
			line-height: 52rem;
			border: 1px solid #48CBCC;
			color: #fff;
			opacity: 0.6;
		}

		li.active {
			background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
			opacity: 1;
		}
	}
	  /*浮在某行*/
	  .ivu-table-row-hover td {
	    background-color: rgb(2, 63, 65)!important;
	  }
	

    .ivu-table td {
    	height: 44rem;
    	background-color: none !important;
    	// border-bottom: 5px solid #111727;
    	border-bottom: none;
    	color: #fff;
    	font-size: 14rem;
    }
	.model-pop-jc{
		tr:hover{
			background-color: none !important;
		}
	}
	
	.map-pro {
		ul {
			color: #fff;
			opacity: .6;
			padding-left: 0rem;
		}

		li {
			margin-top: 10rem;
			cursor: pointer;
		}

		.ivu-collapse-simple,
		.ivu-collapse,
		.ivu-collapse>.ivu-collapse-item,
		.ivu-collapse-content {
			background-color: rgba(0, 0, 0, 0);
			border: none;
		}

		.ivu-collapse-header {
			font-size: 16rem;
			color: #fff;
			opacity: .6;
		}

		.ivu-collapse-item-active .ivu-collapse-header {
			font-size: 16rem;
			color: #fff;
			opacity: 1;
		}

	}

	.maplist-title {
		height: 55rem;
		width: 100%;
		background: rgba(1, 169, 170, 0.1);
	}

	.refund-add-title {
		width: 150rem;
	}

	.refund-add-title.must {}

	.refund-add-title.must::after {
		content: '*';
		color: #F16252;
		margin-left: 8rem;
		font-size: 16rem;
	}

	.b-b {
		border-bottom: 1px solid #01A9AA;
	}

	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(../static/xunwen-pop.png) no-repeat center;
		background-size: 100% 100%;

		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}

		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}

	}

	.ivu-modal-body {
		padding: 0;
	}

	.ivu-modal-content {
		background-color: none !important;
	}

	.ivu-date-picker {
		border: 1px solid #01A9AA;
	}

	.ivu-modal-content {
		background-color: rgba(0, 0, 0, 0);
	}

	// 弹窗
	.model-pop-jc {
		width: 710rem;
		// height: 478rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, #030909 0%, #004D53 100%);

		.info {
			width: 50%;
		}

		.title-text {
			width: 226rem;
			height: 43rem;
			line-height: 43rem;
			padding-left: 40rem;

			background: url(../static/title-bg.png) no-repeat center;
			background-size: 100% 100%;
		}
	}
	.quxiao {
		width: 140rem;
		height: 40rem;
		line-height: 40rem;
		border: 1px solid #01A9AA;
	}
	.queding {
		width: 140rem;
		height: 40rem;
		line-height: 40rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
	}
	.name-color{
		color: #48CBCC !important;
	}
</style>
