<template>
  <div>
				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<!-- <li class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li> -->
					</ul>
					<ul class="flex flex-x-s mt-20">
						<li class="f-14 c-ff mr-10">创建时间：</li>
						<li class="mr-10">
							<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择日期" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
						</li>
<!-- 						<li class="mr-10">
							<Select v-model="model1" style="width:160px" placeholder="跟进类型">
									<Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
							</Select>
						</li> -->
<!-- 						<li class="mr-10">
							<Select v-model="model1" style="width:160px" placeholder="跟进人">
									<Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
							</Select>
						</li> -->

						<li class="mr-10">
							<Input v-model="keyword" class="form-ipt" placeholder="请输入搜索关键词">
									<Button @click="loglist" slot="append" icon="ios-search"></Button>
							</Input>
						</li>
					</ul>
					<div class="table-box mt-25">
						<Table :columns="columns1" :data="data1" class="" disabled-hover>
							<template slot-scope="{ row }" slot="type">
									<div class="f-14 c-ff   plr-10">{{row.follow_type.name}}</div>
							</template>
							<template slot-scope="{ row }" slot="user">
									<div class="f-14 c-ff   plr-10">{{row.user.real_name}}</div>
							</template>
						</Table>
					</div>
					
					<!-- 分页 -->
					<div class="flex flex-x-e mt-20">
						<p class="f-14 c-ff mr-20 mt-10">总记录：{{total_pro}}</p>
						<Page :page-size="per_pro" :total="total_pro" @on-change="changepage1" />
					</div>
				</div>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			keyword: '',
			time: [],
			follow_start_time: '',
			follow_end_time: '',
			total_pro: 0,
			per_pro: 0,
			show: true,
			columns1: [
					{
							title: 'ID',
							key: 'follow_id',
							width: 100
					},
					{
							title: '项目名称',
							key: 'project_name',
							className: 'name-color'
					},
					{
							title: '跟进类型',
							slot: 'type',
					},
					{
							title: '跟进内容',
							key: 'content',
							minWidth:200
					},
					{
							title: '跟进人',
							slot: 'user',
					},
					{
							title: '跟进时间',
							key: 'create_time',
					}
			],
			data1: []
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.loglist()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		// 操作日志列表
		async loglist (page = 1) {
			let _this = this,
					{ keyword,follow_start_time,follow_end_time } = _this;
			const res = await _this.get('project/follows', { page,keyword,follow_start_time,follow_end_time })
			_this.data1 = res.list.data
			_this.total_pro = res.list.total
			_this.per_pro = res.list.per_page
		},
		
		changedate (e) {
			this.follow_start_time = e[0]
			this.follow_end_time = e[1]
			this.time = e
		},
		changepage1 (page) {
			this.loglist(page)
		}
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.model-line-bg {
	width: 15rem;
	height: 900rem;
	padding-top: 30rem;
}
.model-line-bg.right img{
	transform: rotateY(180deg);
}
.meau-box {
	width: 227rem;
	height: 880rem;
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.meal {
		width: 100%!important;
		background: none;
	}
	.meau-bg {
		width: 251rem;
		height: 59rem;
		left: -13rem;
	}
	.meau-bg.top {
		top: -12rem;
	}
	.meau-bg.right {
		width: 251rem;
		height: 59rem;
		bottom: -12rem;
		left: -13rem;
		transform: rotateX(180deg);
	}
	.meau-detail {
		height: 56rem;
		background: url(../../static/meau-bg.png) no-repeat center;
		background-size: 100%  100%;
		color: #48CBCC;
		line-height: 56rem;
		cursor: pointer;
	}
	.meau-detail.active {
		background: url(../../static/meau-bg-active.png) no-repeat center;
		background-size: 100%  100%;
		color: #FFFFFF;
	}
}

.title-icon {
	width: 17rem;
	height: 17rem;
}
.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.close-icon {
	width: 23rem;
	height: 25rem;
}

// 主要列表样式
.table-box {
	
}
.data-box {
	height: 790rem;
	background: url(../../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;
}
.form-ipt {
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.ivu-input {
		border: none;
		color: #48CBCC;
		background-color: rgba(0,0,0,0)
	}
	.ivu-icon-ios-search,.ivu-input::placeholder {
		color: #48CBCC;
	}
	.ivu-input-group-append {
		button {
			background: rgba(1, 169, 170, 0.1);
		}
		background: rgba(1, 169, 170, 0.1);
		border: none;
		border-left: 1px solid #48CBCC;
	}
}
.ivu-table th {
	height: 60px!important;
	background-color: rgba(0, 0,0, .1)!important;
	border-bottom: none;
	color: #fff;
	font-size: 14rem;
	font-weight: bold;
}
.ivu-table td {
	height: 72rem;
	background: rgba(1, 169, 170, 0.2);
	border-bottom: 5px solid #111727;
	color: #fff;
	font-size: 14rem;
}
.ivu-table {
	background-color: rgba(0,0,0,0);
}
.ivu-table:before {
	background-color: rgba(0, 0,0, .1)!important;
}
.table-type {
	background-color: #5EB95E;
	height: 24rem;
	line-height: 24rem;
	display: inline-block;
}
.handle-edit, .handle-del {
	width: 70rem;
	height: 32rem;
	.f-14 {
		margin-left: 3rem;
	}
}
.handle-edit {
	border: 1px solid #74B8F4;
	color: #74B8F4;
}
.handle-del {
	border: 1px solid #F16252;
	color: #F16252;
}
// 弹窗
.model-pop {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 710rem;
	height: 478rem;
	background: url(../../static/data-bg.png) no-repeat center;
	background-size: 100% 100%;
}
</style>
