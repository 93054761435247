<template>
  <div>
		<!-- 头部导航 -->
		<div class="relative top-header flex plr-50 pb-10 flex-y-e">
			<ul class="flex flex-box">
				<li class="top-btn f-20 t-c " @click="chooseLeftmeau(item.id,item.route)" v-for="(item,index) in leftarr" :key="index" :class="hash == ('#/' + item.route)?'active c-ff':'c-15e'"><span >{{item.title}}</span></li>
			</ul>
			<div class=" top-text  relative" style="margin: 0 130rem;"><img src="../static/top_text.png" /></div>
			<ul class="flex  flex-box">
				<li class="top-btn right f-20 t-c " @click="chooseLeftmeau(item.id,item.route)" v-for="(item,index) in rightarr" :key="index" :class="hash == ('#/' + item.route)?'active c-ff':'c-15e'"><span >{{item.title}}</span></li>
			</ul>
		</div>
		
		<div class=" model-box flex flex-y-s">
			<div class="model-line-bg"><img src="../static/left-line.png" alt="" /></div>
			
			<!-- 左侧导航 -->
			<div class="ml-15 meau-box relative mr-20">
				 <div class="meau-bg absolute top"><img src="../static/meal-bg.png" /></div>
				 <div class="meau-bg right absolute"><img src="../static/meal-bg.png" /></div>
				 <ul class="plr-20 relative" style="z-index: 100;">
					 <li class="mt-15" v-for="(item,index) in memusList" :key="index">
						<div class="meau-detail f-16 t-c" :class="(hashleft == item.route || item.children.findIndex(x => x.route == hashleft) != -1  || item.children.findIndex(x => hashleft.includes(x.route)) != -1)?'active':''" @click="navigation('/' + item.route)">{{item.title}}</div>
							<ul class=" c-48c  t-r twolevel" :class="((hashleft == item.route || item.children.findIndex(x => x.route == hashleft) != -1 || item.children.findIndex(x => hashleft.includes(x.route)) != -1) && item.children.length)?'is-show':''">
								<li v-for="(item1,indx1) in item.children"  :key="indx1" class="relative mt-15 two-level shou " :class="(hashleft== item1.route || hashleft.includes(item1.route))?'active':''" @click="navigation('/' + item1.route)">{{item1.title}}</li>
							</ul>
					 </li>
				 </ul>
			</div>
			<div class="flex-box mr-30">
				<!-- 页头用户信息 -->
				<div class="flex ptb-20">
					<ul class="flex">
						<li class="title-icon"><img src="../static/title-icon.png" /></li>
						<li class="f-20 c-ff bold ml-10">{{title_text}}</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'site/roleadd'">新增角色</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'site/rule/add'">新增菜单规则</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/list/common'">设备列表</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/list/curve'">历史曲线</li>
						<!-- <li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/list/detail1'">{{device_title}}</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/list/detail2'">{{device_title}}</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/list/detail'">{{device_title}}</li> -->
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/map/list'">地图模式</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/map/yt'">地图模式</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/map/sj'">地图模式</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/map/eq'">地图模式</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'equip/map/bj'">地图模式</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'refund/install/order'">安装工单</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'refund/maintain/order'">维修工单</li>
						<li class="f-20 c-ff bold ml-10" v-if="hashleft == 'site/admin/add' || hashleft == 'site/admin/set'">账号管理</li>
						
					</ul>
					<ul class="flex">
						<li class="flex">
							<div class="user-head radius-all flex-c"><img :src="avatar" alt="" /></div>
							<div class="ml-8">
								<p class="f-18 c-ff">{{nickname}}</p>
								<p class="f-14 c-cc">{{username}}，欢迎您</p>
							</div>
						</li>
						<li class="close-icon ml-30 shou" @click="loginout"><img src="../static/close-icon.png" /></li>
					</ul>
				</div>
				<!-- 主要表格内容 -->
				<router-view></router-view>
			</div>
			<div class="model-line-bg right"><img src="../static/left-line.png" alt="" /></div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
			hash: '#/home',
			hashleft: '',
			username: '',
			nickname: '',
			avatar: '',
			leftarr: [],
			rightarr: [],
			memusList: [],
			title_text: ''
		}
  },

  created () {
		let _this = this
		// _this.access_id = _this.$route.query.access_id
		_this.username = localStorage.getItem('username')
		_this.nickname = localStorage.getItem('nickname')
		_this.avatar = localStorage.getItem('avatar') || '../static/moren-head.jpg'
		

	},
	
	mounted () {
		this.meaulist()
		this.menuslist();
		
		let hashUrl = window.location.hash
		// console.log(hashUrl.indexOf('/home'))
		let index = hashUrl.indexOf('?')
		this.hashleft = index == -1?hashUrl.substr(2):(hashUrl.substr(0,index)).substr(2)
		if (hashUrl.indexOf('/home') != -1) {
			this.hash = '#/home'
		}
		if (hashUrl.indexOf('/equip') != -1) {
			this.hash = '#/equip'
		}
		if (hashUrl.indexOf('/pro') != -1) {
			this.hash = '#/pro'
		}
		if (hashUrl.indexOf('/user') != -1) {
			this.hash = '#/user'
		}
		if (hashUrl.indexOf('/refund') != -1) {
			this.hash = '#/refund'
		}
		if (hashUrl.indexOf('site') != -1) {
			this.hash = '#/site'
		}
	},
	
	watch: {
		$route (to,from) {
			this.hash = '#' + to.matched[0].path
			this.hashleft = to.path.substr(1)
			this.routetitle()
		}
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		// 导航列表
		async meaulist () {
			let _this = this
			const res = await _this.get('basic/menus')
			_this.leftarr = res.menus.filter((x,index) => index < 3)
			
			_this.rightarr = res.menus.filter((x,index) => index >= 3)
		},
		
		// 选择左侧导航
		async chooseLeftmeau (access_id, path) {
			console.log(path)
			let _this = this
			localStorage.setItem('access_id', access_id)
			_this.$router.push({path: '/' + path})
		},
		
		async menuslist () {
			let _this = this
			let access_id =  localStorage.getItem('access_id')
			const res = await _this.get('basic/menus', {access_id})
			console.log(res);
			_this.memusList = res.menus
			this.routetitle()
		},
		
		// 通过路由去找对应的名称
		routetitle () {
			let _this = this,
			 { hashleft, memusList } = _this;
			var title;
			memusList.forEach((item, index) => {
				if (item.route == hashleft) {
					// console.log(item)
					return title = item.title
				} else {
					item.children.forEach((x,i) => {
						if (x.route == hashleft) {
							return title = x.title
						}
					})
				}
			})
			
			_this.title_text = title
		},
		
		// 退出登录
		async loginout () {
			let _this = this
			const res = await _this.post('passport/logout')
			localStorage.removeItem('token')
			_this.$router.replace({path: '/login'})
		},
	}
}
</script>

<style  lang="less">
a {
	display: block;
}
.top-header {
	// min-width: 1920rem;
	height: 90rem;
	background: url(../static/top_bg.png) no-repeat center;
	background-size: 100%  100%;
	.top-text {
		width: 544rem;
		height: 35rem;
		// left: 50%;
		// top: 50%;
		// transform: translate(-50%,-50%);
		top: -25rem;
	}
	.top-btn {
		// width: 192rem;
		flex:1;
		height: 50rem;
		background: url(../static/topbtn-bg.png) no-repeat center;
		background-size: 100% 100%;
		line-height: 50rem;
		opacity: .7;
		cursor: pointer;
		span{
			font-size:15rem
		}
	}
	.top-btn.right {
		background: url(../static/topbtn-bg2.png) no-repeat center;
		background-size: 100% 100%;
	}
	.top-btn.active {
		background: url(../static/topbtn-gb-sel.png) no-repeat center;
		background-size: 100% 100%;
		opacity: 1;
	}
	.top-btn.active.right {
		background: url(../static/topbtn-gb-sel2.png) no-repeat center;
		background-size: 100% 100%;
	}
}

// 下面是左侧导航

.model-line-bg {
		width: 15rem;
		height: 900rem !important;
		padding-top: 30rem;
	}
	.model-line-bg.right img{
		transform: rotateY(180deg);
	}
	.meau-box {
		width: 227rem;
		height: 947rem !important;
		background: rgba(1, 169, 170, 0.1);
		border: 1px solid #48CBCC;
		.meal {
			width: 100%!important;
			background: none;
		}
		.meau-bg {
			width: 251rem;
			height: 59rem;
			left: -13rem;
		}
		.meau-bg.top {
			top: -12rem;
		}
		.meau-bg.right {
			width: 251rem;
			height: 59rem;
			bottom: -12rem;
			left: -13rem;
			transform: rotateX(180deg);
		}
		.meau-detail {
			height: 56rem;
			background: url(../static/meau-bg.png) no-repeat center;
			background-size: 100%  100%;
			color: #48CBCC;
			line-height: 56rem;
			cursor: pointer;
		}
		.meau-detail.active {
			background: url(../static/meau-bg-active.png) no-repeat center;
			background-size: 100%  100%;
			color: #FFFFFF;
		}
		.two-level {
			padding-left: 50%;
			text-align: left;
		}
		.two-level.active {
			color: #fff;
		}
		.two-level.active::before {
			content: '';
			width: 6rem;
			height: 6rem;
			background: #FFFFFF;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 70rem;
		}
	}
	
	
	.title-icon {
		width: 17rem;
		height: 17rem;
	}
	.site-head {
		width: 52rem;
		height: 52rem;
		background: url(../static/user-head-bg.png) no-repeat center;
		background-size: 100% 100%;
		img {
			width: 43rem;
			height: 43rem;
			border-radius: 50%;
		}
	}
	.close-icon {
		width: 23rem;
		height: 25rem;
	}
	
	@media (max-width:1024px){
		.meau-box{height: 625rem !important;}
		.model-line-bg{height: 630rem  !important}
		.ptb-20{padding-top: 5rem;padding-bottom: 5rem;}
	}
	
	// 主要列表样式
	
	.table-box {
		
	}
	.data-box {
		height: 790rem!important;
		background: url(../static/table-bg.png) no-repeat bottom right !important;
		background-color: rgba(1, 169, 170, 0.1)!important;
		background-size: 655px 327px!important;
	}
	.form-ipt {
		background: rgba(1, 169, 170, 0.1);
		border: 1px solid #48CBCC;
	
	}
		.ivu-input {
			border: none;
			color: #48CBCC;
			background-color: rgba(0,0,0,0)
		}
		.ivu-icon-ios-search,.ivu-input::placeholder {
			color: #48CBCC;
		}
		.ivu-select-selection {
			color: #48CBCC;
			border: 1px solid #48CBCC;
			height: 34px!important;
			border-radius: 0;
			background-color: rgba(0,0,0,0)
		}
		.ivu-input-prefix i, .ivu-input-suffix i {
			color: #48CBCC!important;
		}
		.ivu-select-placeholder {
			color: #48CBCC!important;
			line-height: 32px!important;
		}
		.ivu-input-group-append {
			button {
				background: rgba(1, 169, 170, 0.1);
			}
			background: rgba(1, 169, 170, 0.1);
			border: none;
			border-left: 1px solid #48CBCC;
		}
	.ivu-table th {
		height: 60px!important;
		background-color: rgba(0, 0,0, .1)!important;
		border-bottom: none;
		color: #fff;
		font-size: 14rem;
		font-weight: bold;
	}
	.ivu-table td {
		height: 60rem;
		background: rgba(1, 169, 170, 0.2);
		border-bottom: 5px solid #111727;
		color: #fff;
		font-size: 14rem;
	}
	.ivu-table {
		background-color: rgba(0,0,0,0);
	}
	.ivu-table:before {
		background-color: rgba(0, 0,0, .1)!important;
	}
	.table-type {
		background-color: #5EB95E;
		height: 24rem;
		line-height: 24rem;
		display: inline-block;
	}
	.handle-edit, .handle-del {
		width: 70rem;
		height: 32rem;
		.f-14 {
			margin-left: 3rem;
		}
	}
	.handle-edit {
		border: 1px solid #74B8F4;
		color: #74B8F4;
	}
	.handle-del {
		border: 1px solid #F16252;
		color: #F16252;
	}
	// 弹窗
	.model-pop {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 710rem;
		height: 478rem;
		background: url(../static/data-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
	
	.twolevel {
		height: 0;
		padding: 0 30px 0 0;
		transition: all .3s;
		overflow: hidden;
	}
	.twolevel.is-show {
		height: auto;
		padding: 10px 0 10px 0;
	}
	.title-box {
		width: 226rem;
		height: 43rem;
		line-height: 43rem;
		background: url(../static/title-bg.png) no-repeat center;
		background-size: 100%　100%;
	}
	
	.ivu-page-item,.ivu-page-next, .ivu-page-prev {
		// width: 30rem;
		// height: 30rem;
		border: 1px solid #01A9AA;
		background-color: rgba(0,0,0,0);
		line-height: 2.2;
		a {
			color: #15E6CD!important;
		}
		// opacity: 1;
		// border-radius: 2rem;
	}
	.ivu-page-next, .ivu-page-prev {
		padding: 0;
	}
	.ivu-page-item-active {
		background: rgba(1, 169, 170, 0.3);
	}
	.user-head {
		width: 52rem;
		height: 52rem;
		background: url(../static/user-head-bg.png) no-repeat center;
		background-size: 100% 100%;
		img {
			width: 43rem;
			height: 43rem;
			border-radius: 50%;
		}
	}
	
	// 级联选择器
	.ivu-cascader {
		height: 34rem;
		// padding: 4px 7rem;
		color: #48CBCC;
		background-color: rgba(0, 0, 0, 0);
		width: 200rem;
		border: 1px solid rgb(72, 203, 204);
		.ivu-input-large {
			padding: 0 7rem;
			height: 34rem;
		}
	}
	.ivu-icon {
		color: #48CBCC;
	}
	
	// 抽屉弹窗
	.ivu-drawer-content {
		background-color: #0F2634!important;
	}
	.ivu-drawer-header p, .ivu-drawer-header-inner {
		color: #fff;
	}
	.submit-btn {
		width: 140rem;
		line-height: 40rem;
		height: 40rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
	}
	.submit-btn.cancel {
		background: rgba(0,0,0,0);
		color: #01A9AA;
	}
	.ivu-drawer-header {
		border-bottom: 1px solid #48CBCC;
	}
</style>
