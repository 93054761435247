<template>
	<div class="refund-install">
		<div class="data-box pl-20 pt-25">
			<!-- <ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
					</ul> -->
			<ul class="flex flex-x-s mt-20">
				<li class="f-14 c-ff mr-10">创建时间：</li>
				<li class="mr-10">
					<DatePicker @on-change="changedate" type="daterange" :options="options2" placement="bottom-end" placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
				</li>
				<li class="mr-10">
					<Select v-model="value_gz" style="width:160px" placeholder="是否故障">
						<Option v-for="item in gzList" :value="item.value" :key="item.value">{{ item.label }}</Option>
					</Select>
				</li>
				<li class="mr-10">
					<Select v-model="value_zx" style="width:160px" placeholder="在线状态">
						<Option v-for="item in zxList" :value="item.value" :key="item.value">{{ item.label }}</Option>
					</Select>
				</li>
				<li class="mr-10">
					<Select v-model="category_id" style="width:160px" placeholder="全部类别">
						<Option v-for="item,index in catelist" :value="item.category_id" :key="index">{{ item.name }}</Option>

					</Select>
				</li>
				<li class="mr-10">
					<Input v-model="code" class="form-ipt" placeholder="请输入设备编码">
					<Button slot="append" icon="ios-search" @click="instalReList"></Button>
					</Input>
				</li>
			</ul>
			<!-- 状态以及统计 -->
			<ul class="flex  mt-25 plr-20 mb-10">
				<li class="flex shou">
					<div v-bind:class="{'active': index==0}" class="tab-state f-14 c-ff t-c " @click="changeTab(0)">待分配</div>
					<div v-bind:class="{'active': index==1}" class="tab-state f-14 c-ff t-c " @click="changeTab(1)">进行中</div>
					<div v-bind:class="{'active': index==2}" class="tab-state f-14 c-ff t-c " @click="changeTab(2)">已完成</div>
				</li>
				<li class="c-10b f-14 ">
					<span class="mr-10">共：897个安装工单 </span>
					<span class="mr-10">待接单：679个</span>
					<span class="mr-10"> 进行中：67个</span>
					<span> 已完成：80个</span>
				</li>
			</ul>
			<div class="table-box">
				<Table :columns="columns1" :data="data1" class="" disabled-hover>
					<template slot-scope="{row}" slot="link">
						<ul class="f-14 c-ff">
							<li>王小鱼 </li>
							<li>16655042222</li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="zp">
						<ul class="f-14 c-ff">
							<li>王小鱼 </li>
							<li>16655042222</li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="az">
						<ul class="f-14 c-ff">
							<li>王小鱼 </li>
							<li>16655042222</li>
						</ul>
					</template>
					<template slot-scope="{row}" slot="handle">
						<ul class="flex flex-x-s shou">
							<li class="handle-edit flex-c" @click="navigation('/refund/install/order')">
								<span class="iconfont iconedit1"></span>
								<span class="f-14">详情</span>
							</li>
							<li class="handle-del flex-c ml-8" @click="sureDel(row.id)">
								<span class="iconfont icondelect"></span>
								<span class="f-14">删除</span>
							</li>
						</ul>
					</template>
				</Table>
				<div class="flex flex-x-e mt-15">
					<p class="f-14 c-ff mr-20 mt-10">总记录：{{total}}</p>
					<Page :page-size="per_page" :total="total" @on-change="changepage1" />
				</div>
			</div>
		</div>
		<Modal
				:footer-hide="true"
				v-model="is_show_del"
				:closable="false"
				width="471"
				:mask-closable="false"
			>
				<span slot="title"></span>
				<div class="model-pop-jn eq-jn plr-20">
					<ul class="flex pt-20 flex-y-s flex-x-e">
						<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_del')"></li>
					</ul>
					<div class="f-24  t-c pt-50 c-48c">是否确定删除？</div>
					<li class="flex flex-c mt-50" >
						<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_del')">取消</div>
						<div class="queding f-18 c-ff t-c shou" @click="submitdel">确认</div>
					</li>
				</div>
		</Modal>

	</div>
</template>

<script>
	import topheader from '../../components/topheader.vue'
	export default {
		data() {
			return {
				total_az:'' ,//安装工单
				total_dj:'' ,//待接单
				total_ing:'' ,//进行中
				total_fin:'',//已完成
				is_show_del: false,
				gzList: [{
					value: 0,
					label: '是'
				}, {
					value: 1,
					label: '否'
				}],
				zxList: [{
					value: 0,
					label: '在线'
				}, {
					value: 1,
					label: '离线'
				}],
				index:0,
				value_zx: '',
				value_gz: '',
				catelist: [],
				category_id: 0,
				code: '',
				show: true,
				columns1: [

					{
						title: '订单号',
						key: 'orderno'
					},
					{
						title: '项目地址',
						key: 'address'
					},
					{
						title: '联系人姓名手机号',
						slot: 'link'
					},
					{
						title: '设备信息',
						key: 'info',
						ellipsis: true
					},
					{
						title: '指派师傅手机号',
						slot: 'zp'
					},
					{
						title: '安装师傅手机号',
						slot: 'az'
					},
					{
						title: '添加时间',
						key: 'timeadd'
					},
					{
						title: '安装时间',
						key: 'timeaz'
					},
					{
						title: '操作',
						slot: 'handle',
						width: 200
					}

				],
				data1: [],
				total: 0,
				per_page: 0,
				start_time: '',
				end_time: ''
			}
		},

		components: {
			topHeader: topheader
		},

		created() {

		},
		mounted() {
			this.projectcate()

		},
		methods: {
			//获取安装工单列表
			async instalReList(page = 1) {
				let that = this;
				let {
					start_time,
					end_time,
					value_zx,
					value_gz,
					category_id
				} = that;

				const res = await that.get('project/lists', {
					start_time:start_time,
					end_time:end_time,
					value_zx:value_zx,
					value_gz:value_gz,
					category_id:category_id,
					page
				})
				that.data1 = res.list.data
				that.total = res.list.total - 0
				that.per_page = res.list.per_page - 0
			},

			// 项目类型列表获取
			async projectcate() {
				let that = this
				const res = await that.get('project.category/lists', )
				that.catelist = res.list
			},
			//待分配-进行中-已完成
			changeTab(index){
			    let that = this;
				that.index = index;
				//列表筛选
				that.instalReList()
			},
			// 选择时间
			changedate(e) {
				
				this.start_time = e[0]
				this.end_time = e[1]
			},
			changepage1(page) {
				this.instalReList(page)
			},
			
			sureDel (id) {
				this.admin_id = id
				this.changeModal('is_show_del')
			},
			
			async submitdel () {
				let _this = this
				const res = await _this.post('admin/delete', { admin_id: _this.admin_id })
				_this.util.showMsg('success', '操作成功', 1.5 , false)
				this.changeModal('is_show_del')
				_this.rolelist()
			},
			
			changeModal (type) {
				this[type] = !this[type]
			},

			// 跳转路由
			navigation(path) {
				this.$router.push({
					path
				})
			}
		}
	}
</script>

<style lang="less">
	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(../../static/xunwen-pop.png) no-repeat center;
		background-size: 100% 100%;
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	}
	.ivu-modal-content {
		background-color: rgba(55,55,55,0);
		.ivu-modal {
			height: 0;
		}
	}
	.plr-20 {
		padding: 0px 20px;
	}

	td.name-color {
		color: #48CBCC !important;
	}

	.refund-install {
		.tab-state {
			width: 90rem;
			height: 32rem;
			line-height: 30rem;
			border: 1px solid #48CBCC;
			opacity: 0.6;
			transition: all .4s;
		}

		.active.tab-state {
			background-color: #48CBCC;
			opacity: 1;
		}
	}
</style>
