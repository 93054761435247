<template>
  <div class="refund-records">
				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20" @click="addRefund('is_show_add')"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
					</ul>
					<ul class="flex flex-x-s mt-20">
						<li class="mr-10 mb-10">
							<Input v-model="value13" class="form-ipt" placeholder="请输入手机号">
							</Input>
						</li>
						<li class="mr-10 mb-10">
							<Input v-model="value13" class="form-ipt" placeholder="请输入用户名称">
									<Button slot="append" icon="ios-search"></Button>
							</Input>
						</li>
					</ul>
					<!-- 状态以及统计 -->
					<div class="table-box">
						<Table :columns="columns1" :data="data1" class="" disabled-hover>
						
							<template slot-scope="{row}" slot="state">
									<div class="state-color flex-x-s flex">
										<div class="dight"></div>
										<span>在线</span>
									</div>
							</template>
<!-- 							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template> -->
						</Table>
					</div>
					<div class="flex flex-x-e mt-15 mr-15">
						<p class="f-14 c-ff mr-20 mt-10">总记录：{{total}}</p>
						<Page :page-size="per_page" :total="total" @on-change="changepage1" />
					</div>
				</div>
				<!-- 弹窗 -->
				<Modal :footer-hide="true" v-model="is_show_add" :closable="false" width="710" :mask-closable="false">
					<span slot="title"></span>
					<div class="model-pop-modal">
						<!-- title头部 -->
						<Form ref="xinhao">
							<ul class="flex pt-20 flex-y-s">
								<li class="title-text c-ff f-20">{{is_type}}员工管理</li>
								<li class="iconfont iconclose1 c-48c pr-20 shou" @click="addRefund('is_show_add')"></li>
							</ul>
							<ul class="plr-30 mt-50">
								<li class="mt-30 flex">
									<!-- <div class="flex-box flex">
										<span class="f-16 c-ff t-l" style="width: 90rem;">协议类型：</span>
										<Select v-model="type" class="flex-box" placeholder="请选择协议类型">
											<Option v-for="item,index in list_type" :value="index" :key="item">{{ item }}</Option>
										</Select>
									</div> -->
									<div class="flex-box  flex">
										<span class="f-16 c-ff t-l" style="width: 90rem;">名称：</span>
										<Input v-model="name" class="form-ipt flex-box" placeholder="请输入名称">
										</Input>
									</div>
									<div class="flex-box ml-50 flex">
										<span class="f-16 c-ff t-l" style="width: 90rem;">联系电话：</span>
										<Input v-model="phone" class="form-ipt flex-box" placeholder="请输入联系电话">
										</Input>
									</div>
								</li>
								<li class="mt-30 flex">
									<div class="flex-box flex">
										<span class="f-16 c-ff t-l" style="width: 90rem;">用户名：</span>
										<Input v-model="code" class="form-ipt flex-box" placeholder="请输入用户名">
										</Input>
									</div>
									<div class="flex-box ml-50 flex">
										<span class="f-16 c-ff t-l" style="width: 90rem;">排序：</span>
										<Input v-model="weigh" type="number" class="form-ipt flex-box" placeholder="请填写排序">
										</Input>
									</div>
								</li>
								<!-- <li class="flex mt-35 flex-y-s">
									<span class="f-16 c-ff t-l" style="width: 90rem;">说明：</span>
									<Input v-model="description" type="textarea" :rows="3" class="form-ipt flex-box" placeholder="请填写说明">
									</Input>
								</li> -->
								<li class="flex flex-x-s mt-50" style="padding-left: 90rem;">
									<div class="quxiao f-18 t-c c-10b shou mr-30" @click="addRefund('is_show_add')">取消</div>
									<div class="queding f-18 c-ff t-c shou" @click="bindsubmit">提交</div>
								</li>
							</ul>
						</Form>
				
					</div>
				</Modal>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
		    is_show_add:false,
			value13: '',
			show: true,
			total:0,
			per_page:0,
			columns1: [
					{
							title: 'ID',
							key: 'id'
					},
					{
							title: '名称',
							key: 'name'
					},
					{
							title: '联系电话',
							key: 'phone',
					},
					{
							title: '用户名',
							key: 'user',
					},
					{
							title: '状态',
							slot: 'state'
					},
					// {
					// 		title: '操作',
					// 		slot: 'handle',
					// 		width: 200
					// }
					
			],
			data1: [
					{
							id: 'abc123456',
							phone: 'abc123456',
							name: '安徽区世家Y6-103',
							user: '123...',
					}
			]
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},

  methods: {
		  //增加操作
		  addRefund() {
			this.changeModals('is_show_add')
		  },
		  bindsubmit(){
			
		  },
		  //展示增加弹窗
		  changeModals(type) {
			console.log(type)
			this[type] = !this[type]
		  },
	    //获取员工列表
		async instalReList(page = 1) {
			let that = this;
			let {
				phone,
				name,
			} = that;
		
			const res = await that.get('project/lists', {
				phone:phone,
				name:name,
				page
			})
			that.data1 = res.list.data
			that.total = res.list.total - 0
			that.per_page = res.list.per_page - 0
		},
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		}
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.refund-records {
	.dight {
		width: 6rem;
		height: 6rem;
		background: #8FF38E;
		border-radius: 3rem;
		margin-right: 10rem;
	}
	.tab-state {
		width: 90rem;
		height: 32rem;
		line-height: 30rem;
		border: 1px solid #48CBCC;
		opacity: 0.6;
		transition: all .4s;
	}
	.active.tab-state {
		background-color: #48CBCC;
		opacity: 1;
	}
}
	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(../../static/xunwen-pop.png) no-repeat center;
		background-size: 100% 100%;

		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}

		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	}
	.ivu-modal-content {
		background-color: rgba(55, 55, 55, 0);
	
		.ivu-modal {
			height: 0;
		}
	}
	// 弹窗
	.model-pop-modal {
		width: 710rem;
		height: 412rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, #030909 0%, #004D53 100%);
		.title-text {
			width: 226rem;
			height: 43rem;
			line-height: 43rem;
			padding-left: 40rem;
			
			background: url(../../static/title-bg.png) no-repeat center;
			background-size: 100% 100%;
		}
		textarea {
		   resize: none;  
		}
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	}
</style>
