<template>
  <div>
			<div class="map-box  flex mapyt">
				<!-- 左侧列表 -->
				<div class="map-list mr-15">
					<div class=" f-16 bold c-ff pro-name plr-25" >{{project_name}}</div>
					<div class="pl-30 mt-10">
						<ul>
							<li class="mt-25 f-16 c-ff">
								<div class="shou">在线监控 <span class="iconfont iconxiangxiajiantou f-12 bold"></span></div>
								<ul>
									<li class="pl-30 mt-20 shou  active c-48c relative">实时云图</li>
									<li class="pl-30 mt-20 shou" @click="navigation('/equip/map/sj?id='+project_id)">实时数据</li>
									<!-- <li class="pl-30 mt-20">实时曲线</li> -->
								</ul>
							</li>
							<li class="mt-25 f-16 shou c-ff" @click="navigation('/equip/map/bj?id='+project_id)">故障报警 </li>
							<li class="mt-25 f-16 shou c-ff" @click="navigation('/equip/map/eq?id='+project_id)">设备管理 </li>
						</ul>
					</div>
				</div>
				<!-- 右侧项目地图 -->
				<div class="flex-box map flex flex-dir-col flex-y-s">
					<div class="plr-20 ptb-15 f-16 bold c-ff w100">项目分布图</div>
					<div class="flex-box w100">
						<baidu-map class="map-detail" v-if="data1.length" :center="{lng: data1[0].longitude, lat: data1[0].latitude}" :zoom="15" mapType="BMAP_SATELLITE_MAP">
							<!-- 自定义覆盖物 -->
							<my-overlay
								v-for="item,index in data1"  :key="index" :position="{lng: item.longitude, lat: item.latitude}"
							 >
							</my-overlay>
						</baidu-map>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import myOverlay from './myoverly.vue'
export default {
  data () {
    return {
			value13:'',
			data1: [],
			project_id: 0,
			project_name: ''
		}
  },
	
	components: {
		myOverlay
	},

  created () {
		this.project_id = localStorage.getItem('project_id')
		this.project_name = localStorage.getItem('project_name')
	},

	mounted () {
		this.eqlistData()
	},
	
  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		// 获取设备列表
		async eqlistData (page = 1) {
			let _this = this,
					{  project_id } = _this;
			const res = await _this.get('device/lists_nopage', { project_id })
			_this.data1 = res.list
		},

	}
}
</script>

<style lang="less" >
.map-box >div{
	height: 790rem;
	
}

.map-list {
	width: 280rem;
	background: url(../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;

}

// 标签页
.tabs-title {
	li {
		width: 120rem;
		height: 52rem;
		line-height: 52rem;
		border: 1px solid #48CBCC;
		color: #fff;
		opacity: 0.6;
	}
	li.active {
		background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
		opacity: 1;
	}
}
.map-pro {
	ul {
		color: #fff;
		opacity: .6;
		padding-left: 30rem;
	}
	li {
		margin-top: 10rem;
		cursor: pointer;
	}
	.ivu-collapse-simple,.ivu-collapse,.ivu-collapse>.ivu-collapse-item,.ivu-collapse-content {
		background-color: rgba(0,0,0,0);
		border: none;
	}
	.ivu-collapse-header {
		font-size: 16rem;
		color: #fff;
		opacity: .6;
	}
	.ivu-collapse-item-active .ivu-collapse-header{
		font-size: 16rem;
		color: #fff;
		opacity: 1;
	}
	
}
.map-detail {
	height: 100%;
}

.sample {
  width: 43rem;
	height: 63rem;
}
.sample.active {
  // background: rgba(0,0,0,0.75);
  // color: #fff;
}

.mapyt {
	.pro-name {
		width: 100%;
		height: 52rem;
		line-height: 52rem;
		background: rgba(1, 169, 170, 0.1);
	}
	.active::before {
		content: '';
		position: absolute;
		width: 6rem;
		height: 6rem;
		border-radius: 50%;
		background-color: #48CBCC;
		top: 50%;
		transform: translateY(-50%);
		left: 10rem;
	}
	.flex-box.map .bold,.flex-box.map {
			background: rgba(1, 169, 170, 0.1)!important;
		}
}
</style>
