<template>
  <div class="refund-records">
				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20" @click="daochu"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></li>
					</ul>
					<ul class="flex flex-x-s mt-20">
						<li class="f-14 c-ff mr-10">创建时间：</li>
						<li class="mr-10 mb-10">
							<DatePicker type="daterange" :options="options2" placement="bottom-end" placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
						</li>
						<li class="mr-10 mb-10">
							<Input v-model="phone" class="form-ipt" placeholder="请输入手机号">
							</Input>
						</li>
						<li class="mr-10 mb-10">
							<Input v-model="code" class="form-ipt" placeholder="请输入设备编码">
									<Button slot="append" icon="ios-search"></Button>
							</Input>
						</li>
					</ul>
					<!-- 状态以及统计 -->
					<div class="table-box">
						<Table :columns="columns1" :data="data1" class="" disabled-hover>
						</Table>
					</div>
					<div class="flex flex-x-e mt-15">
						<p class="f-14 c-ff mr-20 mt-10">总记录：{{total}}</p>
						<Page :page-size="per_page" :total="total" @on-change="changepage1" />
					</div>
				</div>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			phone:'',
			code:'',
			total: 0,
			per_page: 0,
			start_time: '',
			end_time: '',
			columns1: [
					{
							title: '序号',
							key: 'xh'
					},
					{
							title: '编号',
							key: 'num'
					},
					{
							title: '设备名称',
							key: 'name',
							className: 'name-color'
					},
					{
							title: '客户名称',
							key: 'user',
					},
					{
							title: '手机号',
							key: 'phone'
					},{
							title: '设备地址',
							key: 'sb'
					},{
							title: '维修次数',
							key: 'wx'
					},
					// {
					// 		title: '操作',
					// 		slot: 'handle',
					// 		width: 200
					// }
					
			],
			data1: []
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},

  methods: {
	  //获取列表
	  async badReList(page = 1) {
	  	let that = this;
	  	let {
	  		phone,
	  		code,
			start_time,
			end_time
	  	} = that;
	  
	  	const res = await that.get('project/lists', {
	  		phone:phone,
	  		code:code,
			start_time:start_time,
			end_time:end_time,
	  		page
	  	})
	  	that.data1 = res.list.data
	  	that.total = res.list.total - 0
	  	that.per_page = res.list.per_page - 0
	  },
	  // 选择时间
	  changedate(e) {
	  	
	  	this.start_time = e[0]
	  	this.end_time = e[1]
	  },
	  
	  // 导出数据
	  daochu () {
	  	this.$refs.table.exportCsv({
	  			filename: '不良项目',
	  			data: this.data1.map(item=>{
	  				item.create_time = '="'+item.create_time+'"',
	  				item.mobile = "\t"+item.mobile
	  				return item
	  			})
	  	})
	  },
	  
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		}
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.refund-records {
	.dight {
		width: 6rem;
		height: 6rem;
		background: #8FF38E;
		border-radius: 3rem;
		margin-right: 10rem;
	}
	.tab-state {
		width: 90rem;
		height: 32rem;
		line-height: 30rem;
		border: 1px solid #48CBCC;
		opacity: 0.6;
		transition: all .4s;
	}
	.active.tab-state {
		background-color: #48CBCC;
		opacity: 1;
	}
}
</style>
