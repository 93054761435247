<template>
  <div class="tending"> 
		<ul class="flex" style="flex-wrap: nowrap;">
			<li class="total-data relative  p-20">
				<ul class="flex flex-x-s">
					<li class="f-14 c-ff mr-10">创建时间：</li>
					<li class="mr-10">
						<DatePicker type="daterange" :options="options2" placement="bottom-end" placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
					</li>
					<li class="mr-10">
						<Input v-model="value13" class="form-ipt" placeholder="请输入员工手机号">
								<Button slot="append" icon="ios-search"></Button>
						</Input>
					</li>
				</ul>
				<ul class="flex" style="margin-top: 70rem;"  >
					<li class="flex">
						<div class="money-icon flex-c"> <span class="iconfont iconyue"></span></div>
						<div class="ml-15">
							<p class="f-16 c-55f">维修总金额（元）</p>
							<p class="f-40 c-ff bold mt-5">398.56</p>
						</div>
					</li>
					<li class="flex flex-y-s">
						<div class="num-icon flex-c"> <span class="iconfont icondingdan1"></span></div>
						<div class="mlr-15 pt-8">
							<p class="f-16 c-55f">工单总量（单）</p>
							<p class="f-40 c-ff bold mt-5">89</p>
						</div>
						<ul class="f-16 pt-8">
							<li><span class="c-408 mr-5">待分配：</span> <span class="c-55f">19</span></li>
							<li><span class="c-408 mr-5">进行中：</span> <span class="c-55f">19</span></li>
							<li><span class="c-408 mr-5">已完成：</span> <span class="c-55f">19</span></li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="total-state relative p-20 ml-10">
				<p class="c-55f f-16">工单状态</p>
				<div id="state1" class="state-box plr-20 mt-10"></div>
			</li>
			<li class=" total-qs ml-10 relative p-20 ">
				<p class="c-55f f-16">过往1月维修单增长趋势</p>
				<div id="state2" class="state-box plr-20 mt-10"></div>
			</li>
		</ul>
		<ul class="flex mt-10 " style="flex-wrap: nowrap;">
			<li class="total-data-bottom relative  p-15">
				<p class="c-55f f-18 bold">维修金额 TOP7</p>
				<Table :columns="columns1" :data="data1" class="mt-10" disabled-hover max-height="434">
				</Table>
			</li>
			<li class="total-state-bottom relative  p-20 ml-10">
				<p class="c-55f f-18 bold">维修金额 TOP7</p>
				<Table :columns="columns1" :data="data1" class="mt-10" disabled-hover max-height="434">
				</Table>
			</li>
		</ul>
	</div>
</template>

<script>

export default {
  data () {
    return {
			columns1: [
					{
							title: '序号',
							key: 'no'
					},
					{
							title: '编号',
							key: 'bh'
					},
					{
							title: '维修金额',
							key: 'money',
					},
					{
							title: '维修员',
							key: 'wxy'
					},
					{
							title: '创建时间',
							key: 'time',
							width: 200
					},
			],
			data1: [
					{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					},{
							no: 1,
							bh: 21,
							money: '123',
							wxy: 1,
							time: '2016-10-03 11:11:11',
							// cz: '2016-10-03'
					}
			]
		}
  },

  created () {},
	
	mounted () {
		this.areaData()
		this.areaData2()
	},

  methods: {
		areaData () {
			var myChart = this.$echarts.init(document.getElementById("state1"));
			var option = {
			    legend: { 
						textStyle: {
							color: '#55FFEF'
						},
						icon: 'pin'
			    },
			    series: [
			        {
			            name: '面积模式',
			            type: 'pie',
			            radius: ['5%', '45%'],
			            center: ['50%', '50%'],
			            roseType: 'area',
			            itemStyle: {
			                borderRadius: 4
			            },
						label: {
							color: '#55FFEF'
						},
			            data: [
			                {value: 12, name: 'rose 1'},
			                {value: 28, name: 'rose 2'},
			                {value: 42, name: 'rose 3'},
			                {value: 30, name: 'rose 4'},
			            ],
									
			        }
			    ]
			};
			myChart.setOption(option, true);
		},
	
		areaData2 () {
			let _this = this
			var myChart = this.$echarts.init(document.getElementById("state2"), 'shine');
			var option = {
						color: [ '#55FFEF'],
						title: {
								text: ''
						},
						tooltip: {
								trigger: 'axis',
								axisPointer: {
										type: 'cross',
										label: {
												backgroundColor: '#6a7985'
										}
								}
						},
						legend: {
								show: false
						},
						grid: {
							
						},
						xAxis: {
								data: ['2019/06','2019/08','2019/10','2019/10','2019/10','2019/10'],
								axisLine: {
									show: true,
									lineStyle: {
											type: 'solid',
											color:'#55FFEF',
											opacity: .6
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12
								},
								boundaryGap: false
						},
		
						yAxis: {
								splitLine: {
									show: false,
								},
								axisLine: {
									show: true,
									lineStyle: {
											type: 'solid',
											color:'#55FFEF',
											opacity: .6
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
								}
						},
						series: [
								{
										type: 'line',
										smooth: true,
										lineStyle: {
												width: 1
										},
										showSymbol: false,
										areaStyle: {
												opacity: 1,
												color: {
												    type: 'linear',
												    x: 0,
												    y: 0,
												    x2: 0,
												    y2: 1,
												    colorStops: [{
												        offset: 0, color: '#55FFEF' // 0% 处的颜色
												    }, {
												        offset: 1, color: 'rgba(255,255,255,0)' // 100% 处的颜色
												    }],
												    global: false // 缺省为 false
												}
										},
										emphasis: {
												focus: 'series'
										},
										data: [0.3, 2.2, 1.3, 0.4, 0.1, 2.1,1.1]
								}
						]
		  };
			myChart.setOption(option, true);
		}
	}
}
</script>

<style lang="less" >
.tending {
	.total-data {
		width: 740rem;
		height: 277rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-data-bottom {
		height: 500rem;
		width: 740rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-data::before {
		content: '';
		position: absolute;
		left: -1rem;
		top: -1rem;
		width: 23rem;
		height: 14rem;
		background: url(../../static/data-boxicon.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
	}
	.total-data-bottom::before {
		content: '';
		position: absolute;
		right: -1rem;
		top: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateX(180deg);
	}
	.total-data-bottom::after {
		content: '';
		position: absolute;
		left: -1rem;
		bottom: -1rem;
		width: 23rem;
		height: 14rem;
		background: url(../../static/data-boxicon.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateX(180deg);
	}
	.total-data::after {
		content: '';
		position: absolute;
		right: -1rem;
		bottom: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
	}
	.money-icon, .num-icon {
		width: 98rem;
		height: 98rem;
		background: radial-gradient(circle, rgba(15, 37, 51, 0) 0%, rgba(25, 68, 92, 0.34) 45%, rgba(85, 255, 239, 0.8) 100%);
		// background: radial-gradient(circle, rgba(15, 37, 51, 0) 0%, rgba(25, 68, 92, 0.34) 60%, #4387FA 100%);
		border-radius: 50%;
	}
	.num-icon {
		background: radial-gradient(circle, rgba(15, 37, 51, 0) 0%, rgba(25, 68, 92, 0.34) 45%, #4387FA 100%);
	}
	.iconyue, .icondingdan1 {
		color: #55FFEF;
		font-size: 36rem;
	}
	.icondingdan1 {
		color: #4084F1;
	}
	.c-55f {
		color: #55FFEF;
	}
	.c-408 {
		color: #4084F1;
	}
	
	// 工单状态
	.total-state {
		width: 307rem;
		height: 277rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-state-bottom {
		// flex: 1;
		height: 500rem;
		flex: 1;
		// width: 847rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-state-bottom::before {
		content: '';
		position: absolute;
		right: -1rem;
		bottom: -1rem;
		width: 23rem;
		height: 14rem;
		background: url(../../static/data-boxicon.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(180deg);
	}
	.total-state-bottom::after {
		content: '';
		position: absolute;
		left: -1rem;
		top: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(180deg);
	}
	.state-box {
		height: 227rem;
	}
	.total-state::before {
		content: '';
		position: absolute;
		left: -1rem;
		bottom: -1rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateY(180deg);
	}
	.total-state::after {
		content: '';
		position: absolute;
		right: -1rem;
		top: -2rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(-90deg);
	}
	.total-qs {
		// width: 660rem;
		flex: 1;
		height: 277rem;
		background: #0F2634;
		border: 1px solid #096069;
	}
	.total-qs::after {
		content: '';
		position: absolute;
		right: -1rem;
		top: -2rem;
		width: 11rem;
		height: 13rem;
		background: url(../../static/data-boxicon1.png) no-repeat center;
		background-size: 100% 100%;
		background-color: #0F1726;
		transform: rotateZ(-90deg);
	}
	
	.ivu-table td,.ivu-table th {
		height: 48px!important;
	}
	.ivu-table td {
		border-bottom: 3px solid #111727;
	}
}
</style>
