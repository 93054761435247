<template>
  <div>

				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<!-- <li class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li> -->
					</ul>
					<div class="table-box">
						<Table :columns="columns1" :data="data1" class="" disabled-hover>
					<!-- 		<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template> -->
							<template slot-scope="{row}" slot="name">
								<div  class="f-14 c-ff t-c table-type plr-10"  v-html="row.name" ></div>
							</template>
						</Table>
					</div>
				</div>
	
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			value13: '',
			show: true,
			columns1: [
					{
							title: '分类ID',
							key: 'category_id'
					},
					{
							title: '分类名称',
							slot: 'name',
							className:"name-class"
					},
					{
							title: '分类排序',
							key: 'sort'
					},
					{
							title: '添加时间',
							key: 'create_time',
					},
					// {
					// 		title: '操作',
					// 		slot: 'handle',
					// 		width: 360
					// }
			],
			data1: []
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.projectcate()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		// 项目类型列表获取
		async projectcate () {
			let _this = this
			const res = await _this.get('project.category/lists',)
			_this.data1 = res.list
		},
	}
}
</script>

<style lang="less" >
.model-line-bg {
	width: 15rem;
	height: 900rem;
	padding-top: 30rem;
}
.model-line-bg.right img{
	transform: rotateY(180deg);
}
.meau-box {
	width: 227rem;
	height: 880rem;
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.meal {
		width: 100%!important;
		background: none;
	}
	.meau-bg {
		width: 251rem;
		height: 59rem;
		left: -13rem;
	}
	.meau-bg.top {
		top: -12rem;
	}
	.meau-bg.right {
		width: 251rem;
		height: 59rem;
		bottom: -12rem;
		left: -13rem;
		transform: rotateX(180deg);
	}
	.meau-detail {
		height: 56rem;
		background: url(../../static/meau-bg.png) no-repeat center;
		background-size: 100%  100%;
		color: #48CBCC;
		line-height: 56rem;
		cursor: pointer;
	}
	.meau-detail.active {
		background: url(../../static/meau-bg-active.png) no-repeat center;
		background-size: 100%  100%;
		color: #FFFFFF;
	}
}

.title-icon {
	width: 17rem;
	height: 17rem;
}
.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.close-icon {
	width: 23rem;
	height: 25rem;
}

// 主要列表样式
.table-box {
	
}
.data-box {
	height: 790rem;
	background: url(../../static/table-bg.png) no-repeat bottom right ;
	// background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;
}
.form-ipt {
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.ivu-input {
		border: none;
		color: #48CBCC;
		background-color: rgba(0,0,0,0)
	}
	.ivu-icon-ios-search,.ivu-input::placeholder {
		color: #48CBCC;
	}
	.ivu-input-group-append {
		button {
			background: rgba(1, 169, 170, 0.1);
		}
		background: rgba(1, 169, 170, 0.1);
		border: none;
		border-left: 1px solid #48CBCC;
	}
}
.ivu-table th {
	height: 60px!important;
	background-color: rgba(0, 0,0, .1)!important;
	border-bottom: none;
	color: #fff;
	font-size: 14rem;
	font-weight: bold;
}
.ivu-table td {
	height: 72rem;
	background: rgba(1, 169, 170, 0.2);
	border-bottom: 5px solid #111727;
	color: #fff;
	font-size: 14rem;
}
.ivu-table {
	background-color: rgba(0,0,0,0);
}
.ivu-table:before {
	background-color: rgba(0, 0,0, .1)!important;
}
.table-type {
	background-color: #5EB95E;
	height: 24rem;
	line-height: 24rem;
	display: inline-block;
}
.handle-edit, .handle-del {
	width: 70rem;
	height: 32rem;
	.f-14 {
		margin-left: 3rem;
	}
}
.handle-edit {
	border: 1px solid #74B8F4;
	color: #74B8F4;
}
.handle-del {
	border: 1px solid #F16252;
	color: #F16252;
}
// 弹窗
.model-pop {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 710rem;
	height: 478rem;
	background: url(../../static/data-bg.png) no-repeat center;
	background-size: 100% 100%;
}
</style>
