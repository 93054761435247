<template>
	
	<div class="data-box refund-detail pl-20 pt-25">
		<!-- 安装工单---详情 -->
		<!-- 导出按钮 -->
		<ul class="flex">
			<!-- <li class="table-btn plr-25">
				<span class="iconfont iconshangjia3 f-14 c-ff"></span>
				<span class="f-14 c-ff">导出</span>
			</li> -->
		</ul>
		<!-- 简介消息 -->
		<div class="order-title plr-30 ptb-25 mt-20 mb-25">
			<ul class="flex flex-x-s">
				<li class="f-20 c-ff bold">维吾问无为谓修工单：#2363</li>
				<li class="mlr-10 order-state f-14 c-ff plr-10">已完成</li>
				<li class="order-comment f-14 c-ff plr-10">好评</li>
			</ul>
			<ul class="mt-15 f-14 c-ff flex flex-x-s">
				<li class="mr-50">
					<span class="title-text mr-10">安装师傅:</span>
					<span>杨晓波</span>
				</li>
				<li class="mr-50">
					<span class="title-text mr-10">创建时间:</span>
					<span>2020-12-09 9:03:26</span>
				</li>
				<li class="mr-50">
					<span class="title-text mr-10">预约时间:</span>
					<span>2020-12-09 9:03:26</span>
				</li>
				<li class="mr-50">
					<span class="title-text mr-10">完成时间:</span>
					<span>2020-12-09 9:03:26</span>
				</li>
			</ul>
		</div>
	
		<div class="flex main-box flex-y-s">
			<!-- 订单信息确定 -->
			<div class="flex-box mr-30 flex-box">
				<Tabs type="card">
						<TabPane label="订单信息">
							<ul class="flex">
								<li class="f-14 c-ff">订单信息</li>
								<!-- <li class="edit-text f-14"><span class="iconfont iconedit mr-5"></span><span>编辑</span></li> -->
							</ul>
							<ul>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">订单号</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">项目/地址</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">联系人姓名</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">安装时间</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">创建时间</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">创建人</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">关联销售</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">服务管家</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">备注</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
							</ul>
						</TabPane>
						<TabPane label="设备信息">
							<ul class="flex">
								<li class="f-14 c-ff">设备信息</li>
								<!-- <li class="edit-text f-14"><span class="iconfont iconedit mr-5"></span><span>编辑</span></li> -->
							</ul>
							<ul>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">设备名称</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">机器型号</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">批次</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">供应商</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">配件信息</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">备注</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">项目详情</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								
							</ul>
						</TabPane>
						<TabPane label="安装信息">
							<ul class="flex">
								<li class="f-14 c-ff">安装信息</li>
								<!-- <li class="edit-text f-14"><span class="iconfont iconedit mr-5"></span><span>编辑</span></li> -->
							</ul>
							<ul>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">安装师傅</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">安装日期</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">施工图纸</div>
									<div class="flex-box pl-10 flex flex-x-s b-b pb-5">
										<span class="mr-30 f-14 c-ff">123123123</span>
										<div class="table-btn plr-15">
											<span class="iconfont iconshangjia3 f-12 mr-5 c-ff"></span>
											<span class="f-142 c-ff">下载</span>
										</div>
									</div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">客户验收单</div>
									<div class="flex-box pl-10 flex flex-x-s b-b pb-5">
										<span class="mr-30 f-14 c-ff">123123123</span>
										<div class="table-btn plr-15">
											<span class="iconfont iconshangjia3 f-12 c-ff mr-5"></span>
											<span class="f-142 c-ff">下载</span>
										</div>
									</div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">配件消耗</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">辅材消耗</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">备注</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								
							</ul>
						</TabPane>
						<TabPane label="评价信息">
							<ul class="flex">
								<li class="f-14 c-ff">评价信息</li>
								<!-- <li class="edit-text f-14"><span class="iconfont iconedit mr-5"></span><span>编辑</span></li> -->
							</ul>
							<ul>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">客户</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">评价</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
								<li class="flex flex-x-s mt-15">
									<div class="refund-add-title mr-20 t-r   f-14 ">评语</div>
									<div class="flex-box pl-10 b-b"><Input v-model="value" placeholder="请填写联系方式" style="color: #fff;"   /></div>
								</li>
							</ul>
						</TabPane>
				</Tabs>
			</div>
			<!-- 跟进记录时间错 -->
			<div class="record-box p-25">
				<div class="record-add f-14 c-ff t-c">写跟进记录</div>
				<div class="flex mtb-30">
					<span class="iconfont icontime1 f-14 c-ff"></span>
					<span class="f-14 c-ff ml-10 mr-8">跟进记录</span>
					<div class="flex-box dashed"></div>
				</div>
				<Timeline>
						<TimelineItem color="green" v-for="item in 7">
								<div slot="dot" class="timeline-dot flex flex-c">
									<div class="dot"></div>
								</div>
								<div class="pl-10">
									<ul class="flex f-12 c-ff">
										<li>2020-12-18:09:07:33</li>
										<li>跟进人：杨德彪</li>
									</ul>
									<p class="f-12 c-ff mt-5 mb-8">到访</p>
									<p class="f-12 c-ff" style="opacity: .6;">合同已签，结算材料已办，月底到货，25号欠安排</p>
								</div>
						</TimelineItem>
						
				</Timeline>
			</div>
		</div>
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			value13: '',
			value: '1123',
			show: true,
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},

  methods: {
	     // 获取详情
		 async getDetail(){
			let that = this;
			
			
		 },
		  
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		}
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.refund-detail {
	.order-title {
		// width: 1534rem;
		// height: 121rem;
		background: rgba(1, 169, 170, 0.1);
	}
	.order-state {
		// width: 60rem;
		height: 24rem;
		line-height: 24rem;
		background: #5EB95E;
	}
	.order-comment {
		background-color: #EAA75D;
		height: 24rem;
		line-height: 24rem;
	}
	.title-text {
		opacity: .7;
	}
	.main-box {
		height: 550rem;
	}
	.record-box {
		width: 575rem;
		height: 550rem;
		background: rgba(1, 169, 170, 0.1);
		.record-add {
			width: 103rem;
			height: 32rem;
			line-height: 30rem;
			border: 1px solid #48CBCC;
			opacity: .6;
		}
	}
	.ivu-timeline-item-head {
		background-color: rgba(0,0,0,0);
	}
	.ivu-timeline-item-head-custom {
		left: 0;
	}
	.timeline-dot {
		width: 16rem;
		height: 16rem;
		border-radius: 50%;
		border: 1px solid #01A9AA;
		.dot {
			width: 9rem;
			height: 9rem;
			background: linear-gradient(180deg, #10BDC4 0%, #8FF38E 100%);
			border-radius: 50%;
		}
	}
	.dashed {
		border-top: 1px dashed #FFFFFF;
		opacity: 0.6;
	}
	.ivu-timeline {
		height: 355rem;
		overflow-y: auto;
	}
	.edit-text {
		color: #74B8F4;
	}
	.refund-add-title {
		width: 75rem;
		color: rgba(255, 255, 255, 0.6);
	}
	.b-b {
		border-bottom: 1px solid rgba(1, 169, 170, 0.5);
	}
	.ivu-input {
		color: #fff;
	}
	
	// tab标签页
	.ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab {
		width: 90rem;
		height: 32rem;
		line-height: 30rem;
		text-align: center;
		border: 1px solid #48CBCC;
		opacity: 0.6;
		background: rgba(0,0,0,0);
		border-radius: 0;
		margin-right: 0;
		padding: 0;
		transition: all .3s ease-in-out;
		color: #fff;
	}
	.ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab-active {
		    background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
		    transform: translateZ(0);
				opacity: 1;
	}
	.ivu-tabs-bar {
	    border-bottom:none;
	    margin-bottom: 16rem;
	}
}
</style>
