<template>
	<div class=" refund-add">
		<ul class="data-box  pl-20 pt-25 refund-add">
			<li class="flex flex-x-s">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">预约时间</div>
				<div>
					<DatePicker type="date" @on-change="changedate" show-week-numbers placeholder="请选择预约时间" style="width: 500px" class="b-b"></DatePicker>
				</div>
			</li>
			<li class="flex flex-x-s mt-15">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">联系人</div>
				<div><Input v-model="options_add.link_name" placeholder="请填写联系人" style="width: 500px" class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-15">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">联系方式</div>
				<div><Input v-model="options_add.phone" placeholder="请填写联系方式" style="width: 500px" class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-15">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">故障产品</div>
				<div>
					<Select v-model="options_add.proId" style="width:500px" class="gz">
						<Option v-for="item in badProList" :value="item.id" :key="item.id">{{ item.label }}</Option>
					</Select>
				</div>
			</li>
			<li class="flex flex-x-s flex-y-s  mt-15">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">故障类型</div>
				<div>
					<Select v-model="options_add.cateId" style="width:500px" class="gz">
						<Option v-for="item in badCateGory" :value="item.id" :key="item.id">{{ item.label }}</Option>
					</Select>
					<div class="mt-15"><Input v-model="options_add.phones" placeholder="请填写联系方式" style="width: 500px" class="b-b" /></div>
				</div>
			</li>
			<li class="flex flex-x-s flex-y-s  mt-25">
				
					<Upload action="https://www.kochem.net.cn/wap/upload/image" name="file" :headers="{token}" :on-success="uploadSuccess"
					 :show-upload-list="false" accept="image/png,image/tiff,image/jpeg" :format="['jpg','jpeg','png','tiff']">
						<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">
							<div class="table-btn flex-c" style="width: 132rem;">
								<span class="iconfont iconshangjia3 f-14 c-ff"></span>
								<span class="f-14 c-ff ml-8">图片上传</span>
							</div>
						</div>
						<div>
							<div class="upload-img flex-c shou"> 
							    <span class="iconfont iconadd1" v-if="!unloadImg" ></span>
								<img :src="unloadImg" v-if="unloadImg"></img>
							</div>
						</div>
					</Upload>
			</li>
			<li class="flex flex-x-s flex-y-s  mt-50">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff"></div>
				<div class="submit-btn f-18 c-ff t-c" @click="form_submit">提交</div>
			</li>
		</ul>
	</div>
</template>

<script>
	import topheader from '../../components/topheader.vue'
	export default {
		data() {
			return {
				unloadImg:'',
				options_add: {
					time: '',
					link_name: '',
					phone: '',
					proId: '',
					cateId: '',
					phones: '',
					file_id:''
				},
				badProList:[{id:1,label:'故障产品1'}],
				badCateGory:[{id:1,label:'故障产品类型1'}],
				token: localStorage.getItem('token'),
				value13: '',
				unloadImg:'',
				show: true,
				columns1: [{
						title: 'ID',
						key: 'id'
					},
					{
						title: '编号',
						key: 'no'
					},
					{
						title: '名称',
						key: 'name'
					},
					{
						title: '类型',
						slot: 'type',
						align: 'center'
					},
					{
						title: '排序',
						key: 'desc'
					},
					{
						title: '添加时间',
						key: 'time',
					},
					{
						title: '状态',
						slot: 'state',
						align: 'center'
					},
					{
						title: '操作',
						align: 'center',
						slot: 'handle',
						width: 360
					}
				],
				data1: [{
					id: 1,
					no: 21,
					name: '123',
					desc: 1,
					time: '2016-10-03',
					// cz: '2016-10-03'
				}]
			}
		},

		components: {
			topHeader: topheader
		},

		created() {

		},

		methods: {
			//获取故障产品
			async badProjectList () {
				let _this = this;
				const res = await _this.get('project.category/lists',)
				_this.badProList = res.list
			},
			
			//获取故障类型
			async badCategory () {
				let _this = this;
				const res = await _this.get('project.category/lists',)
				_this.badCateGory = res.list
			},
			
			
			//选择时间
			changedate(e){
				this.options_add.time = e;
			},
			
			
			
			// 上传图片--接口
			uploadSuccess(response, file, fileList) {
				this.unloadImg = response.data.file_path;
				this.options_add.file_id = response.data.file_id
			},
			
			//表单提交
			async form_submit(){
				let that = this;
				let arr = Object.values(that.options_add);
				let is = arr.some(x=>!x);
				if(is){
					return that.util.showMsg('warning', '请完善表单内容', 2, false)
				}
			  const res = await _this.post(url,that.options_add);
			  that.util.showMsg('success', '操作成功', 2, false)
			},
			// 跳转路由
			navigation(path) {
				this.$router.push({
					path
				})
			}
		}
	}
</script>

<style lang="less">
	.refund-add .ivu-upload-select{
		display: flex;
	}
	.refund-add .refund-add-title{
	   display: flex;
		   justify-content: flex-end;
	}
	.refund-add {
		.refund-add-title {
			width: 440rem;
		}

		.refund-add-title.must {}

		.refund-add-title.must::after {
			content: '*';
			color: #F16252;
			margin-left: 8rem;
			font-size: 16rem;
		}

		.b-b {
			border-bottom: 1px solid #01A9AA;
		}

		.ivu-date-picker-focused input,
		.ivu-input:hover {
			box-shadow: none;
		}

		.gz .ivu-select-selection {
			border: none;
			border-bottom: 1px solid #48CBCC;
			display: block;
		}

		.upload-img {
			width: 150rem;
			height: 150rem;
			border: 1px solid #48CBCC;
			border-radius: 4rem;
		}

		.iconadd1 {
			color: #48CBCC;
			font-size: 40rem;
		}

		.submit-btn {
			width: 140rem;
			line-height: 40rem;
			height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}

	}
</style>
