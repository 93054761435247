<template>
  <div class="tes">
	  <!-- <notice></notice> -->
		<div class="data-box pl-20 pt-25">
			<ul class="flex flex-x-s">
				<li  @click="changeModal('新增')" class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
				<li class="ml-15">
					<Input v-model="search" class="form-ipt" placeholder="请输入名称">
							<Button slot="append" icon="ios-search"></Button>
					</Input>
				</li>
			</ul>
			<div class="table-box">
				<Table :columns="columns1" :data="data1" class="" disabled-hover>
					<template slot-scope="{ row }" slot="type">
							<div class="f-14 c-ff t-c table-type plr-10">{{row.type_arr[1]}}</div>
					</template>
					<template slot-scope="{row}" slot="state">
							<i-switch true-color="#209FFB" false-color="#F56E6E" v-model="row.status" size="large">
									<span slot="open">开</span>
									<span slot="close">关</span>
							</i-switch>
					</template>
					<template slot-scope="{row}" slot="handle">
							<ul class="flex flex-c">
								<li class="handle-edit flex-c shou" @click="changeModal('编辑',row.id)">
									<span class="iconfont iconedit1"></span>
									<span class="f-14">编辑</span>
								</li>
								<li class="handle-del flex-c ml-8 shou">
									<span class="iconfont icondelect"></span>
									<span class="f-14">删除</span>
								</li>
							</ul>
					</template>
				</Table>
			
				<div class="flex flex-x-e mt-10 pr-30">
					<p class="f-14 c-ff mr-20">总记录：{{total}}</p>
					<Page :page-size="per_page" :total="total" @on-change="changepage" />
				</div>
			</div>
		</div>
	
	
		<!-- 弹窗 -->
		<Modal
			:footer-hide="true"
			v-model="is_show"
			:closable="false"
			width="710"
			:mask-closable="false"
		>
			<span slot="title"></span>
			<div class="model-pop-modal">
				<!-- title头部 -->
				<Form ref="xinhao">
					<ul class="flex pt-20 flex-y-s">
						<li class="title-text c-ff f-20">{{is_type}}型号</li>
						<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal"></li>
					</ul>
					<ul class="plr-30">
						<li class="mt-30 flex">
							<div class="flex-box flex">
								<span class="f-16 c-ff t-l" style="width: 90rem;">协议类型：</span>
								<Select v-model="type" class="flex-box" placeholder="请选择协议类型">
										<Option v-for="item,index in list_type" :value="index" :key="item">{{ item }}</Option>
								</Select>
							</div>
							<div class="flex-box ml-50 flex">
								<span class="f-16 c-ff t-l" style="width: 90rem;">型号名称：</span>
								<Input v-model="name" class="form-ipt flex-box" placeholder="请输入型号名称">
								</Input>
							</div>
						</li>
						<li class="mt-30 flex">
							<div class="flex-box flex">
								<span class="f-16 c-ff t-l" style="width: 90rem;">编号：</span>
								<Input v-model="code" class="form-ipt flex-box" placeholder="请输入名称">
								</Input>
							</div>
							<div class="flex-box ml-50 flex">
								<span class="f-16 c-ff t-l" style="width: 90rem;">排序：</span>
								<Input v-model="weigh" type="number" class="form-ipt flex-box" placeholder="请填写排序">
								</Input>
							</div>
						</li>
						<li class="flex mt-35 flex-y-s">
							<span class="f-16 c-ff t-l" style="width: 90rem;">说明：</span>
							<Input v-model="description" type="textarea"   :rows="3" class="form-ipt flex-box" placeholder="请填写说明">
							</Input>
						</li>
						<li class="flex flex-x-s mt-50" style="padding-left: 90rem;">
							<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal">取消</div>
							<div class="queding f-18 c-ff t-c shou" @click="bindsubmit">提交</div>
						</li>
					</ul>
				</Form>
				
			</div>
		</Modal>
	</div>
</template>

<script>
import topheader from './topheader.vue'
import notice from './notice.vue'
export default {
  data () {
    return {
			is_show: false,
			columns1: [
					{
							title: 'ID',
							key: 'id',
							width:50,
							align:'center'
					},
					{
							title: '编号',
							key: 'code',
							align:'center'
					},
					{
							title: '名称',
							key: 'name',
							align:'center'
						
					},
					{
							title: '类型',
							slot: 'type',
							align: 'center',
							align:'center'
					},
					{
							title: '排序',
							key: 'weigh',
							align:'center'
					},
					{
							title: '添加时间',
							key: 'create_time',
							align:'center'
							
					},
					{
							title: '状态',
							slot: 'state',
							align: 'center',
							align:'center'
					},
					{
							title: '操作',
							align: 'center',
							slot: 'handle',
							align:'center',
							width:200
							
					}
			],
			data1: [],
			total: 0,
			per_page: 0,
			type:'', // 协议类型
			name:'', // 型号名称
			code:'', // 编号
			weigh:'', // 协议类型
			description:'', // 协议类型
			list_type: [], // 协议类型
			search: '',
			is_type: ''
		}
  },
	
	components: {
		topHeader: topheader,
		notice:notice
	},

  created () {
		
	},
	
	mounted () {
		this.modellist()
		this.typelist()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		changeModal (type,id) {
			if (type == '新增') {
				this.type = ''
				this.code = ''
				this.description = ''
				this.weigh = ''
				this.name = ''
			} 
			if (type == '编辑') {
				this.category_id = id
				this.xinhaoinfo(id)
			} 
			this.is_type = type
			this.is_show = !this.is_show
		},
		
		async bindsubmit () {
			let _this = this,
				{category_id, type, name, code, weigh, description, is_type} = _this;
			if (!type || !name || !code || !weigh || !description) {
				return _this.util.showMsg('warning', '请完善表单内容', 1.5, false)
			}
			if (is_type == '新增') {
				var data = { type, name, code, weigh, description, }
				var url = 'device.category/add'
			}
			if (is_type == '编辑') {
				var data = { type, name, code, weigh, description, category_id }
				var url = 'device.category/edit'
			}
			const res = await _this.post(url,data)
			_this.util.showMsg('success', '操作成功', 2, false)
			_this.modellist()
			_this.changeModal()
		},
		
		// 信号列表
		async modellist () {
			let _this = this
			const res = await _this.get('device.category/lists')
			res.list.data.forEach(x => {
				x.status = x.status==1?true:false
			})
			_this.data1 = res.list.data
			_this.total = res.list.total - 0
			_this.per_page = res.list.per_page -0
		},
		
		// 协议类型列表
		async typelist () {
			let _this = this
			const res = await _this.get('device.category/add')
			_this.list_type = res.list_type
		},
		
		// 编辑 需要的详情
		async xinhaoinfo (category_id) {
			let _this = this
			const res = await _this.get('device.category/edit', {category_id})
			_this.type = res.category.type - 0
			_this.code = res.category.code
			_this.description = res.category.description
			_this.weigh = res.category.weigh
			_this.name = res.category.name
		},
		
		
		
		changepage () {
			
		}
	}
}
</script>

<style lang="less" >
	.tes .ivu-table-cell{
		padding: 0px;
		
	}
.model-line-bg {
	width: 15rem;
	height: 900rem;
	padding-top: 30rem;
}
.model-line-bg.right img{
	transform: rotateY(180deg);
}
.meau-box {
	width: 227rem;
	height: 880rem;
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.meal {
		width: 100%!important;
		background: none;
	}
	.meau-bg {
		width: 251rem;
		height: 59rem;
		left: -13rem;
	}
	.meau-bg.top {
		top: -12rem;
	}
	.meau-bg.right {
		width: 251rem;
		height: 59rem;
		bottom: -12rem;
		left: -13rem;
		transform: rotateX(180deg);
	}
	.meau-detail {
		height: 56rem;
		background: url(../static/meau-bg.png) no-repeat center;
		background-size: 100%  100%;
		color: #48CBCC;
		line-height: 56rem;
		cursor: pointer;
	}
	.meau-detail.active {
		background: url(../static/meau-bg-active.png) no-repeat center;
		background-size: 100%  100%;
		color: #FFFFFF;
	}
}

.title-icon {
	width: 17rem;
	height: 17rem;
}
.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.close-icon {
	width: 23rem;
	height: 25rem;
}

// 主要列表样式
.table-box {
	
}
.data-box {
	height: 790rem;
	background: url(../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;
}
.form-ipt {
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.ivu-input {
		border: none;
		color: #48CBCC;
		background-color: rgba(0,0,0,0)
	}
	.ivu-icon-ios-search,.ivu-input::placeholder {
		color: #48CBCC;
	}
	.ivu-input-group-append {
		button {
			background: rgba(1, 169, 170, 0.1);
		}
		background: rgba(1, 169, 170, 0.1);
		border: none;
		border-left: 1px solid #48CBCC;
	}
}
.ivu-table th {
	height: 60px!important;
	background-color: rgba(0, 0,0, .1)!important;
	border-bottom: none;
	color: #fff;
	font-size: 14rem;
	font-weight: bold;
}
.ivu-table td {
	height: 72rem;
	background: rgba(1, 169, 170, 0.2);
	border-bottom: 5px solid #111727;
	color: #fff;
	font-size: 14rem;
}
.ivu-table {
	background-color: rgba(0,0,0,0);
}
.ivu-table:before {
	background-color: rgba(0, 0,0, .1)!important;
}
.table-type {
	background-color: #5EB95E;
	height: 24rem;
	line-height: 24rem;
	display: inline-block;
}
.handle-edit, .handle-del {
	width: 70rem;
	height: 32rem;
	.f-14 {
		margin-left: 3rem;
	}
}
.handle-edit {
	border: 1px solid #74B8F4;
	color: #74B8F4;
}
.handle-del {
	border: 1px solid #F16252;
	color: #F16252;
}
// 弹窗
.model-pop-modal {
	width: 710rem;
	height: 478rem;
	border: 1px solid #01A9AA;
	background: linear-gradient(360deg, #030909 0%, #004D53 100%);
	.title-text {
		width: 226rem;
		height: 43rem;
		line-height: 43rem;
		padding-left: 40rem;
		
		background: url(../static/title-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
	textarea {
	   resize: none;  
	}
	.quxiao {
		width: 140rem;
		height: 40rem;
		line-height: 40rem;
		border: 1px solid #01A9AA;
	}
	.queding {
		width: 140rem;
		height: 40rem;
		line-height: 40rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
	}
}

.ivu-modal-body {
	padding: 0;
}
.ivu-modal-content {
	background-color: rgba(0,0,0,0);
}
</style>
