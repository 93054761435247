<template>
  <div>

				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<!-- <li class="table-btn flex flex-c plr-20  mr-10"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li> -->
						<li class="table-btn flex flex-c plr-20" @click="submitDao"><span class="iconfont icondownload mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></li>
					</ul>
					<ul class="flex flex-x-s mt-20">
						<!-- <li class="f-14 c-ff mr-10">创建时间：</li> -->
						<li class="mr-10">
							<DatePicker  type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择时间" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
						</li>
					<!-- 	<li class="mr-10">
							<DatePicker  type="daterange" v-model="end_time" @on-change="changedate" placement="bottom-end" placeholder="结束时间	" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
						</li> -->
						<li class="mr-10">
							<Select v-model="status" style="width:160px" placeholder="故障状态">
								<Option  :value="1" >修复</Option>
								<Option  :value="0" >未修复</Option>
							</Select>
						</li>
					<!-- 	<li class="mr-10">
							<Input v-model="device_id" class="form-ipt" placeholder="设备id">
									
							</Input>
						</li> -->
						<li class="mr-10">
							<!-- clearable filterable -->
							<Select v-model="device_id" style="width:160px" placeholder="设备">
								<Option  :value="item.id" v-for="item in shebeiList">{{item.device_title}}</Option>
							</Select>
						</li>
						<li class="mr-10">
							<Select v-model="project_id" style="width:160px;color: #48CBCC" placeholder="项目">
								<Option  :value="item.project_id" v-for="item in projectList">{{item.name}}</Option>
							</Select>
						</li>
						<li class="mr-10">
							<Input v-model="search" class="form-ipt" placeholder="请输入故障描述">
									<Button @click="loglist(1)" slot="append" icon="ios-search"></Button>
							</Input>
						</li>
						<!-- <li class="table-btn flex flex-c plr-20" @click="clearSearch"><span class="f-14 c-ff">清空</span></li> -->
						
						<!-- <li class="mr-10">
							<Input v-model="search" class="form-ipt" placeholder="请输入操作用户名称">
							</Input>
						</li> -->
					</ul>
					<div class="table-box mt-10">
						<Table ref="table" :row-class-name="rowClassName"  :columns="columns1" :data="data1" class="mt-15 relative" disabled-hover  >
							<template slot-scope="{ row }" slot="id">
									<div class="f-14 c-ff ">{{row.id}}</div>
							</template>
							<template slot-scope="{ row }" slot="code">
									<div class="f-14 c-ff ">{{row.code}}</div>
							</template>
							<template slot-scope="{ row }" slot="name">
									<div class="f-14 c-ff ">{{row.device.code}}</div>
							</template>
							<template slot-scope="{ row }" slot="description">
									<div class="f-14 c-ff ">{{row.description}}</div>
							</template>
						<!-- 	<template slot-scope="{ row }" slot="type">
									<div class="f-14  ">{{row.device.category.name}}</div>
							</template>
							<template slot-scope="{ row }" slot="user">
									<div class="f-14 c-ff ">{{row.admin.username}}</div>
							</template> -->
						<!-- 	<template slot-scope="{ row }" slot="describe">
									<div class="f-14 c-ff ">{{row.device.remark}}</div>
							</template> -->
<!-- 							<template slot-scope="{row}" slot="state">
									<i-switch true-color="#209FFB" false-color="#F56E6E" size="large">
											<span slot="open">开</span>
											<span slot="close">关</span>
									</i-switch>
							</template> -->
<!-- 							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-c">
										<li class="handle-edit flex-c">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template> -->
						</Table>
						
						<!-- 分页 -->
						<div class="flex flex-x-e mt-20">
							<p class="f-14 c-ff mr-20 mt-10">总记录：{{total_pro}}</p>
							<Page :page-size="per_pro" :total="total_pro" @on-change="changepage1" />
						</div>
					</div>
				</div>
	
	</div>
</template>

<script>
import topheader from './topheader.vue'
export default {
  data () {
    return {
			project_id: '',
			shebeiList: [],  // 设备列表
			projectList: [],   // 项目列表
			status: '',
			time: [],
			start_time: '',
			end_time: '',
			device_sn: '',
			search: '',
			per_pro: 0,
			total_pro: 0,
			device_id: '',
			show: true,
			columns1: [
					{
							title: '序号',
							slot: 'id',
							width: 100,
							align: 'center',
							key: 'id',
					},
					{
							title: '项目名称',
							slot: 'code',
							align: 'center',
							key: 'code',
					},
					{
							title: '设备名称',
							slot: 'name',
							align: 'center',
							key: 'name',
					},
					{
							title: '描述',
							slot: 'description',
							ellipsis: true,
							key: 'description',
							align: 'center',
					},
					{
							title: '操作时间',
							key: 'create_time',
							align: 'center',
					}
			],
			data1: [],
			data2: [],
		}
  },
	
	components: {
		topHeader: topheader
	},
	mounted () {
		this.loglist()
		this._lists_nopage()
		this._getProjectAll()
	},

  methods: {
		// 点击清空搜索项
		clearSearch() {
			this.start_time = ''
			this.end_time = ''
			this.device_id = ''
			this.search = ''
			this.status = ''
			this.project_id = ''
		},
		// 获取项目列表
		async _getProjectAll() {
			const res = await this.get('project/all')
			
			this.projectList = res.list
		},
		// 获取设备列表
		async _lists_nopage() {
			const res = await this.get('device/lists_nopage')
			
			this.shebeiList= res.list
		},
		// 故障列表
		async loglist (page = 1) {
			let _this = this,
					{ start_time, end_time, device_id, search,status,project_id } = _this;
			const res = await _this.get('device.fault/lists', { page, search,start_time,end_time,device_id,status,project_id})
			console.log("故障列表",res);
			_this.data1 = res.list.data
			_this.data2 = res.list.data.map(item => {
				return {
					id: item.id,
					code: item.code,
					name: item.device.code,
					description: item.description,
					create_time: item.create_time,
				}
			})
			
			_this.total_pro = res.list.total
			_this.per_pro = res.list.per_page
		},
	  	// 导出数据--一页数据
		async submitDao(){
			
			let _this = this,
					{ start_time, end_time, device_id, search,status,project_id } = _this;
			const res = await _this.get('device.fault/explode', {search,start_time,end_time,device_id,status,project_id});
			window.open(res.file_url);
			console.log('ff',res.file_url)
			//this.is_show_pro = false;
		},
		// daochu () {
		// 	this.$refs.table.exportCsv({
		// 			filename: '故障列表',
		// 			columns: this.columns1.filter((col, index) => true),
		// 			// data: this.data2
		// 			data: this.data2.map(item=>{
		// 				item.create_time = "\t"+item.create_time;
						
		// 				return item
		// 			})	
		// 	})
		// },
		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		
		changedate (e) {
			this.start_time = e[0]
			this.end_time = e[1]
			this.time = e
		},
		changepage1 (val) {
			this.loglist(val)
		}
	}
}
</script>

<style lang="less" >
	// ::v-deep {
	// 	.ivu-select-input {
	// 		color: #48CBCC !important;
	// 	}
	// 	.ivu-select-selection div {
			
	// 		.ivu-select-input {
	// 			input {
	// 				color: #48CBCC !important;
	// 			}
				
	// 		}
	// 	}
		
	// 	.ivu-select-placeholder {
	// 		color: #48CBCC !important;
	// 	}
	// 	.ivu-select-dropdown {
	// 		z-index: 10000 !important;
	// 	}
	// 	.ivu-date-picker .ivu-select-dropdown{
	// 		z-index: 10000 !important;
	// 	}
	// 	.ivu-date-picker {
	// 		.ivu-select-dropdown{
	// 			z-index: 10000 !important;
	// 		}
	// 	}
	// }
.model-line-bg {
	width: 15rem;
	height: 900rem;
	padding-top: 30rem;
}
.model-line-bg.right img{
	transform: rotateY(180deg);
}
.meau-box {
	width: 227rem;
	height: 880rem;
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.meal {
		width: 100%!important;
		background: none;
	}
	.meau-bg {
		width: 251rem;
		height: 59rem;
		left: -13rem;
	}
	.meau-bg.top {
		top: -12rem;
	}
	.meau-bg.right {
		width: 251rem;
		height: 59rem;
		bottom: -12rem;
		left: -13rem;
		transform: rotateX(180deg);
	}
	.meau-detail {
		height: 56rem;
		background: url(../static/meau-bg.png) no-repeat center;
		background-size: 100%  100%;
		color: #48CBCC;
		line-height: 56rem;
		cursor: pointer;
	}
	.meau-detail.active {
		background: url(../static/meau-bg-active.png) no-repeat center;
		background-size: 100%  100%;
		color: #FFFFFF;
	}
}

.title-icon {
	width: 17rem;
	height: 17rem;
}
.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.close-icon {
	width: 23rem;
	height: 25rem;
}

// 主要列表样式
.table-box {
	
}
.data-box {
	height: 790rem;
	background: url(../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;
}
.form-ipt {
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.ivu-input {
		border: none;
		color: #48CBCC;
		background-color: rgba(0,0,0,0)
	}
	.ivu-icon-ios-search,.ivu-input::placeholder {
		color: #48CBCC;
	}
	.ivu-input-group-append {
		button {
			background: rgba(1, 169, 170, 0.1);
		}
		background: rgba(1, 169, 170, 0.1);
		border: none;
		border-left: 1px solid #48CBCC;
	}
}
.ivu-table th {
	height: 60px!important;
	background-color: rgba(0, 0,0, .1)!important;
	border-bottom: none;
	color: #fff;
	font-size: 14rem;
	font-weight: bold;
}
.ivu-table td {
	height: 72rem;
	background: rgba(1, 169, 170, 0.2);
	border-bottom: 5px solid #111727;
	color: #fff;
	font-size: 14rem;
}
.ivu-table {
	background-color: rgba(0,0,0,0);
}
.ivu-table:before {
	background-color: rgba(0, 0,0, .1)!important;
}
.table-type {
	background-color: #5EB95E;
	height: 24rem;
	line-height: 24rem;
	display: inline-block;
}
.handle-edit, .handle-del {
	width: 70rem;
	height: 32rem;
	.f-14 {
		margin-left: 3rem;
	}
}
.handle-edit {
	border: 1px solid #74B8F4;
	color: #74B8F4;
}
.handle-del {
	border: 1px solid #F16252;
	color: #F16252;
}
// 弹窗
.model-pop {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 710rem;
	height: 478rem;
	background: url(../static/data-bg.png) no-repeat center;
	background-size: 100% 100%;
}
</style>
