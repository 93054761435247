<template>
  <div class="data-box p-30 relative">
		<!-- 标题 -->
  	<ul class="flex flex-x-s">
			<li class="ml-50 flex flex-x-s">
				<DatePicker type="daterange" :options="options2" placement="bottom-end" placeholder="请选择开始和结束时间" class="datepicker" ></DatePicker>
				<div class="data-btn f-14 t-c">确定</div>
			</li>
		</ul>
		<div id="chart4" class="area-box mt-35"></div>
  </div>
</template>

<script>

export default {
  data () {
    return {
			columns1: [
					{
							title: '项目名称',
							key: 'id',
							align: 'center'
					},
					{
							title: '项目所在区域',
							align: 'center',
							key: 'name'
					},
					{
							title: '维修工',
							align: 'center',
							key: 'desc'
					},
					{
							title: '开始时间',
							align: 'center',
							key: 'time',
					},
					{
							title: '结束时间',
							align: 'center',
							key: 'handle',
					}
			],
			data1: [
					{
							id: 1,
							name: '123',
							desc: 1,
							time: '2016-10-03',
							handle: '2016-10-03',
					},
					{
							id: 1,
							name: '123',
							desc: 1,
							time: '2016-10-03',
							handle: '2016-10-03',
					},
					{
							id: 1,
							name: '123',
							desc: 1,
							time: '2016-10-03',
							handle: '2016-10-03',
					},
					{
							id: 1,
							name: '123',
							desc: 1,
							time: '2016-10-03',
							handle: '2016-10-03',
					}
			]
		}
  },

  created () {},
	
	mounted () {
		this.areaData()
	},

  methods: {
		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		// 接入面积统计
		areaData () {
			let _this = this
			// console.log(this.$echarts)
			var myChart = this.$echarts.init(document.getElementById("chart4"), 'shine');
			var option = {
					title: {
							text:"",
							textStyle:{
									fontSize:16,
									padding:"10px"
							}
					},
					tooltip: {},
					xAxis: {
							type: 'value',
							axisLine: {
								show: true,
								lineStyle: {
										type: 'solid',
										color:'#2772E9',
										opacity: 0.6
								}
							},
						  splitNumber: 21,
							axisTick: {
								show: false
							},
							axisLabel: {
								show: true,
								color: '#ccc',
								fontSize: 12
							},
							splitLine: {
								show: true,
								lineStyle: {
										type: 'dashed',
										color:'#2772E9',
										opacity: 0.4
								}
							},
					},
		
					yAxis: {
							type: 'category',
							data: ['北京','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津','天津'],
					
							// axisLine: {
							// 	show: true,
							// 	lineStyle: {
							// 			type: 'solid',
							// 			color:'#2772E9',
							// 			opacity: 0.6
							// 	}
							// },
							axisTick: {
								show: false
							},
							axisLine: {
								show: true,
								lineStyle: {
										type: 'solid',
										color:'#2772E9',
										opacity: 0.6
								}
							},
							axisLabel: {
								show: true,
								color: '#ccc',
								fontSize: 12,
							}
					},
					grid: {
						left: '5%',
						top: '3%',
						bottom: '8%',
						right: '3%',
					},
					series: [
							{
									barWidth: 10,
									data: [50, 100, 600, 100, 300, 100, 700, 100, 100, 300 ,100, 600, 500, 1000, 100, 100, 100 ,1000],
									name: "累计",
									type: "bar",
									itemStyle: {
											color: new this.$echarts.graphic.LinearGradient(
													1, 0, 0, 0,
													[
															{offset: 1, color: '#1376BB'},
															{offset: 0, color: '#00C5AB'}
													]
											)
									},
							}
					]
			};
			myChart.setOption(option, true);
		},
	}
}
</script>

<style lang="less" >
.data-box {
	background: linear-gradient(360deg, rgba(3, 9, 9, 0.14) 0%, rgba(0, 77, 83, 0.7) 100%);
}
.data-box::after {
	content: '';
	position: absolute;
	width: 655rem;
	height: 327rem;
	right: 0;
	bottom: 0;
	background: url(../../static/table-bg.png) no-repeat center ;
	background-size: 655px 327rem;
	z-index: -1;
}
.datepicker {
	width: 260rem;
	border: 1px solid rgba(1, 169, 170, 0.8)!important;
	border-radius: 4px 0 0 4rem;
}
.data-btn {
	width: 70rem;
	height: 34rem;
	line-height: 34rem;
	border: 1px solid #01A9AA;
	border-left: none;
	border-radius: 0 4px 4px 0;
	color: #15E6CD;
}
.area-box {
	height: 660rem;
}

.table-info-row {
	td {
		border-bottom: none!important;
		background: rgba(1, 169, 170, 0.24)!important;
	}
}
.table-error-row {
	td {
		border-bottom: none!important;
		background-color: rgba(0, 0, 0, 0.1) !important;
	}
}

</style>
