<template>
  <div>
			<div class="eq-listcom  data-box">
				<ul class="tabs-title flex flex-x-s t-c shou">
					<li @click="navigation('/equip/list')">项目用户</li>
					<li class="active"  @click="navigation('/equip/list/common')">普通用户</li>
				</ul>
				<!-- 右侧项目地图 -->
				<div class="flex-box map flex flex-dir-col flex-y-s mt-10">
					<div class="plr-20 maplist-title flex">
						<p class="f-16 c-ff bold ">普通用户设备（{{total}}）</p>
						<ul class="flex flex-x-s">
							<li class="table-btn flex flex-c plr-20 shou mr-10" @click="addEq()"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
							<li class="table-btn flex flex-c plr-20 shou" @click="daochu"><span class="iconfont icondownload mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></li>
						</ul>
					</div>
					<div class="flex-box w100 pt-15 plr-25"  style="overflow-x: auto;">
						<ul class="flex flex-x-s ">
							<li class="f-14 c-ff mr-5">选择时间：</li>
							<li class="mr-10 mb-10">
								<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
							</li>
							<li class="f-14 c-ff mr-5">地区筛选：</li>
							<li class="mr-10 mb-10">
								<Cascader 
									class="Cascader"
									size="large"
									:data="options"
									v-model="selectedOptions"
									@on-change="addressChange">
								</Cascader >
							</li>
							<li class="mr-10 mb-10">
								<Select v-model="haserr" style="width:160px" placeholder="是否故障">
									<Option  :value="1" >故障</Option>
									<Option  :value="0" >正常</Option>
								</Select>
							</li>
							<li class="mr-10 mb-10">
								<Select v-model="online" style="width:160px" placeholder="在线状态">
										<Option  :value="1" >在线</Option>
										<Option  :value="0" >离线</Option>
								</Select>
							</li>
							<li class="mr-10 mb-10">
								<Select v-model="cate_id" style="width:160px" placeholder="全部类别">
										<Option v-for="item,index in modeldata" :value="item.id" :key="index">{{ item.name }}</Option>
								</Select>
							</li>
							<!-- <li class="mr-10">
								<Input v-model="value13" class="form-ipt" placeholder="请输入用户手机号">
								</Input>
							</li> -->
							<li class="mr-10 mb-10">
								<Input v-model="search" class="form-ipt" placeholder="请输入设备编码">
										<Button slot="append" @click="eqlistData1" icon="ios-search"></Button>
								</Input>
							</li>
						</ul>
						<Table :columns="columns1" :data="data1" class="" disabled-hover max-height="560" width="1680" ref="table">
							<template slot-scope="{row}" slot="state">
									<div class="state-color bad flex-c" v-if="row.haserr == 1">
										<div class="dight"></div>
										<span>故障</span>
									</div>
									<div class="state-color flex-c" v-else>
										<div class="dight"></div>
										<span>正常</span>
									</div>
									
							</template>
							<template slot-scope="{row}" slot="name">
									<div>{{row.category.name}}</div>
							</template>
							<template slot-scope="{row}" slot="user">
									<div class="f-14 c-ff">
										<p>{{row.nickname}}</p>
										<p>{{row.mobile}}</p>
									</div>
							</template>
							<template slot-scope="{row}" slot="time">
									<div class="f-14 c-ff">
										<p class="onelist">{{row.remark}}</p>
										<p>{{row.create_time}}</p>
									</div>
							</template>
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c shou" @click="navigationTo(row.category.type, row.id)"> 
											<span class="iconfont iconwarnning"></span>
											<span class="f-14">详情</span>
										</li>
										<li class="handle-edit flex-c ml-8 shou"  @click="editEq(row)">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑{{row.pr}}</span>
										</li>
										<li class="handle-del flex-c ml-8 shou" @click="delEq(row.id)">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template>
						</Table>
						
						<!-- 分页 -->
						<div class="flex flex-x-e mt-10 pr-30">
							<p class="f-14 c-ff mr-20">总记录：{{total}}</p>
							<Page :current="page" :page-size="per_page" :total="total" @on-change="changepage" />
						</div>
					</div>
				</div>
			</div>
			
			<Drawer :title="add_title" v-model="is_add" width="40%" :mask-closable="false">
					<ul>
						<li class="flex flex-x-s flex-y-s  mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备型号</div>
							<div class="flex-box">
								<Select v-model="options_add.category_id" class="gz w100">
									 <Option v-for="item,index in modeldata" :value="item.id" :key="index">{{ item.name }}</Option>
							  </Select>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备编码</div>
							<div class="flex-box"><Input v-model="options_add.code" placeholder="请填写设备编码"   class="b-b w100" /></div>
						</li>
						
						<li class="flex flex-x-s flex-y-s  mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">所属项目</div>
							<div class="flex-box">
								<Select v-model="options_add.project_id" class="gz w100">
									 <Option v-for="(item,index) in proListNew" :value="item.project_id" :key="index">{{ item.name }}</Option>
							  </Select>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备台数</div>
							<div class="flex-box"><Input v-model="options_add.group_num" placeholder="请填写设备台数"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">设备SN</div>
							<div class="flex-box"><Input v-model="options_add.sn" placeholder="请填写设备SN"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">省市区</div>
							<div class="flex-box"><Cascader 
									class="Cascader "
									style="width: 100%"
									size="large"
									:data="options"
									v-model="selectedOptions1"
									@on-change="addressChange1">
								</Cascader ></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">详细地址</div>
							<div class="flex-box"><Input v-model="options_add.address" placeholder="请填写详细地址"   class="b-b w100" /></div>
						</li>
						
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">坐标系</div>
							<div class="flex-box"><Input v-model="options_add.longitude" placeholder="点击地图拾取" style="width: 100px"  class="b-b" /></div>
							<div class="flex-box"><Input v-model="options_add.latitude" placeholder="点击地图拾取" style="width: 100px"  class="b-b" /></div>
						</li>
						<li class="mt-15">
							 <baidu-map
									class="bm-view"
									ak="QFgFQorrB84maOZh0pPGC8kUiP0mGIhx"
									:center="options_add.province + options_add.city + options_add.region"
									style="height: 300rem;"
									:zoom="zoom"
									:scroll-wheel-zoom="true"
									@ready="createMap"
								>
									<bm-marker :position="{lng: options_add.longitude, lat: options_add.latitude}"/>
								</baidu-map>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">项目名称</div>
							<div class="flex-box"><Input v-model="options_add.device_title" placeholder="请填写项目名称"   class="b-b w100" /></div>
						</li>
						
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户姓名</div>
							<div class="flex-box"><Input v-model="options_add.client_name" placeholder="请填写客户姓名"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">客户电话</div>
							<div class="flex-box"><Input v-model="options_add.client_mobile" placeholder="请填写客户电话"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">密码</div>
							<div class="flex-box"><Input v-model="options_add.password" placeholder="请填写密码,不填表示不做修改"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">匹数</div>
							<div class="flex-box"><Input v-model="options_add.horse" placeholder="请填写匹数"   class="b-b w100" /></div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">安装时间</div>
							<DatePicker type="date" v-model="options_add.fix_date" @on-change="changeFixDate" placement="bottom-end" placeholder="请选择时间" style="border: 1px solid #48CBCC;flex:1"></DatePicker>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">视频展示图片</div>
							<div class="flex-box">
						    <Upload action="https://www.kochem.net.cn/wap/upload/image" 
									name="file" 
									:headers="{token}"
									:on-success="uploadSuccess1"
									:show-upload-list="false"
									accept="image/png,image/tiff,image/jpeg"
									:format="['jpg','jpeg','png','tiff']"> 
										<div class="table-btn flex-c" style="width: 132rem;">
											<span class="iconfont iconshangjia3 f-14 c-ff"></span>
											<span class="f-14 c-ff ml-8">选择图片</span>
										</div>
								</Upload>
								<ul class="flex flex-x-s pr-40  mt-20" v-if="video_img">
									<img :src="video_img" style="width:200px;height:100px"/>
								</ul>
								<p class="f-14 c-10b">建议高度：660*476</p>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">模型展示图片</div>
						   		<div class="flex-box">
								<Upload action="https://www.kochem.net.cn/wap/upload/image" 
								name="file" 
								:headers="{token}"
								:on-success="uploadSuccess2"
								:show-upload-list="false"
								accept="image/png,image/tiff,image/jpeg"
								:format="['jpg','jpeg','png','tiff']"> 
									<div class="table-btn flex-c" style="width: 132rem;">
										<span class="iconfont iconshangjia3 f-14 c-ff"></span>
										<span class="f-14 c-ff ml-8">选择图片</span>
									</div>
								</Upload>
								<ul class="flex flex-x-s pr-40  mt-20" v-if="model_img">
									<img :src="model_img" style="width:200px;height:100px"/>
								</ul>
								<p class="f-14 c-10b">建议高度：400*270</p>
							</div>
						</li>
						<li class="flex flex-x-s mt-15">
							<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">萤石云外链地址链接</div>
							<div class="flex-box"><Input v-model="options_add.vedio_url" placeholder="请填写萤石云外链地址链接"   class="b-b w100" /></div>
						</li>
						
						
						<li class="flex flex-x-c flex-y-s  mt-50">
							<div class="submit-btn f-18 c-ff t-c mr-40 cancel shou" @click="changemodal('is_add')">取消</div>
							<div class="submit-btn f-18 c-ff t-c shou" @click="submitEq">提交</div>
						</li>
						
					</ul>
			</Drawer>
			
			<Modal
					:footer-hide="true"
					v-model="is_show_del"
					:closable="false"
					width="471"
					:mask-closable="false"
				>
				<span slot="title"></span>
				<div class="model-pop-jn eq-jn plr-20">
					<ul class="flex pt-20 flex-y-s flex-x-e">
						<li class="iconfont iconclose1 c-48c shou" @click="changemodal('is_show_del')"></li>
					</ul>
					<p class="t-c f-20 bold mt-30" style="color: #54FDF5;">友情提示</p>
					<p class="t-c f-16 c-ff mt-20">是否确定删除该设备?</p>
					<li class="flex flex-c mt-40" >
						<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changemodal('is_show_del')">取消</div>
						<div class="queding f-18 c-ff t-c shou" @click="submitdel">确认</div>
					</li>
				</div>
			</Modal>
	</div>
</template>
<script>
	import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
	import { regionData,CodeToText } from 'element-china-area-data'
export default {
  data () {
    return {
		    proListNew:[],
			token: localStorage.getItem('token'),
			is_add: false,
			is_show_del: false,
			add_title: '新增设备', 
			selectedOptions1: [],
			modeldata: [],
			edit_device_id: 0,
			del_id: 0,
			options_add: {
				project_id: 0,
				category_id: 0,
				code: '',
				sn: '',
				password: '123456',
				province: '',
				city: '',
				region: '',
				address: '',
				client_mobile: '',
				client_name: '',
				longitude: 0,
				latitude: 0,
				vedio_img_id: '',
				model_img_id: '',
				fix_date: '',
				horse: '',
				group_num:'',
				vedio_url: '',
				device_title: '',
			},
			model_img: '',
			video_img: '',
			columns1: [
					{
							title: 'ID',
							key: 'id',
							width: 80
					},
					{
							title: '编号',
							key: 'code',
							width:200
					},
					
					{
							title: '名称',
							slot: 'name',
							className: 'name-color',
							width:200
					},
					{
							title: '设备SN',
							key: 'sn',
							width:200
							
					},
					{
							title: '绑定用户',
							slot: 'user',
							width:200
					},
					{
							title: '状态',
							slot: 'state',
							align: 'center',
							width:100
							
					},
					{
							title: '备注/创建时间',
							slot: 'time',
							width:200
							
					},
					
					{
							title: '操作',
							align: 'left',
							slot: 'handle',
							width: 280
					}
			],
			columns2: [
					{
							title: '设备编号',
							key: 'code'
					},
					{
							title: '设备型号',
							key: 'ccode',
					},
					
					
					{
							title: '设备名称',
							key: 'cname',
					},
					{
							title: '设备SN',
							key: 'sn',
					},
					{
							title: '绑定用户',
							key: 'nickname'
					},
					{
							title: '手机号',
							key: 'mobile'
					},
					{
							title: '设备地址',
							key: 'address'
					},
					{
							title: '在线状态',
							key: 'zx'
					},
					{
							title: '故障状态',
							key: 'gz'
					},
					{
							title: '创建时间',
							key: 'ctime'
					},
			],
			data1: [],
			data2: [],
			options: regionData,
			selectedOptions: [],
			time: '', //
			start_time: '', // 开始时间
			end_time: '', // 结束时间
			province: '', // 省
			city: '', // 市
			region: '', // 区
			haserr: -1, // 是否故障
			online: -1, // 是否故障
			search: '', // sn码
			total: 0,
			per_page: 0,
			page: 1,
			cate_id: 0,
			center: {lng: 0, lat: 0},
			zoom: 10,
			model: {
				lng: '',
				lat: '',
				address: ''
			}
		}
  },


  created () {
	},
	
	components: {
		BaiduMap,
		// BmLocalSearch
	},

	mounted () {
		this.eqlistData()
		this.modellist();
		this.getPlist()
	},
	
  methods: {
		// 安装时间
		changeFixDate(e){
			this.options_add.fix_date = e
			
		},
		//模型图片
		uploadSuccess2 (response, file, fileList) {
			// console.log(response)
			this.model_img  = response.data.file_path;
			this.options_add.model_img_id = response.data.file_id
		},
		//视频图片
		uploadSuccess1 (response, file, fileList) {
			// console.log(response)
			this.video_img  = response.data.file_path;
			this.options_add.vedio_img_id = response.data.file_id
		},
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		
		// 分别跳转不同详情
		navigationTo (type,id) {
			// localStorage.setItem('device_title',device_title)
			let routeUrl = ''
			// if (type == 0 || type == 1) {
			// 	routeUrl = this.$router.resolve({path: '/equip/list/detail', query: {id}})
			// } else if (type == 2) {
			// 	routeUrl = this.$router.resolve({path: '/equip/list/detail1', query: {id}})
			// } else {
			// 	routeUrl = this.$router.resolve({path: '/equip/list/detail2', query: {id}})
			// }
			
			// // console.log(window.location.href);
			// // console.log(window.location.href.split('#')[0]);
			
			// // console.log(location.host);
			// // console.log(routeUrl.href);
			// console.log(location.host + '/' + routeUrl.href)
			
			if (type == 0 || type == 1) {
				routeUrl = this.$router.resolve({path: '/equip/list/detail', query: {id}})
			} else if (type == 2) {
				routeUrl = this.$router.resolve({path: '/equip/list/detail1', query: {id}})
			} else {
				routeUrl = this.$router.resolve({path: '/equip/list/detail2', query: {id}})
			}
			window.open(window.location.href.split('#')[0] + routeUrl.href);
		},
		
		// 新建设备
		async submitEq () {
			let _this = this,
					{ options_add,edit_device_id } = _this;
					let options_add1 = JSON.parse(JSON.stringify(options_add)) 
					delete options_add1.project_id
			let arr = Object.values(options_add1)
			let is = arr.some(x => {!x})
			if (is&&!edit_device_id) {
				return _this.util.showMsg('warning', '请完善表单内容', 2, false)
			}
			if (edit_device_id) {
				var url = 'device/edit'
				var data = {...options_add,device_id:edit_device_id}
			} else {
				var url = 'device/add'
				var data = options_add
			}
			console.log('普通用户添加数据',data);
			// return
			const res = await _this.post(url,data)
			_this.util.showMsg('success', '操作成功', 1.5, false)
			_this.eqlistData()
			_this.changemodal('is_add')
		},
		
		// 检索
		eqlistData1 () {
			this.page = 1
			this.eqlistData()
		},
		// 获取设备列表
		async eqlistData () {
			let _this = this,
					{ start_time, end_time, province, city, cate_id, region, haserr, online, search, page } = _this;
			const res = await _this.get('device/lists', {start_time,cate_id, end_time, province, city, region, haserr, online, search, page})
			var data2 = []
			res.list.data.forEach(x => {
				let obj = {
					code: x.code,
					ccode: x.category.code,
					cname: x.category.name,
					sn: x.sn,
					nickname: x.nickname,
					mobile: x.mobile,
					address: x.province + x.city + x.region + x.address,
					zx: x.online==1?'正常':'离线',
					gz: x.haserr==1?'正常':'故障',
					ctime: x.create_time,
					
				}
				data2.push(obj)
			})
			_this.data2 = data2
			_this.data1 = res.list.data
			_this.total = res.list.total - 0
			_this.per_page = res.list.per_page -0
		},
		
		addressChange(arr) {
			this.province = CodeToText[arr[0]]
			this.city = CodeToText[arr[1]]
			this.region = CodeToText[arr[2]]
		},
		addressChange1(arr) {
			
			this.options_add.province = CodeToText[arr[0]]
			this.options_add.city= CodeToText[arr[1]]
			this.options_add.region = CodeToText[arr[2]]
		},
		// 改变时间
		changedate (e) {
			// console.log(e)
			this.start_time = e[0]
			this.end_time = e[1]
		},
		// 获取类别
		async modellist () {
			let _this = this
			const res = await _this.get('device.category/lists')
			_this.modeldata = res.list.data
		},
		
		
		// 分页
		changepage (page) {
			this.page = page
			this.eqlistData()
		},
		
		createMap ({BMap, map}) {
			this.zoom = this.zoom
			const viewthis = this
			map.addEventListener('click', function (e) {
				viewthis.options_add.longitude = e.point.lng
				viewthis.options_add.latitude = e.point.lat
			})
			
		},
		async submitdel () {
			let _this = this
			const res = await _this.post('device/delete', {device_id: _this.del_id})
			_this.util.showMsg('success', '操作成功', 1.5, false)
			_this.eqlistData()
			_this.changemodal('is_show_del')
		},
		
		changemodal (type) {
			this[type] = !this[type]
		},
		delEq (id) {
			this.del_id = id
			this.changemodal('is_show_del')
		},
		editEq (data) {
			console.log(data.project_id);
			let _this = this
			_this.options_add.category_id = data.category_id
			_this.options_add.code = data.code
			_this.options_add.sn = data.sn
			_this.options_add.password = ''
			_this.options_add.province = data.province
			_this.options_add.city = data.city
			_this.options_add.region = data.region
			_this.options_add.address = data.address
			_this.options_add.client_mobile = data.mobile
			_this.options_add.client_name = data.nickname
			_this.options_add.longitude = data.longitude
			_this.options_add.latitude = data.latitude
			_this.options_add.group_num = data.group_num;
			_this.options_add.project_id = data.project_id;
			_this.options_add.vedio_img_id = data.vedio_img_id
			_this.options_add.model_img_id = data.model_img_id
			_this.options_add.fix_date = data.fix_date
			_this.options_add.horse = data.horse
			_this.options_add.vedio_url = data.vedio_url
			_this.options_add.device_title = data.device_title
			_this.model_img = data.modelimg.file_path
			_this.video_img = data.vedioimg.file_path
			
			
			_this.edit_device_id = data.id
			_this.selectedOptions1 = [_this.findKey(data.province),_this.findKey(data.city),_this.findKey(data.region)]
			_this.changemodal('is_add')
		},
		addEq () {
			let _this = this
			_this.options_add.category_id = ''
			_this.options_add.code = ''
			_this.options_add.sn = ''
			_this.options_add.password = '123456'
			_this.options_add.province ='北京市'
			_this.options_add.city = '北京市'
			_this.options_add.region = '东城区'
			_this.options_add.address = ''
			_this.options_add.client_mobile = ''
			_this.options_add.client_name = ''
			_this.options_add.longitude = ''
			_this.options_add.latitude = ''
			_this.options_add.vedio_img_id = ''
			_this.options_add.model_img_id = ''
			_this.options_add.fix_date = ''
			_this.options_add.horse = ''
			_this.options_add.vedio_url = ''
			_this.options_add.device_title = ''
			_this.model_img = ''
			_this.video_img = '';
			_this.options_add.project_id = _this.project_id
			_this.edit_device_id = 0
			_this.selectedOptions1 = []
			_this.changemodal('is_add')
		},
		
		findKey (value, compare = (a, b) => a === b) {
		  return Object.keys(CodeToText).find(k => compare(CodeToText[k], value))
		},
		
		// 导出数据
		daochu () {
			this.$refs.table.exportCsv({
					filename: 'data',
					columns: this.columns2,
					data: this.data2
			});;
		},
		async getPlist () {
			let _this = this
			const res = await _this.get('project/all')
			_this.proListNew = res.list;
			console.log('fff',_this.proListNew)
		},


	}
}
</script>

<style lang="less" >
.eq-listcom {
	td.name-color {
		color: #48CBCC!important;
	}
	.state-color {
		color: #8FF38E;
		.dight {
			width: 6rem;
			height: 6rem;
			background: #8FF38E;
			border-radius: 3rem;
			margin-right: 10rem;
		}
	}
	.state-color.bad {
		color: #F56E6E;
		.dight {
			width: 6rem;
			height: 6rem;
			background: #F56E6E;
			border-radius: 3rem;
			margin-right: 10rem;
		}
	}
	.map-box >div{
		height: 790rem;
		
	}
	.flex-box.map {
			// background: rgba(1, 169, 170, 0.1);
		}
	.map-list {
		width: 280rem;
		background: url(../static/table-bg.png) no-repeat bottom right ;
		background-color: rgba(1, 169, 170, 0.1);
		background-size: 655px 327rem;
	
	}
	
	// 标签页
	.tabs-title {
		li {
			width: 120rem;
			height: 52rem;
			line-height: 52rem;
			border: 1px solid #48CBCC;
			color: #fff;
			opacity: 0.6;
		}
		li.active {
			background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
			opacity: 1;
		}
	}
	.map-pro {
		ul {
			color: #fff;
			opacity: .6;
			padding-left: 50rem;
		}
		li {
			margin-top: 10rem;
			cursor: pointer;
		}
		.ivu-collapse-simple,.ivu-collapse,.ivu-collapse>.ivu-collapse-item,.ivu-collapse-content {
			background-color: rgba(0,0,0,0);
			border: none;
		}
		.ivu-collapse-header {
			font-size: 16rem;
			color: #fff;
			opacity: .6;
		}
		.ivu-collapse-item-active .ivu-collapse-header{
			font-size: 16rem;
			color: #fff;
			opacity: 1;
		}
		
	}
	
	.maplist-title {
		height: 55rem;
		width: 100%;
		background: rgba(1, 169, 170, 0.1);
	}

}

	
	.refund-add-title {
		width: 150rem;
	}
	.refund-add-title.must {
		
	}
	.refund-add-title.must::after {
		content: '*';
		color: #F16252;
		margin-left: 8rem;
		font-size: 16rem;
	}
	.b-b {
		border-bottom: 1px solid #01A9AA;
	}
	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(../static/xunwen-pop.png) no-repeat center;
		background-size: 100% 100%;
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	
	}
	
	.ivu-modal-body {
		padding: 0;
	}
	.ivu-modal-content {
		background-color: rgba(0,0,0,0);
	}
	.ivu-date-picker {
		border: 1px solid #01A9AA;
	}
</style>
