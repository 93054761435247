<template>
		<bm-overlay
		  ref="customOverlay"
		  :class="{sample: true}"
		  pane="labelPane"
		  @draw="draw">
			<div @click="change1"><img src="../static/address.png" alt="" /></div>
		</bm-overlay>
</template>
 
<script>
import { BmOverlay } from 'vue-baidu-map'
export default {
  props: {position: Object}, // 用来接受传入的值，用来定制样式
  components: {
    BmOverlay
  },
  watch: {
    position: {
      handler () {
        this.$refs.customOverlay.reload()  // 当位置发生变化时，重新渲染，内部会调用draw
      },
      deep: true
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    // 这是百度地图的重绘函数,用于维持覆盖物的位置（这里的值貌似会影响拖拉时的偏移度）
    draw ({ el, BMap, map }) {
      const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
      el.style.left = pixel.x - 16 + 'px'
      el.style.top = pixel.y - 16 + 'px'
    },
		// draw1 ({ el, BMap, map }) {
		//   const { lng, lat } = this.position
		//   const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
		//   el.style.left = pixel.x - 36 + 'px'
		//   el.style.top = pixel.y - 36 + 'px'
		// },
		// draw2 ({ el, BMap, map }) {
		//   const { lng, lat } = this.position
		//   const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
		//   el.style.left = pixel.x - 56 + 'px'
		//   el.style.top = pixel.y - 56 + 'px'
		// },
		change1 () {
			console.log('222')
		}
  }
}
</script>
 
<style>
.sample {
  width: 43rem;
  height: 63rem;
 /* line-height: 30rem;
  border-radius: 32rem;
  background: rgba(0,0,0,0.5);
  overflow: hidden;
  box-shadow: 0 0 5px #000;
  color: #fff;
  text-align: center; */
  position: absolute;
}
/* .sample.active {
  background: rgba(0,0,0,0.75);
  color: #fff;
} */
</style>