<template>
  <div>
			<div class="map-box  flex mapyt">
				<!-- 左侧列表 -->
				<div class="map-list mr-15">
					<div class=" f-16 bold c-ff pro-name plr-25" >{{project_name}}</div>
					<div class="pl-30 mt-10">
						<ul>
							<li class="mt-25 f-16 c-ff">
								<div class="shou"  @click="navigation('/equip/map/yt?id='+project_id)">在线监控 <span class="iconfont iconxiangyoujiantou f-12 bold"></span></div>
								<ul v-if="0">
									<li class="pl-30 mt-20 shou"  @click="navigation('/equip/map/yt?id='+project_id)">实时云图</li>
									<li class="pl-30 mt-20 shou" @click="navigation('/equip/map/sj?id='+project_id)">实时数据</li>
									<!-- <li class="pl-30 mt-20">实时曲线</li> -->
								</ul>
							</li>
							<li class="mt-25 f-16 shou c-ff" @click="navigation('/equip/map/bj?id='+project_id)">故障报警 </li>
							<li class="mt-25 f-16 shou    c-48c relative " >设备管理 </li>
						</ul>
					</div>
				</div>
				<!-- 右侧项目地图 -->
				<div class="flex-box  flex flex-dir-col flex-y-s">
					<div class="plr-20 maplist-title flex" >
						<div class="f-16 c-ff bold " v-if="project">{{project.name}}</div>
						<ul class="flex flex-x-s">
							<!-- <li class="table-btn flex flex-c plr-20 shou mr-10" @click="addEq()"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li> -->
							<!-- <li class="table-btn flex flex-c plr-20 shou" @click="daochu"><span class="iconfont icondownload mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></li> -->
						</ul>
					</div>
					<div class="flex-box w100 pt-15 plr-25" style="background: rgba(1, 169, 170, 0.1);overflow-x: auto;">
						<ul class="flex flex-x-s ">
							<li class="f-14 c-ff mr-10">创建时间：</li>
							<li class="mr-10">
								<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择日期" style="width: 200rem;border: 1px solid #48CBCC;"></DatePicker>
							</li>
							<li class="mr-10">
								<Select v-model="haserr" style="width:160px" placeholder="是否故障">
									<Option  :value="1" >故障</Option>
									<Option  :value="0" >正常</Option>
								</Select>
							</li>
							<li class="mr-10">
								<Select v-model="online" style="width:160px" placeholder="在线状态">
										<Option  :value="1" >在线</Option>
										<Option  :value="0" >离线</Option>
								</Select>
							</li>
							<li class="mr-10">
								<Select v-model="cate_id" style="width:160px" placeholder="全部类别">
										<Option v-for="item,index in modeldata" :value="item.id" :key="index">{{ item.name }}</Option>
								</Select>
							</li>
							<li class="mr-10">
								<Input v-model="search" class="form-ipt" placeholder="请输入设备编码">
										<Button slot="append" @click="eqlistData" icon="ios-search"></Button>
								</Input>
							</li>
						</ul>
						<!-- <p class="f-14 c-ff ptb-15">绑定用户：王晓宇 13965030255</p> -->
						<Table :columns="columns1" :data="data1" class="" disabled-hover width="1340rem">
							<template slot-scope="{row}" slot="state">
									<div class="state-color flex-c" v-if="row.zx == '在线'">
										<div class="dight"></div>
										<span>在线</span>
									</div>
									<div class="state-color bad flex-c" v-else>
										<div class="dight"></div>
										<span>离线</span>
									</div>
							</template>
							<template slot-scope="{row}" slot="time">
									<div class="f-14 c-ff">
										<p class="onelist">{{row.remark}}</p>
										<p>{{row.create_time}}</p>
									</div>
							</template>
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c shou" @click="navigationTo(row.category.type, row.id)">
											<span class="iconfont iconwarnning"></span>
											<span class="f-14">详情</span>
										</li>
									</ul>
							</template>
						</Table>
						<!-- 分页 -->
						<div class="flex flex-x-e mt-20">
							<p class="f-14 c-ff mr-20 mt-10">总记录：{{total_pro}}</p>
							<Page :page-size="per_pro" :total="total_pro" @on-change="changepage1" />
						</div>
					</div>
				</div>
				
				
			</div>
	</div>
</template>

<script>
	import myOverlay from './myoverly.vue'
export default {
  data () {
    return {
			value13:'',
			columns1: [
					{
							title: 'ID',
							key: 'id',
							width: 60,
					},
					{
							title: '编号',
							key: 'code'
					},
					{
							title: '型号',
							key: 'ccode',
							width:100
					},
					{
							title: '名称',
							key: 'cname',
							className: 'name-color'
					},
					{
							title: '设备SN',
							key: 'sn',
							align: 'center',
							width: 160
					},
					{
							title: '状态',
							slot: 'state',
							align: 'center',
							width: 120
					},
					{
							title: '备注/创建时间',
							slot: 'time',
							width: 200
					},
					
					{
							title: '操作',
							align: 'left',
							slot: 'handle',
							width: 180
					}
			],
			data1: [],
			haserr: -1,
			online: -1,
			search: '',
			cate_id: 0,
			start_time: '',
			end_time: '',
			project_id:0,
			time:[],
			modeldata: [],
			total_pro: 0,
			per_pro: 0,
			project: null,
			project_name: ''
			
		}
  },
	
	components: {
		myOverlay
	},

  created () {
		this.project_id = localStorage.getItem('project_id')
		this.project_name = localStorage.getItem('project_name')
	},

	mounted () {
		this.eqlistData()
		this.equipinfo()
	},
	
  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		// 分别跳转不同详情
		navigationTo (type,id) {
			if (type == 0 || type == 1) {
				this.$router.push({path: '/equip/list/detail', query: {id}})
			} else if (type == 2) {
				this.$router.push({path: '/equip/list/detail1', query: {id}})
			} else {
				this.$router.push({path: '/equip/list/detail2', query: {id}})
			}
		},
		
		// 获取设备详情
		async equipinfo () {
			let _this = this
			const res = await _this.get('project/detail', {project_id: _this.project_id})
			_this.project = res.project
		},
		
		// 获取设备列表
		async eqlistData (page = 1) {
			let _this = this,
					{  project_id,haserr,online,search,cate_id,start_time,end_time } = _this;
			const res = await _this.get('device/lists', { page, project_id,haserr,online,search,cate_id,start_time,end_time })
			var data2 = []
			res.list.data.forEach(x => {
				let obj = {
					code: x.code,
					ccode: x.category.code,
					cname: x.category.name,
					sn: x.sn,
					nickname: x.nickname,
					mobile: x.mobile,
					address1: x.province + x.city + x.region + x.address,
					zx: x.online==1?'在线':'离线',
					gz: x.haserr==1?'正常':'故障',
					remark: x.remark,
					create_time: x.create_time,
					id: x.id,
					category_id: x.category_id,
					category: x.category,
					province: x.province,
					city: x.city,
					region: x.region,
					address: x.address,
					longitude: x.longitude,
					latitude: x.latitude,
				}
				data2.push(obj)
			})
			_this.data1 = data2
			_this.total_pro = res.list.total - 0
			_this.per_pro = res.list.per_page -0
		},
		
		changepage1 (page) {
			this.eqlistData(page)
		},
		
		// 获取类别
		async modellist () {
			let _this = this
			const res = await _this.get('device.category/lists')
			_this.modeldata = res.list.data
		},

		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		changedate (e) {
			// console.log(e)
			this.start_time = e[0]
			this.end_time = e[1]
		},
	}
}
</script>

<style lang="less" >
.map-box >div{
	height: 790rem;
	
}

.map-list {
	width: 280rem;
	background: url(../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;

}

// 标签页
.tabs-title {
	li {
		width: 120rem;
		height: 52rem;
		line-height: 52rem;
		border: 1px solid #48CBCC;
		color: #fff;
		opacity: 0.6;
	}
	li.active {
		background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
		opacity: 1;
	}
}
.map-pro {
	ul {
		color: #fff;
		opacity: .6;
		padding-left: 30rem;
	}
	li {
		margin-top: 10rem;
		cursor: pointer;
	}
	.ivu-collapse-simple,.ivu-collapse,.ivu-collapse>.ivu-collapse-item,.ivu-collapse-content {
		background-color: rgba(0,0,0,0);
		border: none;
	}
	.ivu-collapse-header {
		font-size: 16rem;
		color: #fff;
		opacity: .6;
	}
	.ivu-collapse-item-active .ivu-collapse-header{
		font-size: 16rem;
		color: #fff;
		opacity: 1;
	}
	
}
.map-detail {
	height: 100%;
}

.sample {
  width: 43rem;
	height: 63rem;
}
.sample.active {
  // background: rgba(0,0,0,0.75);
  // color: #fff;
}

.mapyt {
	.pro-name {
		width: 100%;
		height: 52rem;
		line-height: 52rem;
		background: rgba(1, 169, 170, 0.1);
	}
	.active::before {
		content: '';
		position: absolute;
		width: 6rem;
		height: 6rem;
		border-radius: 50%;
		background-color: #48CBCC;
		top: 50%;
		transform: translateY(-50%);
		left: 10rem;
	}
	.flex-box.map .bold{
			background: rgba(1, 169, 170, 0.1)!important;
		}
		.ivu-table th {
			height: 60px!important;
			background-color: rgba(0, 0,0, .1)!important;
			border-bottom: none;
			color: #fff;
			font-size: 14rem;
			font-weight: bold;
		}
		.ivu-table td {
			height: 60rem;
			background: rgba(1, 169, 170, 0.2);
			border-bottom: 5px solid #111727;
			color: #fff;
			font-size: 14rem;
		}
		.ivu-table {
			background-color: rgba(0,0,0,0);
		}
		.ivu-table:before {
			background-color: rgba(0, 0,0, .1)!important;
		}
		.table-info-row {
			td {
				border-bottom: none!important;
				background: rgba(1, 169, 170, 0.24)!important;
			}
		}
		.table-error-row {
			td {
				border-bottom: none!important;
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}
}

.maplist-title {
	height: 55rem;
	width: 100%;
	background: rgba(1, 169, 170, 0.1);
}
	.state-color {
		color: #8FF38E;
		.dight {
			width: 6rem;
			height: 6rem;
			background: #8FF38E;
			border-radius: 3rem;
			margin-right: 10rem;
		}
	}
	.state-color.bad {
		color: #F56E6E;
		.dight {
			width: 6rem;
			height: 6rem;
			background: #F56E6E;
			border-radius: 3rem;
			margin-right: 10rem;
		}
	}
</style>
