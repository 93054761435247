<template>
  <div class="flex eq-detail flex-x-s flex-y-s relative" >
	   <div class="device_title">{{device_title}}</div>
		<!-- 第一列 -->
		<ul>
			<!-- 项目简介 -->
			<li class="eq-detail-info pb-40">
			<div class="submit-btn submit-btntt f-18 c-ff t-c shou" @click="submitEq" v-if="show_operate==1 || device.category_id==11 ">运营报表</div>
			
			<div class="title f-20 c-ff bold plr-20 onelist" v-if="device.category">{{device.category.name}}</div>
				<div class="plr-15">
					<ul class="flex flex-x-s mt-15">
				        <li @click="changezt" class="state-icon shou success f-14 c-ff mr-10" v-if="device.power==1">开机</li>
						<li @click="changezt" class="state-icon shou warning f-14 c-ff mr-10" v-else>关机</li>
						<!-- @click="changezt" -->
						<li  class="state-icon shou success f-14 c-ff mr-10" v-if="device.online==1">在线</li>
						<li  class="state-icon shou warning f-14 c-ff mr-10" v-else>离线</li>
						<li class="state-icon success f-14 c-ff mr-10" v-if="device.haserr==0">正常</li>
						<li class="state-icon warning f-14 c-ff" v-else>故障</li>
					</ul>
					<!-- 链接用户 -->
					<div class="mt-20">
						<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">链接用户</p></div>
						<ul class="" style="padding: 0 27rem;">
							<li class="flex flex-x-s f-14 mt-10" v-for="(item,index) in list_user" :key="item.user_id">
								<p class="mr-20 c-ff">连接用户{{index+1}}：</p>
								<div class="c-48c"><span class="pr-15">{{item.user.nickname}}</span><span>{{item.user.mobile}}</span></div>
							</li>
						</ul>
					</div>
					<!-- 详细信息 -->
						<div class="mt-20">
						<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">详细信息 (点击机组查看详情)</p></div>
						<ul class="flex flex-x-s" style="padding:7px 0 0 27rem;" v-if="device.params">
							<li class="f-14 mt-5 mr-5 jizu-icon plr-10 f-14 c-ff shou" @click="basicsinfo(item.sn, item.id)" v-for="item in device.params.list_pgroup">{{item.name}}</li>
						</ul>
					</div>
				</div>
			</li>
			<!-- 项目模型 -->
			<li class="eq-detail-mx mt-20">
				<div class="title flex  plr-20 ">
					<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">项目模型</p></div>
					<img class="magnify" src="../static/fangda-icon.png" alt="">
				</div>
				<div class="mx-img"><img :src="device.model_img" /></div>
			</li>
		</ul>
		<!-- 第二列 -->
		<ul class="ml-15 flex-box">
			<!-- 视频监控 -->
			<li class="eq-detail-jk">
				<div class="title flex  plr-20 ">
					<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">视频监控</p></div>
					<div class="new_detail" @click="getNewsDetail(device.vedio_url)">详情</div>
					<!-- <img class="magnify" src="../static/fangda-icon.png" alt=""> -->
				</div>
				<div class="mx-img"><img :src="device.vedio_img"/></div>
			</li>
			<!-- 视频监控 -->
			<li class="eq-detail-jk mt-10">
				<div class="title flex  plr-20 ">
					<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">故障信息</p></div>
					<!-- <img class="magnify" src="../static/fangda-icon.png" alt=""> -->
					<div style="">
						<li class="table-btn flex flex-c plr-20" style="width: 100rem;" @click="daochu"><span class="iconfont icondownload mr-5 f-12 c-ff"></span><span class="f-14 c-ff">导出</span></li>
					</div>
				</div>
				
				
				<Table ref="table1" :columns="columns1" :row-class-name="rowClassName" :data="list_err" class="" disabled-hover  class="new_table" :height="height">
					<template slot="update_time" slot-scope="{row}">
						<!-- <div>{{row.status == 0? '--':row.update_time}}</div> -->
						<div>{{row.status == 0? '--':row.update_time}}</div>
					</template>
				</Table>
				
			</li>
		</ul>
		<!-- 第三列 -->
		<ul class="ml-15 flex-box">
			<!-- 只能管控 -->
			<li class="eq-zngk eq-zngk_new">
				<div class="title flex  plr-20 ">
					<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">智能管控</p></div>
				</div>
				<div class="plr-15">
					<!-- 基本状态 -->
					<ul class=" pb-20 shou">
						<li class="mt-5 c-ff flex">
							<div class="day f-16 flex-c plr-40 pt-10"><span>正常运行</span><span class="f-30 bold mlr-10">{{device.run_days}}</span><span>天</span></div>
							<div class="flex-box ml-5 baojin flex-c" @click="waing_show = true">
								<div class="icon-img"><img src="../static/baojin-icon.png" alt="" /></div>
								<div class="f-14 c-ff ml-15 mt-10"><p>设备预警</p><p class="f-30  bold">{{waring_num}}</p></div>
							</div>
						</li>
						<li class="mt-5 flex">
							<div v-if="device.status_info"  class=" plr-10 baojin ms flex-c"  @click="changework(device.status_info.work_mode)">
								<div class="icon-img" v-if="device.status_info.work_mode==1"><img src="../static/baojin-icons.png" alt="" /></div>
								<div class="icon-img" v-if="device.status_info.work_mode==2"><img src="../static/hot_ic.png" alt="" /></div>
								<div  class="f-14 c-ff ml-10 "><p>工作模式</p><p class="f-14 bold mt-5" v-if="device.status_info ">{{setting_param.work_mode[device.status_info.work_mode]}}</p></div>
							</div>
                            <!-- <div class="flex-box baojin ms flex-c  ml-5"  @click="changewind(device.status_info.wind_mode)">
								<div class="icon-img"><img src="../static/fnegsu-icon.png" alt="" /></div>
								<div class="f-14 c-ff ml-15 mt-10"><p>风速模式</p><p class="f-20  bold" v-if="device.status_info">{{setting_param.wind_mode[device.status_info.wind_mode]}}</p></div>
							</div> -->
							<div v-if="device.status_info" class="flex-box baojin ms flex-c  ml-5" @click="changeenergy(device.status_info.device_time)">
								<div class="icon-img"><img src="../static/tims.png" alt="" /></div>
								<div class="f-14 c-ff ml-15 mt-10"><p>设备时间</p><p class="f-20  bold" v-if="device.status_info">{{device.status_info.device_time?device.status_info.device_time: '00:00'}}</p></div>
							</div>
						</li>
						<li class="mt-5 flex">
							<div class="flex-box wd baojin flex-c" @click="changewd()"  v-if="device.status_info">
								<div class="icon-img"><img src="../static/wendu-icon.png" alt="" /></div>
								
								<div  class="f-14 c-ff ml-15 mt-10"><p>{{setting_param.work_mode[device.status_info.work_mode]}}温度</p><p class="f-30  bold">{{device.status_info.work_mode==1?device.status_info.cold_temp:device.status_info.heat_temp}}<span class="f-16">℃</span></p></div>
							</div>
							<div class="flex-box ml-5 tq baojin flex-c">
								<div class="icon-img"><img v-if="icon" :src="require('../static/color-64/' + icon + '.png')" alt="" /></div>
								<div class="f-14 c-ff ml-15 mt-10"><p>当地天气</p><p class="f-30  bold">{{dew}} <span class="f-16">℃</span></p></div>
							</div>
						</li>
						<!-- 设备定时 -->
						<li class="mt-20">
							<p class="f-14 c-ff">设备定时：</p>
							<ul class="flex flex-x-s">
								<li class="mt-5 mr-8 eq-detail-ds flex shou" v-for="(item,index) in list_timetask" :key="index" @click="showtimetask(item.id,item.open_time+'-'+item.close_time,item.status)">
									<div class="f-14 c-48c plr-8">时段{{index+1}}:</div>
									<div class="flex-box time f-14 c-ff plr-5">{{item.open_time}}—{{item.close_time}}</div>
								</li>

							</ul>
						</li>
						<!-- 设备定时 -->
						<li class="mt-20">
							<p class="f-14 c-ff">定时模式：</p>
							<ul class="flex flex-x-s">
								<li class="mt-5 mr-8 eq-detail-ds flex shou" @click="changeDs(item)" v-for="(item,index) in list_optiontask" :key="index">
									<div class="f-14 c-48c plr-8">时段{{index+1}}:</div>
									<div class="flex-box time f-14 c-ff plr-5">{{item.time_set}}</div>
								</li>
								<li class="iconfont iconadd1 c-48c ml-10" @click="changeDs(0)"></li>
							</ul>
						</li>
						<!-- 公共信息： -->
						<li class="mt-20" v-if="0">
							<p class="f-14 c-ff">公共信息：</p>
							<ul class="flex flex-x-s">
								<li class="mt-10  eq-detail-gg f-14 c-ff" v-for="item in 6">水流量：-3</li>
							</ul>
						</li>
						<!-- 其他设置 -->
						<li class="mt-20">
							<p class="f-14 c-ff">其他设置：</p>
							<div style="height:74px;overflow:auto">
								<ul class="flex flex-x-s" v-if="device.params" >
									<li class="mt-5 mr-8 eq-detail-ds flex shou" @click="changewind(item)" v-for="(item,index) in device.params.list_backcontrol" :key="index">
										<div class="f-14 c-48c plr-8">{{item.desc}}:</div>
										<div class="flex-box time f-14 c-ff plr-5">{{item.value}}</div>
									</li>
							   </ul>
							</div>
						</li>
					</ul>
				</div>
			</li>
			
			<!-- 只能管控 -->
			<li class="eq-zngk mt-10">
				<div class="title flex  plr-20 ">
					<div class="flex flex-x-s"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">历史曲线</p></div>
				
					<div class="flex">
					    <img style="width:22rem;height:22rem;margin-right:10rem" src="../static/dao.png" alt="" @click="exports">		
						<img class="magnify" src="../static/fangda-icon.png" alt="" @click="fullPage">
					</div>
					
				</div>
				<ul class="flex flex-x-s plr-20">
					<li class="mr-20">
						<Select v-model="group_name" class="select_new" v-if="device.params" @on-change="equipQx">
							<Option  :value="item.name"  v-for="item in device.params.list_pgroup" >{{item.name}}</Option>
						</Select>
					</li>
					<li class=" flex-box mr-20">
						<Select v-model="sc_key" class="w100" placeholder="在线状态"  @on-change="equipQx1">
								<Option  :value="item" v-for="(item,index) in canshow" :key="index">{{item}}</Option>
						</Select>
					</li>
					<li class=" flex-box">
						<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择日期" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
					</li>
				</ul>
				
				<div id="chart2" class="area-box1 plr-20 "></div>
			</li>
		</ul>
		<!-- 弹窗 -->
		<Modal
				:footer-hide="true"
				v-model="is_show"
				:closable="false"
				width="470"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-modal eq-wkq">
				<!-- title头部 -->
					<ul class="flex pt-20 flex-y-s">
						<li class="title-text c-ff f-20">设备定时</li>
						<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('is_show')"></li>
					</ul>
					<ul class="plr-30">
						<li class="mt-30 flex">
							<span class="f-16 c-ff t-l" style="width: 90rem;">选择时间：</span>
							<div class="flex-box flex">
								<TimePicker type="timerange" v-model="time_range" class="w100 " placement="bottom-end" placeholder="请选择时间" format="HH:mm"></TimePicker>
							</div>
						</li>
						<li class="mt-30 flex">
							<div class="flex-box flex">
								<span class="f-16 c-ff t-l" style="width: 90rem;">工作模式：</span>
								<Select v-model="status" class="flex-box" placeholder="请选择工作模式">
										<Option  :value="1" >开机</Option>
										<Option  :value="0" >关机</Option>
								</Select>
							</div>
						</li>
			            <li class="mt-30 flex">
			            	<div class="flex-box flex">
			            		<span class="f-16 c-ff t-l" style="width: 90rem;">是否启用：</span>
			            		<Select v-model="task_status" class="flex-box" placeholder="请选择是否启用">
			            				<Option  :value="1" >启用</Option>
			            				<Option  :value="0" >禁用</Option>
			            		</Select>
			            	</div>
			            </li>
						<li class="flex flex-x-s mt-50" style="padding-left: 90rem;">
							<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show')">取消</div>
							<div class="queding f-18 c-ff t-c shou" @click="submittimetask">提交</div>
						</li>
					</ul>
			</div>
		</Modal>
<!-- 		<Modal
				:footer-hide="true"
				v-model="is_show_fs"
				:closable="false"
				width="471"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_fs')"></li>
				</ul>
				<div class="flex-box flex" style="padding-top: 50rem;">
					<span class="f-16 c-ff t-l" style="width: 90rem;">风速模式：</span>
					<Select v-model="wind" class="flex-box" placeholder="请选择风速模式">
							<Option  :value="index" v-for="item,index in setting_param.wind_mode" :key="index">{{item}}</Option>
					</Select>
				</div>
				<li class="flex flex-c mt-50" >
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_fs')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitwind">确认</div>
				</li>
			</div>
		</Modal> -->
		<Modal
				:footer-hide="true"
				v-model="is_show_gz"
				:closable="false"
				width="471"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_gz')"></li>
				</ul>
				<div class="flex-box flex" style="padding-top: 50rem;">
					<span class="f-16 c-ff t-l" style="width: 90rem;">工作模式：</span>
					<Select v-model="work" class="flex-box" placeholder="请选择工作模式">
							<Option  :value="index" v-for="(item,index) in setting_param.work_mode" :key="index">{{item}}</Option>
					</Select>
				</div>
				<li class="flex flex-c mt-50" >
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_gz')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitwork">确认</div>
				</li>
			</div>
		</Modal>
		<Modal
				:footer-hide="true"
				v-model="is_show_wd"
				:closable="false"
				width="471"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_wd')"></li>
				</ul>
				<div class="flex-box flex" style="padding-top: 50rem;" v-if="device.status_info">
					<span class="f-16 c-ff t-l" style="width: 90rem;">{{device.status_info.work_mode==2?'制热温度':'制冷温度'}}：</span>
					<Input v-model="work_temp" type="number" class="form-ipt flex-box" placeholder="请填温度">
					</Input>
				</div>
				<li class="flex flex-c mt-50" >
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_wd')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitwd">确认</div>
				</li>
			</div>
		</Modal>
		<!-- 下面是定时模式 -->
		<!-- 弹窗 -->
		<Modal
				:footer-hide="true"
				v-model="is_show_ds"
				:closable="false"
				width="470"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-modal eq-wkq">
				<!-- title头部 -->
					<ul class="flex pt-20 flex-y-s">
						<li class="title-text c-ff f-20">定时模式</li>
						<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('is_show_ds')"></li>
					</ul>
					<ul class="plr-30">
						<li class="mt-30 flex">
							<span class="f-16 c-ff t-l" style="width: 90rem;">选择时间：</span>
							<div class="flex-box flex">
								<TimePicker type="time" v-model="time_set" class="w100 " placement="bottom-end" placeholder="请选择时间" format="HH:mm"></TimePicker>
							</div>
						</li>
						<li class="mt-10 flex">
							<div class="flex-box flex" >
								<span class="f-16 c-ff t-l" style="width: 90rem;">工作模式：</span>
								<Select v-model="options.work_mode" class="flex-box" placeholder="请选择工作模式">
										<Option  :value="index" v-for="item,index in setting_param.work_mode" :key="index">{{item}}</Option>
								</Select>
							</div>
						</li>
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 90rem;">工作温度：</span>
							<div class="flex-box flex">
								<Input v-model="options.work_temp" type="number" class="form-ipt flex-box" placeholder="请填工作温度">
								</Input>
							</div>
						</li>
						<li class="mt-10 flex">
							<div class="flex-box flex">
								<span class="f-16 c-ff t-l" style="width: 90rem;">是否启用：</span>
								<Select v-model="status_set" class="flex-box" placeholder="请选择是否开关">
										<Option  :value="1" >是</Option>
										<Option  :value="0" >否</Option>
								</Select>
							</div>
						</li>
			
						<li class="flex flex-x-s mt-50" style="padding-left: 90rem;">
							<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_ds')">取消</div>
							<div class="queding f-18 c-ff t-c shou" @click="submitDsms">提交</div>
						</li>
					</ul>
			</div>
		</Modal>
		<Modal
				:footer-hide="true"
				v-model="is_show_zt"
				:closable="false"
				width="471"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_zt')"></li>
				</ul>
				<div class="flex-box flex" style="padding-top: 50rem;">
					<span class="f-16 c-ff t-l" style="width: 90rem;">工作状态：</span>
					<Select v-model="power" class="flex-box" placeholder="请选择风速模式">
							<Option  :value="1" >开机</Option>
							<Option  :value="0" >关机</Option>
					</Select>
				</div>
				<li class="flex flex-c mt-50" >
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_zt')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitzt">确认</div>
				</li>
			</div>
		</Modal>
		<!-- 这是其他设置 -->
		<Modal
				:footer-hide="true"
				v-model="is_show_qt"
				:closable="false"
				width="471"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-jn eq-jn plr-20">
				<ul class="flex pt-20 flex-y-s flex-x-e">
					<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_qt')"></li>
				</ul>
				<div class="flex-box flex" style="padding-top: 50rem;" >
					<span class="f-16 c-ff  mr-20" >{{qita.desc}}</span>
					<Input v-model="qita.value" @on-keyup="changeqita" type="number" class="form-ipt flex-box" placeholder="请填写">
					</Input>
				</div>
				<li class="flex flex-c mt-50" >
					<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_qt')">取消</div>
					<div class="queding f-18 c-ff t-c shou" @click="submitwind">确认</div>
				</li>
			</div>
		</Modal>
		<!-- 弹窗 -->
		<Modal
			:footer-hide="true"
			v-model="is_show_jz"
			:closable="false"
			width="710"
			:mask-closable="false"
		>
			<span slot="title"></span>
			<div class="model-pop-jc">
				<ul class="flex pt-20 flex-y-s">
					<li class="title-text c-ff f-20">信息展示</li>
					<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('is_show_jz')"></li>
				</ul>
				<ul class=" flex plr-50 ptb-30">
					<li class="info f-14 c-ff mt-10" v-for="(item,key,index) in basics" :key="index"><span class="mr-10">{{key}}:</span><span class="c-48c">{{item}}</span></li>
				</ul>
			</div>
			
		</Modal>
		<!-- 设备时间 -->
		<Modal
				:footer-hide="true"
				v-model="is_show_sj"
				:closable="false"
				width="470"
				:mask-closable="false"
			>
			<span slot="title"></span>
			<div class="model-pop-modal eq-wkq">
				<!-- title头部 -->
					<ul class="flex pt-20 flex-y-s">
						<li class="title-text c-ff f-20">设备时间</li>
						<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('is_show_sj')"></li>
					</ul>
					<ul class="plr-30">
						<li class="mt-30 flex">
							<span class="f-16 c-ff t-l" style="width: 90rem;">选择时间：</span>
							<div class="flex-box flex">
								<TimePicker type="time" v-model="device_time" class="w100 " placement="bottom-end" placeholder="请选择时间" format="HH:mm"></TimePicker>
							</div>
						</li>
			
						<li class="flex flex-x-s mt-50" style="padding-left: 90rem;">
							<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_sj')">取消</div>
							<div class="queding f-18 c-ff t-c shou" @click="submitsjms">提交</div>
						</li>
					</ul>
			</div>
		</Modal>

		<!-- 新增弹出 -->
		<Modal
				:footer-hide="true"
				v-model="quan_show"
				:closable="false"
				width="1200"
				
				:mask-closable="true"
			>
		   <div class="erc-modal-content quaian">
			   <li class="iconfont iconclose1 c-48c pr-20 shou quan1" @click="changeModal('quan_show')"></li>
			   <div class="flex flex-x-s" style="margin-bottom:10rem"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">历史曲线</p></div>
			  
			   	<ul class="flex flex-x-s plr-20">
					<li class="mr-20">
						<Select v-model="group_name" style="width:100px" v-if="device.params" @on-change="equipQx">
							<Option  :value="item.name"  v-for="item in device.params.list_pgroup" >{{item.name}}</Option>
						</Select>
					</li>
					<li class="  mr-20" >
						<Select v-model="sc_key" style="width:200px" placeholder="在线状态"  @on-change="equipQx1">
								<Option  :value="item" v-for="(item,index) in canshow" :key="index">{{item}}</Option>
						</Select>
					</li>
					<li   style="width:300px">
						<DatePicker   type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择日期" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
					</li>
				</ul>
				<div id="chart3" class="area-box plr-20 "></div>
		   </div>
		</Modal>
        <!-- 预警弹出 -->
			<Modal
				:footer-hide="true"
				v-model="waing_show"
				:closable="false"
				width="600"
				:mask-closable="true"
			>
		   <div class="erc-modal-content">
			   <li class="iconfont iconclose1 c-48c pr-20 shou quan1" @click="changeModal('waing_show')"></li>
			   <div class="flex flex-x-s" style="margin-bottom:10rem"><img src="../static/title-icon.png" class="title-icon" alt=""><p class="f-16 c-48c bold ml-10">预警信息</p></div>
			   <div style="max-height:400px;overflow:auto">
				    <Alert show-icon v-for="(item,index) in waring">{{item}}</Alert>
			   </div>
		   </div>
		</Modal>
		
		<!-- 运营报表 -->
			<Modal
				:footer-hide="true"
				v-model="yun_show"
				:closable="false"
				width="600"
				:mask-closable="true"
			>
			<span slot="title"></span>
			<div class="model-pop-modal eq-wkq">
				<!-- title头部 -->
					<ul class="flex pt-20 flex-y-s">
						<li class="title-text c-ff f-20">运营报表</li>
						<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('yun_show')"></li>
					</ul>
					<ul class="plr-30">
						<li class="mt-30 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">开始时间：</span>
							<div class="flex-box flex">
								<DatePicker type="datetime" v-model="bosForm.start_date" @on-change="getStartTime"  placeholder="请选择开始时间"
									class="w100 " />

							</div>
						</li>
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">结束时间：</span>
							<div class="flex-box flex">
								<DatePicker type="datetime" v-model="bosForm.end_date" @on-change="getEndTime"  placeholder="请选择结束时间"
									class="w100 " />
								
							</div>
						</li>
						
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">峰段电价：</span>
							<div class="flex-box flex">
								<Input v-model="bosForm.max_price" type="number" class="form-ipt flex-box" placeholder="请填写峰段电价">
								</Input>
							</div>
						</li>
					    <li class="mt-10 flex">
					    	<span class="f-16 c-ff t-l" style="width: 100rem;">平段电价：</span>
					    	<div class="flex-box flex">
					    		<Input v-model="bosForm.level_price" type="number" class="form-ipt flex-box" placeholder="请填写平段电价">
					    		</Input>
					    	</div>
					    </li>
			            <li class="mt-10 flex">
			            	<span class="f-16 c-ff t-l" style="width: 100rem;">谷段电价：</span>
			            	<div class="flex-box flex">
			            		<Input v-model="bosForm.min_price" type="number" class="form-ipt flex-box" placeholder="请填写谷段电价">
			            		</Input>
			            	</div>
			            </li>
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">蒸汽热值：</span>
							<div class="flex-box flex">
								<Input v-model="bosForm.zhenqi_val" type="number" class="form-ipt flex-box" placeholder="请填写蒸汽热值">
								</Input>
							</div>
						</li>
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">90C冷凝水热值：</span>
							<div class="flex-box flex">
								<Input v-model="bosForm.ln_val" type="number" class="form-ipt flex-box" placeholder="请填写90C冷凝水热值">
								</Input>
							</div>
						</li>
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">天然气价格：</span>
							<div class="flex-box flex">
								<Input v-model="bosForm.gas_price" type="number" class="form-ipt flex-box" placeholder="请填写天然气价格">
								</Input>
							</div>
						</li>
						<li class="mt-10 flex">
							<span class="f-16 c-ff t-l" style="width: 100rem;">蒸汽价格：</span>
							<div class="flex-box flex">
								<Input v-model="bosForm.steam_price" type="number" class="form-ipt flex-box" placeholder="请填写蒸汽价格">
								</Input>
							</div>
						</li>
						<li class="flex flex-x-s mt-50" style="padding-left: 100rem;">
							<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('yun_show')">取消</div>
							<div class="queding f-18 c-ff t-c shou" @click="handleDao">导出</div>
						</li>
					</ul>
			</div>
		 
		</Modal>
	
		
	</div>
</template>

<script>
import { EleResize} from '../../public/a.js'
export default {
  data () {
    return {
		show_operate:0,
		bosForm:{
			start_date:'',
			end_date:'',
			max_price:'',
			level_price:'',
			min_price:'',
			zhenqi_val:'',
			ln_val:'',
			gas_price:'',
			steam_price:'',
			device_id:'',
		},
		yun_show:false,
		waring_num:0,
		waing_show:false,
		windowWidth: document.documentElement.clientWidth,
		height:document.documentElement.clientWidth>1024?268:110,
			columns1: [
					{
							title: '故障说明',
							key: 'description'
					},
					{
							title: '响应等级',
							key: 'level_txt'
					},
					{
							title: '故障时间',
							key: 'create_time'
					},
					{
							title: '恢复时间',
							slot: 'update_time',
							key: 'update_time'
					},
			],
			is_show_sj: false, // 节能模式
			device_time: '',
			quan_show:false,
			is_show_qt: false, // 风速模式
			is_show_gz: false,
			is_show_ds: false,
			is_show_wd: false,
			is_show_zt: false,
			work: 0,
			wind: 0, // 风速模式
			data1: [],
			device_id: 0,
			device: {
			
				
			},
			list_err: [],
			list_optiontask: [],
			list_timetask: [],
			list_user: [],
			num_errs: 0,
			is_show: false,
			status: 0, // 定时状态
			time_range: '', // 定时时间
			task_id: 0, // 设备定时任务id
			dew: 0, // 当地天气
			icon: 0, // 当地天气图标
			setting_param: {}, // 工作模式
			options: {},
			status_set: 1,
			time_set: '',
			task_id_set: '',
			work_temp: 0, // 工作温度
			power: 0, // 下面是其他设置的一些内容
			qita: {},
			is_show_jz: false,
			basics: {}, // 下面是实时曲线
			group_name: '公用',
			time: [],
			date_range: '',
			sc_key: '',
			canshow: [],
			waring:[],
			token: localStorage.getItem('token'),
			device_title: '',
			task_status:0
		}
  },
  //获取屏幕款第
 watch: {
	windowWidth(val) {
		let that = this;
		if(val<=1024){
			that.height = 100
		}
		console.log("实时屏幕高度：",val, that.windowWidth  );
	}

  },
  created () {
	  let that = this;
		that.device_id = that.$route.query.id
		that.tqData()
	},

	mounted () {
		if(this.token){
            this.equipDetail()
			this.equipQx();
			this.getBao()
			this.device_title = localStorage.getItem('device_title')||'详情'
		}
		  
	},
	
	
  methods: {
	   getStartTime(startTime) {
		   this.bosForm.start_date = startTime
	     
	  },
	  
	  getEndTime(endTime){
		  this.bosForm.end_date = endTime
	  },
		// 导出数据--一页数据
		daochu () {
			this.$refs.table1.exportCsv({
					filename: '故障信息',
					columns: this.columns1.filter((col, index) => true),
					data: this.list_err.map(item=>{
						item.create_time = "\t"+item.create_time;
						item.update_time = "\t"+item.update_time;
						if (item.status == 0) {
							item.update_time = '--'
						}
						return item
					})
			})
		},
		
		 submitEq(){
			let that = this;
			that.yun_show = true;
			// const res = await that.post('device/operate_config', {} );
			// that.bosForm = res.config
			// console.log('fff',res)
		},
		
		async getBao(){
			let that = this;
			// that.yun_show = true;
			const res = await that.post('device/operate_config', {} );
			that.bosForm = res.config
			console.log('fff',res)
		},
		
		async handleDao(){
			let _this = this;
			_this.bosForm.device_id = _this.device_id
			
			const res = await _this.post('device/device_operate', _this.bosForm );
			window.open(res.file_url)
			// window.location.href  = 
			_this.changeModal('yun_show')
			
		},
		
		
		
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		changeModal (type) {
			this[type] = !this[type]
		},
		bindsubmit () {
			
		},
		// 修改设备定时
		showtimetask (task_id,time_range,status) {
			// console.log(task_id,time_range)
			let _this = this
			_this.task_id = task_id
			_this.time_range = time_range.split('-')
			_this.status = status
			_this.changeModal('is_show')
		},
		async submittimetask () {
			let _this = this,
					task_id = _this.task_id,
					status = _this.status,
					task_status = _this.task_status,
					device_id = _this.device_id,
					time_range = _this.time_range.join('-');
					
			const res = await _this.post('device.operate/timetask_power', { task_id, status, device_id, time_range,task_status} )
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show')
			_this.equipDetail()
		},
		// 修改节能模式状态
		changeenergy (device_time) {
		
			this.device_time = device_time
			this.changeModal('is_show_sj')
		},
		async submitsjms () {
			let _this = this
			var opt = {device_time: _this.device_time}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_sj')
			_this.equipDetail()
		},
		// 修改风速状态
		changewind (wind) {
			this.qita.desc = wind.desc
			this.qita.name = wind.name
			this.qita.range = wind.range
			this.qita.value = wind.value
			this.changeModal('is_show_qt')
		},
		changeqita () {
			let _this = this,
					max = _this.qita.range[1],
					min = _this.qita.range[0];
			if (this.qita.value  < min) {
				return _this.util.showMsg('warning', '输入值低于最小参数' + min, 1.5, false)
			}
			if (this.qita.value  > max) {
				return _this.util.showMsg('warning', '输入值高于最大参数' + max, 1.5, false)
			}
		},
		async submitwind () {
			let _this =this
			let opt = {[_this.qita.name]: this.qita.value}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_qt')
			_this.equipDetail()
		},
		// 修改工作模式
		changework (work) {
			this.work = work + ''
			this.changeModal('is_show_gz')
		},
		async submitwork () {
			let _this =this
			let opt = {work_mode: this.work}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_gz')
			_this.equipDetail()
		},
		// 修改定时模式
		changeDs (item) {
			if (item) {
				this.options = JSON.parse(item.options)
				this.status_set = item.status
				this.time_set = item.time_set
				this.task_id_set = item.id
				this.changeModal('is_show_ds')
			} else {
				this.task_id_set = ''
				this.options = {}
				this.status_set = 1
				this.time_set = ''
				this.changeModal('is_show_ds')
			}
		},
		async submitDsms () {
			let _this = this,
					opt = JSON.stringify(_this.options),
					status = _this.status_set,
					time_set = _this.time_set,
					task_id = _this.task_id_set;
			const res = await _this.post('device.operate/timetask_option', {
				device_id: _this.device_id,opt,status,time_set,task_id
			})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_ds')
			_this.equipDetail()
		},
		// 修改温度
		changewd () {
			this.work_temp = this.device.status_info.work_mode==2?this.device.status_info.heat_temp:this.device.status_info.cold_temp
			this.changeModal('is_show_wd')
		},
		async submitwd () {
			let _this =this
			if (_this.device.status_info.work_mode==2) {
				var opt = {heat_temp: this.work_temp}
			} else {
				var opt = {cold_temp: this.work_temp}
			}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_wd')
			_this.equipDetail()
		},
		// 修改开关机状态
		changezt () {
			this.power = this.device.power
			this.changeModal('is_show_zt')
		},
		async submitzt () {
			let _this =this
			let opt = {power: this.power}
			const res = await _this.post('device.operate/control', { device_id: _this.device_id, opt: JSON.stringify(opt)})
			_this.util.showMsg('success', '修改成功', 1.5, false)
			_this.changeModal('is_show_zt')
			_this.equipDetail()
		},
		
		// 设备详情
		async equipDetail () {
			let _this = this,
					device_id = _this.device_id;
					_this.util.spinShow()
			const res = await _this.get('device/detail', {device_id,is_view:1});
		
			_this.show_operate = res.show_operate
			this.device_title = res.device.device_title
			_this.list_user = res.list_user;
		
			_this.device = res.device;
			// console.log('device',res.device);
			 _this.list_err = res.list_err;
			 
			 
			 _this.list_optiontask = res.list_optiontask;
			 _this.list_timetask = res.list_timetask;
			_this.waring_num= res.waring_num;
			_this.waring = res.waring;
			
			 _this.num_errs = res.num_errs;
			_this.setting_param = res.setting_param;
			_this.$Spin.hide()
		},
		
		showbasics () {
			
		},
		// 获取基础信息
		async basicsinfo (sn,pgid) {
			let _this = this
			const res = await _this.post('device/get_dmonvalues', {sn,pgid})
			_this.basics = res
			_this.changeModal('is_show_jz')
		},
		
		
		// 历史曲线
		async equipQx () {
			let _this = this;
			let {device_id,date_range,group_name} = _this;
			let sc_key = '';
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
			_this.canshow= res.canshow
			_this.sc_key = res.canshow[0];
			this.areaData2(res.data_x,res.data_y);
			this.areaData3(res.data_x,res.data_y)
		},

		// 历史曲线
		async equipQx1 () {
			let _this = this,
					{device_id,date_range,group_name,sc_key} = _this;
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
			this.areaData2(res.data_x,res.data_y)
			this.areaData3(res.data_x,res.data_y)
		},

			// 历史曲线
		async equipQx2 () {
			let _this = this,
					{device_id,date_range,group_name,sc_key} = _this;
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key})
			this.areaData2(res.data_x,res.data_y)
			this.areaData3(res.data_x,res.data_y)
		},

		async exports(){
			let _this = this,
			{device_id,date_range,group_name,sc_key} = _this;
			const res = await _this.get('device/linemap_dtu', {device_id,date_range,group_name,sc_key,is_export:1})
			var tempwindow=window.open('_blank');
            tempwindow.location=res.file_url;
		},
		

		// 获取当前温度
		async tqData () {
			const res = await this.get('basic/weather', {device_id: this.device_id})
			console.log(111111,res);
			this.dew = res.temp
			this.icon = res.icon
		},
		
		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		changedate (e) {
			this.date_range = e[0] + ' - ' +e[1]
			this.equipQx2()
		},
		//详情跳转
		  getNewsDetail(url) {
			  var tempwindow=window.open('_blank');
              tempwindow.location=url;
           
		  },
		  // 分别跳转不同详情
		  fullPage () {
		    localStorage.setItem('device_id_s',this.device_id);
		    localStorage.setItem('device_title','历史曲线')
		  	let routeUrl = '';
			routeUrl = this.$router.resolve({path: '/equip/list/curve'})
		  
		  	window.open(window.location.href.split('#')[0] + routeUrl.href);
		  
		  },
		 
		
		areaData2 (xx, yy) {
			let _this = this
			var myChart = this.$echarts.init(document.getElementById("chart2"), 'shine');
			var option = {
						title: {
								text: ''
						},
						tooltip: {
								trigger: 'axis',
								axisPointer: {
										type: 'cross',
										label: {
												backgroundColor: '#6a7985'
										}
								}
						},
						legend: {
								show: false
						},
						grid: {
							left: '12%',
							top: '10%',
							bottom: '20%',
							right: 0,
						},
						xAxis: {
								data: xx,
								axisLine: {
									show: true,
									lineStyle: {
											type: 'solid',
											color:'#2772E9',
											opacity: 0.6
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12
								},
								boundaryGap: ['00%','0%']
						},
		
						yAxis: {
								splitLine: {
									show: true,
									lineStyle: {
											type: 'dashed',
											color:'#2772E9',
											opacity: 0.4
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12,
								}
						},
						series: [
								{
										name: '当日',
										type: 'line',
										stack: '总量',
										smooth: true,
										lineStyle: {
												width: 1
										},
										showSymbol: false,
										areaStyle: {
												opacity: 1,
												color: {
												    type: 'linear',
												    x: 0,
												    y: 0,
												    x2: 0,
												    y2: 1,
												    colorStops: [{
												        offset: 0, color: 'rgba(25,104,255,1)' // 0% 处的颜色
												    }, {
												        offset: 1, color: 'rgba(255,255,255,0)' // 100% 处的颜色
												    }],
												    global: false // 缺省为 false
												}
										},
										emphasis: {
												focus: 'series'
										},
										data: yy
								}
						]
		  };
			myChart.setOption(option, true);
		},
	    areaData3 (xx, yy) {
			let _this = this
			var myChart1 = this.$echarts.init(document.getElementById("chart3"), 'shine');
			var option = {
						title: {
								text: ''
						},
						tooltip: {
								trigger: 'axis',
								axisPointer: {
										type: 'cross',
										label: {
												backgroundColor: '#6a7985'
										}
								}
						},
						legend: {
								show: false
						},
						grid: {
							left: '12%',
							top: '10%',
							bottom: '20%',
							right: 0,
						},
						xAxis: {
								data: xx,
								axisLine: {
									show: true,
									lineStyle: {
											type: 'solid',
											color:'#2772E9',
											opacity: 0.6
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12
								},
								boundaryGap: ['00%','0%']
						},
		
						yAxis: {
								splitLine: {
									show: true,
									lineStyle: {
											type: 'dashed',
											color:'#2772E9',
											opacity: 0.4
									}
								},
								axisTick: {
									show: false
								},
								axisLabel: {
									show: true,
									color: '#ccc',
									fontSize: 12,
								}
						},
						series: [
								{
										name: '当日',
										type: 'line',
										stack: '总量',
										smooth: true,
										lineStyle: {
												width: 1
										},
										showSymbol: false,
										areaStyle: {
												opacity: 1,
												color: {
												    type: 'linear',
												    x: 0,
												    y: 0,
												    x2: 0,
												    y2: 1,
												    colorStops: [{
												        offset: 0, color: 'rgba(25,104,255,1)' // 0% 处的颜色
												    }, {
												        offset: 1, color: 'rgba(255,255,255,0)' // 100% 处的颜色
												    }],
												    global: false // 缺省为 false
												}
										},
										emphasis: {
												focus: 'series'
										},
										data: yy
								}
						]
		  };
		  
			myChart1.setOption(option, true);
			var listener = () => {
			  myChart1.resize()
			}
			EleResize.on(document.getElementById("chart3"), listener)
		},
		
		roamMap(flag) {
		      const that = this;
			  var myChart_sd = this.$echarts.init(document.getElementById("chart3"), 'shine');
		      const currentZoom = myChart_sd.getOption().series[0].zoom // 当前的缩放比例
		      let increaseAmplitude = 1.2 // 点击按钮每次 放大/缩小 比例
		      if (flag === 1) {
		        increaseAmplitude = 0.8
		      }
		     myChart_sd.setOption({
		        series: {
		          zoom: currentZoom * increaseAmplitude
		        }
		      })
		    },
		    add() {
		      this.roamMap(0)
		    },
		    sub() {
		      this.roamMap(1)
		    },
	


	}
}
</script>

<style lang="less" >
	.submit-btntt{position: absolute;right: .3em;top: 4.1em;cursor: pointer;}
	.select_new{width: 100px;}
	.new_table{height: 281px;}
	.relative{
		position: relative;
	}
	.device_title{
		position: absolute;
		left: 32rem;
		top: -60rem;
		color: #fff;
		font-size: 20rem;
		font-weight: bold
		
	}
	.eq-detail {
		.eq-detail-info {
			width: 400rem;
			height: 473rem;
			overflow: auto;
			position: relative;
			border: 1px solid #10BDC4;
			.title {
				height: 66rem;
				line-height: 66rem;
				background: linear-gradient(180deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
			}
		}
		.eq-detail-mx {
			border: 1px solid #10BDC4;
			.title {
				height: 52rem;
				background: rgba(0, 77, 83, 0.7);
			}
			.mx-img {
				width: 400rem;
				height: 309rem;
			}
		}
		.eq-detail-jk {
			max-width: 660rem;
			flex: 1;
			border: 1px solid #10BDC4;
			.title {
				height: 48rem;
				background: rgba(0, 77, 83, 0.7);
			}
			.mx-img {
				width: 100%;
				height: 476rem;
			}
		}
		.eq-zngk {
			// width: 500rem;
			// height: 580rem;
			border: 1px solid rgba(16, 189, 196, 0.4980392156862745);
			background: linear-gradient(1deg, rgba(3, 9, 9, 0.14) 0%, rgba(0, 77, 83, 0.7) 100%);
			.title {
				height: 50rem;
				// background: rgba(0, 77, 83, 0.7);
			}
			.day {
				// width: 312rem;
				height: 80rem;
				background: #3E80EA;
			}
			.baojin {
				height: 80rem;
				background:#FE9E47;
			}
			.baojin.ms {
				background: #6C45FE;
			}
			.baojin.wd {
				background: #37D3D2;
			}
			.baojin.tq {
				background: #36D4A1;
			}
			.icon-img {
				width: 42rem;
				height: 42rem;
			}
		}
		.eq-detail-ds {
			// width: 148rem;
			height: 38rem;
			line-height: 38rem;
			background: rgba(1, 169, 170, 0.2);
			border: 1px solid #10BDC4;
			.time {
				background: rgba(72,203,204,.5);
			}
		}
		.eq-detail-gg {
			width: 33.33%;
		}
		
		.state-icon {
			height: 24rem;
			padding: 0 13rem;
			line-height: 24rem;
		}
		.state-icon.success {
			 background: #5EB95E;
		}
		.state-icon.warning {
			 background: #DD514C;
		}
		.title-icon {
			width: 17rem;
			height: 17rem;
		}
		.jizu-icon {
			width: 80rem;
			line-height: 38rem;
			height: 38rem;
			background: rgba(72,203,204,.5);
		}
		.magnify {
			width: 18rem;
			height: 18rem;
		}
		// .area-box1 {
		// 	width: 80% !important;
		// 	height: 180rem !important;
		// }
		.area-box1 {
			width: 100%;
			height: 180rem;
		}
		.ivu-table th {
			height: 44px!important;
			background-color: rgba(0, 0,0, .1)!important;
			border-bottom: none;
			color: #fff;
			font-size: 14rem;
			font-weight: bold;
		}
		.ivu-table td {
			height: 44rem;
			background: rgba(1, 169, 170, 0.2);
			// border-bottom: 5px solid #111727;
			border-bottom: none;
			color: #fff;
			font-size: 14rem;
		}
		.ivu-table {
			background-color: rgba(0,0,0,0);
		}
		.ivu-table:before {
			background-color: rgba(0, 0,0, .1)!important;
		}
		.table-info-row {
			td {
				border-bottom: none!important;
				background: rgba(1, 169, 170, 0.24)!important;
			}
		}
		.table-error-row {
			td {
				border-bottom: none!important;
				background-color: rgba(0, 0, 0, 0.1) !important;
			}
		}

	}
	
	
	.eq-wkq.model-pop-modal {
		height: auto;
		padding-bottom: 50rem;
	}
	.model-pop-jn {
		width: 471rem;
		height: 311rem;
		background: url(../static/xunwen-pop.png) no-repeat center;
		background-size: 100% 100%;
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	
	}
	// 弹窗
	.model-pop-modal {
		width: 710rem;
		height: 478rem;
		border: 1px solid #01A9AA;
		background: linear-gradient(360deg, #030909 0%, #004D53 100%);
		.title-text {
			width: 226rem;
			height: 43rem;
			line-height: 43rem;
			padding-left: 40rem;
			
			background: url(../static/title-bg.png) no-repeat center;
			background-size: 100% 100%;
		}
		textarea {
		   resize: none;  
		}
		.quxiao {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
		}
		.queding {
			width: 140rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	}
	
	.ivu-modal-body {
		padding: 0;
	}
	.ivu-modal-content {
		background-color: rgba(0,0,0,0);
	}
	.ivu-date-picker {
		border: 1px solid #01A9AA;
	}


// 弹窗
.model-pop-jc {
	width: 710rem;
	// height: 478rem;
	border: 1px solid #01A9AA;
	background: linear-gradient(360deg, #030909 0%, #004D53 100%);
	.info {
		width: 50%;
	}
	.title-text {
		width: 226rem;
		height: 43rem;
		line-height: 43rem;
		padding-left: 40rem;
		
		background: url(../static/title-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
}
.new_detail{
	color: #48CBCC;
	cursor: pointer;
}
.erc-modal-content{
	position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
	pointer-events: auto;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
	padding: 30px;
	position: relative;
}
.erc-modal-content .area-box{
	width: 1200px;
	height:500rem;
	margin-left: -3%
}
.quaian canvas{
	    // left: -122px !important;
}

.erc-modal-content .quan1{
	position: absolute;
	right: 20px;
	font-size: 20px;
	top: 20px
}

@media (max-width:1024px){
		.title-icon{
			position: relative;
			img{
				position: absolute;
				top: -6rem;
			}
		}
		.eq-detail .eq-detail-info .title{height: 50rem;line-height: 50rem;}
		.eq-detail .eq-detail-info{height: 270rem;}
		.eq-detail .eq-detail-mx .mx-img{height: 220rem;}
		.device_title{top: -52rem;}
		.eq-detail .eq-detail-jk .mx-img{height: 300rem;}
		.new_table{height: 100px;overflow: auto;}
		.ivu-table-cell{padding: 4px !important;}
		.eq-detail .eq-zngk_new{height: 285rem;overflow: auto;}
		.select_new{width: 70px;}
		.day {padding-left: 10rem;padding-right: 10rem;}
		.meau-box{height: 630rem !important;}
	}
</style>
