<template>
  <div class="site">
		<top-header></top-header>
		
		<div class=" model-box flex flex-y-s" v-if="0">
			<div class="model-line-bg"><img src="../../static/left-line.png" alt="" /></div>
			
			<!-- 左侧导航 -->
			<div class="ml-15 meau-box relative mr-20">
				 <div class="meau-bg absolute top"><img src="../../static/meal-bg.png" /></div>
				 <div class="meau-bg right absolute"><img src="../../static/meal-bg.png" /></div>
				 <ul class="plr-20 relative" style="z-index: 100;">
					 <li class="mt-15">
							 <div class="meau-detail  f-16 t-c" :class="(hash=='#/site/basic' || hash=='#/site/rule')?'active':''" @click="navigation('/site/basic')">基本设置</div>
							 <ul class=" c-48c  t-r twolevel" :class="(hash=='#/site/basic' || hash=='#/site/rule')?'is-show':''">
										<li class="relative t-l two-level shou " :class="(hash=='#/site/basic')?'active':''" @click="navigation('/site/basic')">基本信息</li>
										<li class="relative t-l two-level shou mt-10" :class="(hash=='#/site/rule')?'active':''" @click="navigation('/site/rule')">菜单规则</li>
							 </ul>
					 </li>
					 <li class="mt-15">
						 <div class="meau-detail  f-16 t-c" :class="(hash=='#/site/role' || hash=='#/site/admin' || hash=='#/site/setadmin')?'active':''" @click="navigation('/site/role')">权限设置</div>
						 <ul class=" c-48c  t-r twolevel" :class="(hash=='#/site/role' || hash=='#/site/admin' || hash=='#/site/setadmin')?'is-show':''">
								<li class="relative t-l two-level shou " :class="(hash=='#/site/role')?'active':''" @click="navigation('/site/role')">角色管理</li>
								<li class="relative t-l two-level shou mt-10" :class="(hash=='#/site/admin' || hash=='#/site/setadmin')?'active':''" @click="navigation('/site/admin')">管理员列表</li>
						 </ul>
					 </li>
				 </ul>
			</div>
			<div class="flex-box mr-30">
				<!-- 页头用户信息 -->
				<div class="flex ptb-20">
					<ul class="flex">
						<li class="title-icon"><img src="../../static/title-icon.png" /></li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/basic'">基本信息</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/rule'">菜单规则</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/role'">角色管理</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/admin'">管理员列表</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/setadmin'">编辑管理员</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/case'">案例管理</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/site/roledetail'">角色详情</li>

					</ul>
					<ul class="flex">
						<li class="flex">
							<div class="site-head radius-all flex-c"><img :src="avatar" alt="" /></div>
							<div class="ml-8">
								<p class="f-18 c-ff">{{nickname}}</p>
								<p class="f-14 c-cc">{{username}}，欢迎您</p>
							</div>
						</li>
						<li class="close-icon ml-30"><img src="../../static/close-icon.png" /></li>
					</ul>
				</div>
				<!-- 主要表格内容 -->
				<router-view></router-view>

			</div>
			
			<div class="model-line-bg right"><img src="../../static/left-line.png" alt="" /></div>
		</div>


		<!-- 设备对话框 -->
	<!-- 	 <transition name="fade">
			 <div class="model-pop fixed"></div>
		 </transition> -->
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			hash: '#/equip/model',
			username: '',
			nickname: '',
			avatar: '../../static/moren-head.jpg',
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		let hashUrl = window.location.hash
		let index = hashUrl.indexOf('?')
		this.hash = index == -1?hashUrl:hashUrl.substr(0,index)
	},
	
	mounted () {
		let _this = this
		_this.username = localStorage.getItem('username')
		_this.nickname = localStorage.getItem('nickname')
		_this.avatar = localStorage.getItem('avatar') || '../../static/lebron.jpg'
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		}
	},
	
	watch: {
		$route (to,from) {
			// console.log(to,from)
			this.hash = '#' + to.path
		}
	}
}
</script>

<style lang="less" >

</style>
