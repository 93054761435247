<template>
  <div class="site-rolede data-box">
		<ul class="  pl-20 pt-25 refund-add" >
			<li class="flex flex-x-s ">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">上级角色</div>
				<div>
					 <Select v-model="group" style="width:500px" class="gz" @on-change="changegroup">
								<Option v-for="item in list_group"  :value="item.id" :key="item.id">{{item.title}}</Option>
						</Select>
				</div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">权限名称</div>
				<div><Input v-model="title" placeholder="请填写权限名称" style="width: 500px"  class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">是否是菜单</div>
				<div>
					<RadioGroup v-model="ismenu" class="c-48c" @on-change="changeismenu">
							<Radio label="1" > <span>是</span></Radio>
							<Radio label="0" > <span>否</span></Radio>
					</RadioGroup>
				</div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff">规则路径</div>
				<div><Input v-model="name" placeholder="请填入规则路径" style="width: 500px"  class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff">路由</div>
				<div><Input v-model="route" placeholder="请填入路由" style="width: 500px"  class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff">排序</div>
				<div><Input v-model="weigh" placeholder="请填入排序" type="number" style="width: 500px"  class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">状态</div>
				<div>
					<RadioGroup v-model="ismenu" class="c-48c" @on-change="changestate">
							<Radio label="1" > <span>显示</span></Radio>
							<Radio label="0" > <span>隐藏</span></Radio>
					</RadioGroup>
				</div>
			</li>
			<li class="flex flex-x-s flex-y-s  mt-50">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff"></div>
				<div class="submit-btn f-18 c-ff t-c shou" @click="bindsubmit">提交</div>
				<div class="submit-btn f-18 c-ff t-c ml-30 shou" @click="navigationBack">返回</div>
			</li>
		</ul>
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			group: 0,
			show: true,
			title: '',
			name: '',
			route: '',
			weigh: '',
			list_group: [],  // 角色列表
			// role: null,  // 角色信息
			rule_tree: [],  // 权限信息
			access_ids: '',
			ismenu: '1',
			status: '1'
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
	},
	
	mounted () {
		this.releinfo()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		navigationBack () {
			this.$router.go(-1)
		},
		changeismenu (value) {
			this.ismenu = value
		},
		changestate (value) {
			this.status = value
		},
		
		// 新建角色
		async bindsubmit () {
			let _this = this,
					{ group, title, name, route,weigh,ismenu,status } = _this;
			if (!title) {
				return _this.util.showMsg('warning', '请填写权限名称',1.5)
			}
			if (!name) {
				return _this.util.showMsg('warning', '请选择权限规则',1.5)
			}
			if (!route) {
				return _this.util.showMsg('warning', '请选择权限路由',1.5)
			}
			const res = await _this.post('auth.access/add', {pid:group, name, title, route, weigh: weigh-0,ismenu: ismenu-0,status: status-0})
			_this.navigation('/site/rule')
		},
		
		// 角色信息
		async releinfo () {
			let _this = this
			const res = await _this.get('auth.access/add', {})
			res.list_rule.map( x => {
				x.title = x.title.replace(/&nbsp;/g, '  ')
			})
			// _this.group = res.list_rule[0].id
			_this.list_group = res.list_rule
		},
		
		// 切换角色
		changegroup (value) {
			this.group = value
		},
		changerules (arr) {
			let _this = this
			let ar = arr.map(x => x.id)
			_this.access_ids = ar.join(',')
		},
		// 角色变了 对应不同的权限
		async quanxianInfo (role_id) {
			let _this = this
			const res = await _this.get('auth.role/roletree', { role_id })
			_this.rule_tree = res.list_rule
		},
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.site-rolede {
		.refund-add-title {
			width: 550rem;
		}
		.refund-add-title.must {
			
		}
		.refund-add-title.must::after {
			content: '*';
			color: #F16252;
			margin-left: 8rem;
			font-size: 16rem;
		}
		.b-b {
			border-bottom: 1px solid #01A9AA;
		}
		.ivu-date-picker-focused input,.ivu-input:hover {
			box-shadow:none;
		}
		
		.gz .ivu-select-selection {
			border: none;
			border-bottom: 1px solid #48CBCC;
			display: block;
		}
		.upload-img {
			width: 150rem;
			height: 150rem;
			border: 1px solid #48CBCC;
			border-radius: 4rem;
		}
		.iconadd1 {
			color: #48CBCC;
			font-size: 40rem;
		}
		.submit-btn {
			width: 140rem;
			line-height: 40rem;
			height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
		
		// 树样式
		.ivu-tree-title,.ivu-tree-arrow-open i,.ivu-tree-arrow i {
			color: #48CBCC;
		}
		.ivu-checkbox-checked .ivu-checkbox-inner,.ivu-checkbox-indeterminate .ivu-checkbox-inner {
			border-color: #48CBCC;
			background-color: #48CBCC;
		}
}
</style>
