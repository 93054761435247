<template>
  <div>
			<div class="map-box-list  flex">
				<!-- 左侧列表 -->
				<div class="map-list mr-15">
					<!-- 标签 -->
					<ul class="tabs-title flex flex-x-s t-c shou">
						<li @click="navigation('/equip/map')">项目用户</li>
						<li class="active" @click="navigation('/equip/map/list')">普通用户</li>
					</ul>
					<div class="pl-15 pr-20 mt-25">
						<Input v-model="search" class="form-ipt" placeholder="请输入名称">
								<Button slot="append" icon="ios-search"></Button>
						</Input>
					</div>
					<div class="plr-30 mt-20 f-16 bold c-10b">普通用户设备（{{total}}）</div>
					<div class="map-pro mt-10 plr-30">
						<ul   class="f-14">
							<li v-for="item,index in data1" @click="changeNav(index)" :class="nav_index == index?'c-48c active relative':' c-ff'">{{item.category.name}}</li>
						</ul>
					</div>
					<!-- 分页 -->
					<div class="flex flex-x-e mt-50 pr-30">
						<p class="f-14 c-ff mr-20">总记录：{{total}}</p>
						<Page :page-size="per_page" :total="total" @on-change="changepage" />
					</div>
				</div>
				<!-- 右侧项目地图 -->
				<div class="flex-box map flex flex-dir-col flex-y-s">
					<div class="plr-20 ptb-15 f-16 bold c-ff">项目分布图</div>
					<div class="flex-box w100">
						<baidu-map class="map-detail" :center="center" :zoom="15" @ready="handler" mapType="BMAP_SATELLITE_MAP">
							<!-- 自定义覆盖物 -->
							<bm-overlay
							  ref="customOverlay"
							  :class="{sample: true}"
							  pane="labelPane"
							  @draw="draw">
							<div class="relative">
								<div @click="shebeiPop"><img src="../static/address.png" alt="" /></div>
								
								<div class="sb-pop-box absolute" v-if="pop_device" :class="ishow_eq&&eqdetail.category?'op1': ''">
									<ul class="pt-20 pr-30 sb-title-box flex flex-y-s">
										<li class="bg f-20 c-ff pl-40" v-if="eqdetail.category">{{eqdetail.category.name}}</li>
										<li class="iconfont iconclose1 c-48c f-16" @click="showSBpop"></li>
									</ul>
									<div class="sb-content-box">
										<img class="eq-img" v-if="eqdetail.category" :src="eqdetail.category.image.file_path" alt="" />
										<!-- 错误劲爆 -->
										<div class="flex" v-if="pop_err_now">
											<div class="flex-c warning-title"><span class="iconfont iconwarnning mr-10" style="color: #F56E6E;"></span><span class="f-16" style="color: #F56E6E;">故障信息</span></div>
											<div class="flex-box warning-con plr-10 onelist f-14 c-ff">故障: <span  style="color: #F56E6E;" class="mr-10">{{pop_err_now.description || ''}}</span> 时间: {{pop_err_now.create_time}}</div>
										</div>
										<!-- 温度 -->
										<ul class="eq-wend flex flex-y-s plr-15 pb-5 pt-10">
											<li class="flex">
												<div class="c-ff mr-30">
													<p class="f-12">当前气温：</p>
													<p class="bold"><span class="f-24">{{dew}}</span><span class="f-14">℃</span></p>
												</div>
												<div class="c-ff" v-if="pop_device.status_info">
													<p class="f-12">工作温度：</p>
													<p class="bold"><span class="f-24">{{pop_device.status_info.work_temp}}</span><span class="f-14">℃</span></p>
												</div>
											</li>
											<li></li>
										</ul>
										<!-- 信息 -->
										<ul class="flex eq-info pt-20">
											<li class="mt-10 onelist f-14 c-ff plr-20" v-if="pop_project">
												<span>所属项目：</span>
												<span class="c-15e">{{pop_project.name}}</span>
											</li>
											<li class="mt-10 onelist f-14 c-ff plr-20">
												<span>设备编号：</span>
												<span class="c-15e">{{pop_device.code}}</span>
											</li>
											<li class="mt-10 onelist f-14 c-ff plr-20" v-if="pop_device.category">
												<span>设备名称：</span>
												<span class="c-15e">{{pop_device.category.name}}</span>
											</li>
											<li class="mt-10 onelist f-14 c-ff plr-20">
												<span>报修记录：</span>
												<span class="c-15e">{{pop_device.num_repair}}次</span>
											</li>
											<li class="mt-10 onelist f-14 c-ff plr-20">
												<span>安装日期：</span>
												<span class="c-15e">{{pop_device.create_time.substr(0,10)}}</span>
											</li>
											<li class="mt-10 onelist f-14 c-ff plr-20">
												<span>开机频率：</span>
												<span class="c-15e">{{pop_device.times_open}}次/月</span>
											</li>
											<li class="mt-10 onelist f-14 c-ff plr-20" style="width: 100%;">
												<span>设备位置：</span>
												<span class="c-15e">{{pop_device.address}}</span>
											</li>
										</ul>
										<div class="flex flex-x-e pt-40 pb-20 pr-20" @click="navigationTo(pop_device.category.type, pop_device.id)">
											<div class="eq-enter-btn t-c f-18 c-ff">进入设备</div>
										</div>
									</div>
									
								</div>
							</div>
							</bm-overlay>
						</baidu-map>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import myOverlay from './myoverly.vue'
export default {
  data () {
    return {
			value13:'',
			longitude: 0,
			center: {},
			latitude: 0,
			data1: [],
			nav_index: 0,
			total: 0,
			per_page: 0,
			search: '',
			eqdetail: '',
			ishow_eq: false,
			pop_device: null,
			pop_err_now: {},
			pop_project: {},
			dew: 0,
		}
  },
	
	components: {
		myOverlay
	},

  created () {
	},

	mounted () {
		this.eqlistData()
	},
	
  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		// 分别跳转不同详情
		navigationTo (type,id) {
			if (type == 0 || type == 1) {
				this.$router.push({path: '/equip/list/detail', query: {id}})
			} else if (type == 2) {
				this.$router.push({path: '/equip/list/detail1', query: {id}})
			} else {
				this.$router.push({path: '/equip/list/detail2', query: {id}})
			}
		},
		
		// 获取设备列表
		async eqlistData () {
			let _this = this,
					{  search, page } = _this;
			const res = await _this.get('device/lists', { search, page})
			console.log('设备列表',res.list.data);
			_this.data1 = res.list.data
			_this.changeMap()
			_this.total = res.list.total - 0
			_this.per_page = res.list.per_page -0
		},
		
		// 获取弹窗详情
		async shebeiPop () {
			let _this = this
			const res = await _this.get('device/detail_sp', {device_id: _this.device_id})
			const res1 = await this.get('basic/weather', {device_id: this.device_id})
			console.log(res,res1);
			this.dew = res1.dew
			_this.pop_device = res.device
			_this.pop_err_now = res.err_now
			_this.pop_project = res.project
			_this.showSBpop()
		},
		
		// 地图模式显示标注点
		changeMap () {
			this.longitude = this.data1[this.nav_index].longitude - 0
			this.latitude = this.data1[this.nav_index].latitude - 0
			this.eqdetail = this.data1[this.nav_index]
			this.device_id = this.data1[this.nav_index].id
			this.handler()
		},
		changeNav (index) {
			this.nav_index = index
			this.ishow_eq = false
			this.changeMap()
			this.handler()
		},
		
		handler () {
			// console.log(4);
			// return
			// {lng: 118.804623, lat: 32.319819}
			this.center = {lng: this.longitude, lat: this.latitude}
			// console.log(this.center);
		},
		
		// 分页
		changepage (page) {
			this.page = page
			this.eqlistData()
		},
		
	
		draw ({ el, BMap, map }) {
		  const { longitude, latitude } = this
		  const pixel = map.pointToOverlayPixel(new BMap.Point(longitude, latitude))
		  el.style.left = pixel.x - 16 + 'px'
		  el.style.top = pixel.y - 16 + 'px'
		},
		
		showSBpop () {
			this.ishow_eq = !this.ishow_eq
		}

	},
	
	watch: {
	  longitude: {
	    handler () {
				console.log(1121221);
	      this.$refs.customOverlay.reload()  // 当位置发生变化时，重新渲染，内部会调用draw
	    },
	    deep: true
	  },
		latitude: {
		  handler () {
				console.log(1121221);
		    this.$refs.customOverlay.reload()  // 当位置发生变化时，重新渲染，内部会调用draw
		  },
		  deep: true
		}
	},
}
</script>

<style lang="less" >
.map-box-list{
	 >div {
		 height: 790rem;
	 }
	.flex-box.map {
			background: rgba(1, 169, 170, 0.1);
		}
	.map-list {
		width: 280rem;
		background: url(../static/table-bg.png) no-repeat bottom right ;
		background-color: rgba(1, 169, 170, 0.1);
		background-size: 655px 327rem;
	
	}
	
	// 标签页
	.tabs-title {
		li {
			width: 120rem;
			height: 52rem;
			line-height: 52rem;
			border: 1px solid #48CBCC;
			color: #fff;
			opacity: 0.6;
		}
		li.active {
			background: linear-gradient(0deg, rgba(1, 169, 170, 0.74) 0%, rgba(72, 203, 204, 0.5) 100%);
			opacity: 1;
		}
	}
	.map-pro {
		ul {
			color: #fff;
			opacity: .6;
			// padding-left: 50rem;
		}
		li {
			margin-top: 10rem;
			cursor: pointer;
		}
		.active::before {
			content: '';
			display: block;
			width: 5rem;
			height: 5rem;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			transform: translateY(-40%);
			left: -15rem;
			background-color: #48CBCC;
		}
		.ivu-collapse-simple,.ivu-collapse,.ivu-collapse>.ivu-collapse-item,.ivu-collapse-content {
			background-color: rgba(0,0,0,0);
			border: none;
		}
		.ivu-collapse-header {
			font-size: 16rem;
			color: #fff;
			opacity: .6;
		}
		.ivu-collapse-item-active .ivu-collapse-header{
			font-size: 16rem;
			color: #fff;
			opacity: 1;
		}
		
	}
	.map-detail {
		height: 100%;
	}
	
	.sample {
	  width: 43rem;
		height: 63rem;
	}
	.sample.active {
	  // background: rgba(0,0,0,0.75);
	  // color: #fff;
	}
}

.warning-title {
	width: 120rem;
	height: 44rem;
	background: rgba(1, 169, 170, 0.2);
}
.warning-con {
	height: 44rem;
	// background: #013D40;
	line-height: 44rem;
}

.op1 {opacity: 1!important;}
.sb-pop-box {
	transition: opacity .3s; 
	left: 60rem;
	bottom: 0%;
	opacity: 0;
	transform: translateY(50%);
	width: 472rem;
	border: 1px solid #01A9AA;
	background: rgba(12,70,74, .9);
	.sb-title-box {
		.bg {
			width: 226rem;
			height: 42rem;
			line-height: 42rem;
			background: url(../static/title-bg.png) no-repeat center;
			background-size: 100%;
		}
		// height: 76rem;
		// background: url(../static/sb-pop.png) no-repeat center;
		// background-size: 100%;
	}
	.sb-content-box {
		// background: rgba(0,77,83,.9);
		.eq-img {
			display: block;
			max-width: 440rem;
			max-height: 180rem;
			margin: 10px auto;
		}
		.eq-wend {
			// height: 78rem;
			background: #013D40;
		}
		.eq-info li {
			width: 50%;
		}
		.eq-enter-btn {
			width: 129rem;
			height: 40rem;
			line-height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
	}
}
</style>
