<template>
  <div class="user">
		<top-header></top-header>
		
		<div class=" model-box flex flex-y-s"  v-if="0">
			<div class="model-line-bg"><img src="../../static/left-line.png" alt="" /></div>
			
			<!-- 左侧导航 -->
			<div class="ml-15 meau-box relative mr-20">
				 <div class="meau-bg absolute top"><img src="../../static/meal-bg.png" /></div>
				 <div class="meau-bg right absolute"><img src="../../static/meal-bg.png" /></div>
				 <ul class="plr-20 relative" style="z-index: 100;">
					 <li class="mt-15">
						 <div class="meau-detail  f-16 t-c" :class="hash=='#/user/list'?'active':''" @click="navigation('/user/list')">普通用户</div>
						 <ul class=" c-48c  t-r twolevel" :class="(hash=='#/user/list')?'is-show':''">
						 	<li class="relative t-l two-level shou " :class="(hash=='#/user/list')?'active':''" @click="navigation('/user/list')">用户列表</li>
						 </ul>
					 </li>
					 <li class="mt-15">
						 <div class="meau-detail f-16 t-c" :class="(hash=='#/user/zone' || hash=='#/user/source' || hash=='#/user/habit')?'active':''" @click="navigation('/user/zone')">统计分析</div>
						 <ul class=" c-48c  t-r twolevel" :class="(hash=='#/user/zone' || hash=='#/user/source' || hash=='#/user/habit')?'is-show':''">
								<li class="relative two-level t-l shou " :class="(hash=='#/user/zone')?'active':''" @click="navigation('/user/zone')">分布统计</li>
								<li class="relative two-level t-l shou mt-15" :class="(hash=='#/user/source')?'active':''" @click="navigation('/user/source')">来源统计</li>
								<li class="relative two-level t-l shou mt-15" :class="(hash=='#/user/habit')?'active':''" @click="navigation('/user/habit')">使用习惯统计</li>
						 </ul>
					 </li>
				 </ul>
			</div>
			<div class="flex-box mr-30">
				<!-- 页头用户信息 -->
				<div class="flex ptb-20">
					<ul class="flex">
						<li class="title-icon"><img src="../../static/title-icon.png" /></li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/user/list'">用户列表</li>
						<!-- <li class="f-20 c-ff bold ml-10" v-show="hash=='#/user/list'">项目列表</li> -->
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/user/follow'">跟进记录</li>
						<li class="f-20 c-ff bold ml-10" v-show="hash=='#/user/log'">操作日志</li>
					</ul>
					<ul class="flex">
						<li class="flex">
							<div class="user-head radius-all flex-c"><img :src="avatar" alt="" /></div>
							<div class="ml-8">
								<p class="f-18 c-ff">{{nickname}}</p>
								<p class="f-14 c-cc">{{username}}，欢迎您</p>
							</div>
						</li>
						<li class="close-icon ml-30"><img src="../../static/close-icon.png" /></li>
					</ul>
				</div>
				<!-- 主要表格内容 -->
				<router-view></router-view>
<!-- 				<div class="data-box pl-20 pt-25">
					<ul class="flex flex-x-s">
						<li class="table-btn flex flex-c plr-20"><span class="iconfont iconadd1 mr-5 f-12 c-ff"></span><span class="f-14 c-ff">新增</span></li>
						<li class="ml-15">
							<Input v-model="value13" class="form-ipt" placeholder="请输入名称">
									<Button slot="append" icon="ios-search"></Button>
							</Input>
						</li>
					</ul>
					<div class="table-box">
						<Table :columns="columns1" :data="data1" class="" disabled-hover>
							<template slot-scope="{ row }" slot="type">
									<div class="f-14 c-ff t-c table-type plr-10">温控器</div>
							</template>
							<template slot-scope="{row}" slot="state">
									<i-switch true-color="#209FFB" false-color="#F56E6E" size="large">
											<span slot="open">开</span>
											<span slot="close">关</span>
									</i-switch>
							</template>
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-c">
										<li class="handle-edit flex-c">
											<span class="iconfont iconedit1"></span>
											<span class="f-14">编辑</span>
										</li>
										<li class="handle-del flex-c ml-8">
											<span class="iconfont icondelect"></span>
											<span class="f-14">删除</span>
										</li>
									</ul>
							</template>
						</Table>
					</div>
				</div> -->
			</div>
			
			<div class="model-line-bg right"><img src="../../static/left-line.png" alt="" /></div>
		</div>


		<!-- 设备对话框 -->
	<!-- 	 <transition name="fade">
			 <div class="model-pop fixed"></div>
		 </transition> -->
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			hash: '#/equip/model',
			username: '',
			nickname: '',
			avatar: '../../static/moren-head.jpg',
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		let hashUrl = window.location.hash
		let index = hashUrl.indexOf('?')
		this.hash = index == -1?hashUrl:hashUrl.substr(0,index)
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		}
	},
	
	mounted () {
			let _this = this
			_this.username = localStorage.getItem('username')
			_this.nickname = localStorage.getItem('nickname')
			_this.avatar = localStorage.getItem('avatar') || '../../static/moren-head.jpg'
	},
	
	watch: {
		$route (to,from) {
			// console.log(to,from)
			this.hash = '#' + to.path
		}
	}
}
</script>

<style lang="less" >

</style>
