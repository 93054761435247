<template>
  <div class="site-rolede data-box">
		<ul class="  pl-20 pt-25 refund-add" >
			<li class="flex flex-x-s ">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">上级角色</div>
				<div>
					 <Select v-model="group" style="width:500px" class="gz" @on-change="changegroup">
								<Option v-for="item in list_group"  :value="item.id" :key="item.id">{{item.name}}</Option>
						</Select>
				</div>
			</li>
			
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r must relative f-14 c-ff">角色名称</div>
				<div><Input v-model="name" placeholder="请填写角色名称" style="width: 500px"  class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-25">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff">角色说明</div>
				<div><Input v-model="desc" placeholder="请填入角色说明" style="width: 500px"  class="b-b" /></div>
			</li>
			<li class="flex flex-x-s mt-25 flex-y-s">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff pt-8">权限列表</div>
				<Tree :data="rule_tree" show-checkbox @on-check-change="changerules"></Tree>
			</li>
			<li class="flex flex-x-s mt-25 flex-y-s">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff pt-5">身份</div>
				<Radio-group v-model="is_admin" @on-change="changeAdmin">
					<Radio label="1"  class="radio">管理员</Radio>
					<Radio label="0" class="radio">项目管理员</Radio>
				</Radio-group>
			</li>
			
			<li class="flex flex-x-s mt-25 flex-y-s" v-if="is_admin==0">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff pt-8">项目列表</div>
				<Checkbox-group v-model="project_select" class=" mt-50 ml-30" @on-change="checkAllGroupChange">
					<Checkbox class="che_box" :label="item.project_id" :vlaue="item.project_id" v-for="(item,index) in project">{{item.name}}</Checkbox>
                </Checkbox-group>
			</li>
			<li class="flex flex-x-s flex-y-s  mt-50 mb-50">
				<div class="refund-add-title mr-30 t-r  relative f-14 c-ff"></div>
				<div class="submit-btn f-18 c-ff t-c shou" @click="bindsubmit">提交</div>
				<div class="submit-btn f-18 c-ff t-c ml-30 shou" @click="navigationBack">返回</div>
			</li>
		</ul>
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
		   is_admin:"1",
		   project_select:[],
		   project:[],
			group: 0,
			show: true,
			name: '',
			desc: '',
			list_group: [],  // 角色列表
			// role: null,  // 角色信息
			rule_tree: [],  // 权限信息
			access_ids: '',
			project_ids:''
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
	},
	
	mounted () {
		this.releinfo()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		navigationBack () {
			this.$router.go(-1)
		},
		
		// 新建角色
		async bindsubmit () {
			let _this = this,
					{ group, name, desc, access_ids ,project_ids,is_admin} = _this;
			console.log(is_admin==1)
			if(is_admin==1){project_ids=''}
		
			if (!name) {
				return _this.util.showMsg('warning', '请填写角色名称',1.5)
			}
			if (!access_ids) {
				return _this.util.showMsg('warning', '请选择相关权限',1.5)
			}
			const res = await _this.post('auth.role/add', {pid:group, name, desc, access_ids,project_ids,is_admin})
			_this.navigation('/site/role')
		},
		
		// 角色信息
		async releinfo () {
			let _this = this
			const res = await _this.get('auth.role/add', {})
			_this.project = res.project;
			res.list_role.map( x => {
				x.name = x.name.replace(/&nbsp;/g, '  ')
			})
			_this.group = res.list_role[0].id
			_this.quanxianInfo(res.list_role[0].id)
			_this.list_group = res.list_role
		},
		
		// 切换角色
		changegroup (value) {
			this.group = value
			this.quanxianInfo(value)
		},
		changerules (arr) {
			let _this = this
			let ar = arr.map(x => x.id)
			_this.access_ids = ar.join(',')
		},
		changeAdmin(e){
		  let _this = this;
		  _this.is_admin = e;
		  
		},
		checkAllGroupChange(e){
			let _this = this
			let ar = e.map(x => x)
			_this.project_ids = ar.join(',')
         
		},


		// 角色变了 对应不同的权限
		async quanxianInfo (role_id) {
			let _this = this
			const res = await _this.get('auth.role/roletree', { role_id })
			_this.rule_tree = res.list_rule
		},
	}
}
</script>

<style lang="less" >
td.name-color {
	color: #48CBCC!important;
}
.radio{
	    font-size: 14px;
	    font-family: PingFang SC;
        color: #48CBCC;
}
.che_box{
	    width: 200px;
		margin-right: 20px;
		margin-bottom: 20px;
		font-size: 13px;
	    font-family: PingFang SC;
        color: #48CBCC;
}
.site-rolede {
		.refund-add-title {
			width: 550rem;
		}
		.refund-add-title.must {
			
		}
		.refund-add-title.must::after {
			content: '*';
			color: #F16252;
			margin-left: 8rem;
			font-size: 16rem;
		}
		.b-b {
			border-bottom: 1px solid #01A9AA;
		}
		.ivu-date-picker-focused input,.ivu-input:hover {
			box-shadow:none;
		}
		
		.gz .ivu-select-selection {
			border: none;
			border-bottom: 1px solid #48CBCC;
			display: block;
		}
		.upload-img {
			width: 150rem;
			height: 150rem;
			border: 1px solid #48CBCC;
			border-radius: 4rem;
		}
		.iconadd1 {
			color: #48CBCC;
			font-size: 40rem;
		}
		.submit-btn {
			width: 140rem;
			line-height: 40rem;
			height: 40rem;
			border: 1px solid #01A9AA;
			background: linear-gradient(360deg, rgba(1, 169, 170, 0.74) 0%, rgba(1, 85, 85, 0.59) 100%);
		}
		
		// 树样式
		.ivu-tree-title,.ivu-tree-arrow-open i,.ivu-tree-arrow i {
			color: #48CBCC;
		}
		.ivu-checkbox-checked .ivu-checkbox-inner,.ivu-checkbox-indeterminate .ivu-checkbox-inner {
			border-color: #48CBCC;
			background-color: #48CBCC;
		}
}
</style>
