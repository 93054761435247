<template>
  <div>

				<div class="data-box pl-20  pr-20 pt-25">
					<div class="">
						<!-- <div class="" style="width: 300rem;">
							<Input v-model="value13" class="form-ipt" placeholder="请输入名称">
									<Button slot="append" icon="ios-search"></Button>
							</Input>
						</div> -->
						<ul class="flex flex-x-e mt-20">
							<li class="f-14 c-ff mr-5">数值：</li>
							<li class="">
								<Input v-model="amount_beg" class="form-ipt" placeholder="来电数量起始值">
								</Input>
							</li>
							<li class="f-16 c-ff mlr-8">-</li>
							<li class="mr-20">
								<Input v-model="amount_end" class="form-ipt" placeholder="来电数量截止值">
								</Input>
							</li>
							<li class="f-14 c-ff mr-5">来电时间：</li>
							<li class="mr-10">
								<DatePicker type="daterange" v-model="time" @on-change="changedate" placement="bottom-end" placeholder="请选择日期" class="w100" style="border: 1px solid #48CBCC;"></DatePicker>
							</li>
							<li class="">
								<Input v-model="search" class="form-ipt" placeholder="请输入手机号">
										<Button slot="append" @click="loglist(1)" icon="ios-search"></Button>
								</Input>
							</li>
						</ul>
					</div>
					
					<div class="table-box">
						<Table :row-class-name="rowClassName"  :columns="columns1" :data="data1" class="mt-15 relative" disabled-hover >
							<template slot-scope="{row}" slot="handle">
									<ul class="flex flex-x-s">
										<li class="handle-edit flex-c  mr-10 shou" @click="showDetail(row.mobile)">
											<span class="iconfont icondingdan1"></span>
											<span class="f-14">详情</span>
										</li>
									<!-- 	<li class="handle-add flex-c">
											<span class="iconfont iconedit1 mr-5"></span>
											<span class="f-14">创建工单</span>
										</li> -->
									</ul>
							</template>
							<template slot-scope="{ row }" slot="name">
									<div class="f-14 c-ff ">{{row.remark[0]?row.remark[0]:row.remark[1]}}</div>
							</template>
						</Table>
					</div>
					
					<!-- 分页 -->
					<div class="flex flex-x-e mt-20">
						<p class="f-14 c-ff mr-20 mt-10">总记录：{{total_pro}}</p>
						<Page :page-size="per_pro" :total="total_pro" @on-change="changepage1" />
					</div>
				</div>
				
				
				<!-- 来电详情 -->
				<Modal
						:footer-hide="true"
						v-model="is_show_detail"
						:closable="false"
						:mask-closable="false"
					>
					<span slot="title"></span>
					<div class="model-lddetail  pb-30">
						<ul class="flex pt-20 flex-y-s">
							<li class="title-text c-ff f-20">{{mobile}}来电</li>
							<li class="iconfont iconclose1 c-48c pr-20 shou" @click="changeModal('is_show_detail')"></li>
						</ul>
						<!-- 下面就是主要客户详情内容 -->
						<div class="plr-20 pt-30">
							<ul class="content">
								<li class="client-title f-16 c-ff plr-20 relative">
									<span>基本信息</span> 
									<span class="iconfont iconsheji1 f-16 c-48c ml-10 shou" @click="changeModal('ldEdit')"></span>
									<div class="absolute client-save t-c c-ff f-12 plr-15 shou" @click="saveRemark" v-show="!ldEdit">保存</div>
								</li>
								<li class="client-con f-16 c-ff plr-20 flex">
									<div class="flex-box">备注名称：<input class="c-ff" ref="ldedit" :disabled="ldEdit" type="text" v-model="rowname" placeholder="点击编辑按钮添加备注" /></div>
									<div class="flex-box">客户说明：<input class="c-ff"  :disabled="ldEdit" type="text" v-model="rowremark" placeholder="点击编辑按钮添加备注" /></div>
								</li>
								<li class="client-title f-16 c-ff plr-20" v-if="user">用户信息</li>
								<li class="client-con f-16 c-ff plr-20 flex" v-if="user">
									<div class="flex-box flex flex-x-s">
										<span>姓名：{{user.nickname}}</span>
										<span class="plr-8">|</span>
										<div><input class="c-ff" :disabled="userEdit" @blur="saveuserName(user.id)" ref="useredit" type="text" v-model="usertruename" /></div>
										<span class="iconfont iconsheji1 f-16 c-48c ml-10 shou" @click="changeModal('userEdit')"></span>
									</div>
									<div class="flex-box">手机号：{{user.mobile}}</div>
								</li>
								<li class="client-con f-16 c-ff plr-20 flex" v-if="user">
									<div class="flex-box">注册时间：{{user.create_time}}</div>
								</li>
								<li class="client-title f-16 c-ff plr-20" v-show="list_device.length">连接设备</li>
								<li class="client-link plr-20 pb-20 f-16 c-ff" v-show="list_device.length">
									<ul>
										<li class="pt-20" v-for="item,index in list_device" :key="index">
											<div>{{index+1}}. 设备编号：{{item.code}}  <span class="ml-10">设备型号：{{item.category.name}} </span></div>
											<div class="mt-8">设备地址：{{item.address}}</div>
											<div class="mt-8">维修次数：总次数：{{item.times_repair[0]}} 当前进行中：{{item.times_repair[1]}}</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</Modal>
	
				<!-- 这是其他设置 -->
<!-- 				<Modal
						:footer-hide="true"
						v-model="is_show_qt"
						:closable="false"
						width="471"
						:mask-closable="false"
					>
					<span slot="title"></span>
					<div class="model-pop-jn eq-jn plr-20">
						<ul class="flex pt-20 flex-y-s flex-x-e">
							<li class="iconfont iconclose1 c-48c shou" @click="changeModal('is_show_qt')"></li>
						</ul>
						<div class="flex-box flex" style="padding-top: 50rem;" >
							<span class="f-16 c-ff  mr-20" >{{qita.desc}}</span>
							<Input v-model="qita.value" @on-keyup="changeqita" type="number" class="form-ipt flex-box" placeholder="请填写">
							</Input>
						</div>
						<li class="flex flex-c mt-50" >
							<div class="quxiao f-18 t-c c-10b shou mr-30" @click="changeModal('is_show_qt')">取消</div>
							<div class="queding f-18 c-ff t-c shou" @click="submitwind">确认</div>
						</li>
					</div>
				</Modal> -->
	</div>
</template>

<script>
import topheader from '../../components/topheader.vue'
export default {
  data () {
    return {
			is_show_detail: false, // 控制详情显示 
			list_device: [], // 客户绑定设备
			user: {}, // 客户信息
			row: {}, // 备注及一些信息
			rowname: '',
			rowremark: '',
			usertruename: '',
			ldEdit: true,
			userEdit: true,
			mobile: '',
			amount_beg: '',
			amount_end: '',
			time: [],
			search: '',
			start_time: '',
			end_time: '',
			total_pro: 0,
			per_pro: 0,
			columns1: [
					{
							title: 'ID',
							key: 'id',
					},
					{
							title: '联系电话',
							key: 'mobile',
					},
					{
							title: '客户姓名',
							slot: 'name',
							className: 'name-color'
					},
					{
							title: '来电时间',
							key: 'create_time',
					},
					{
							title: '操作',
							slot: 'handle',
					}
			],
			data1: [],
			editname: '', // 备注内容
			editremark: '', // 备注备注1
		}
  },
	
	components: {
		topHeader: topheader
	},

  created () {
		
	},
	
	mounted () {
		this.loglist()
	},

  methods: {
		// 跳转路由
		navigation (path) {
			this.$router.push({path})
		},
		rowClassName (row, index) {
			if (index % 2 == 0) {
					return 'table-info-row';
			} else if (index % 2 != 0) {
					return 'table-error-row';
			}
			return '';
		},
		
		// 来点记录
		async loglist (page = 1) {
			let _this = this,
					{ search,start_time,end_time,amount_beg,amount_end } = _this;
			const res = await _this.get('phone_call/index', { page,search,start_time,end_time,amount_beg,amount_end })
			_this.data1 = res.list.data
			_this.total_pro = res.list.total
			_this.per_pro = res.list.per_page
		},
		
		async showDetail (mobile, is = false) {
			let _this = this
			const res = await _this.get('phone_call/detail', {mobile})
			// return console.log(res)
			_this.list_device = res.data.list_device || []
			_this.user = res.data.user || null
			_this.row = res.row ||  null
			_this.rowname = res.row?res.row.name:''
			_this.rowremark = res.row?res.row.remark:''
			_this.mobile = res.mobile || ''
			_this.usertruename = res.data.user?res.data.user.truename:''
			if (!is) {
				this.changeModal('is_show_detail')
			}
		},
		
		// 保存来电备注
		async saveRemark () {
			let _this = this,
					{ mobile, rowname, rowremark } = _this;
			if (!rowname) {
				return _this.util.showMsg('warning', '请输入备注名', 1.5, false)
			}
			const res = await _this.post('phone_call/remark', {mobile,clientname: rowname, remark:rowremark})
			return _this.util.showMsg('success', '保存成功', 1.5, false)
			this.showDetail(_this.mobile, true)
			// this.changeModal('ldEdit')
		},
		// 保存用户名
		async saveuserName (id) {
			let _this = this,
					{ usertruename } = _this;
			const res = await _this.post('user/editname', {id,name: usertruename})
			return _this.util.showMsg('success', '保存成功', 1.5, false)
		},
		
		changedate (e) {
			this.follow_start_time = e[0]
			this.follow_end_time = e[1]
			this.time = e
		},
		changepage1 (page) {
			this.loglist(page)
		},
		changeModal (type) {
			this[type] = !this[type]
			if (type == 'ldEdit') {
				this.$nextTick(() => {
					this.$refs.ldedit.focus()
				})
			}
			if (type == 'userEdit') {
				this.$nextTick(() => {
					this.$refs.useredit.focus()
				})
			}
		},
		
	}
}
</script>

<style lang="less" >
	td.name-color {
		color: #48CBCC!important;
	}
.model-line-bg {
	width: 15rem;
	height: 900rem;
	padding-top: 30rem;
}
.model-line-bg.right img{
	transform: rotateY(180deg);
}
.meau-box {
	width: 227rem;
	height: 880rem;
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.meal {
		width: 100%!important;
		background: none;
	}
	.meau-bg {
		width: 251rem;
		height: 59rem;
		left: -13rem;
	}
	.meau-bg.top {
		top: -12rem;
	}
	.meau-bg.right {
		width: 251rem;
		height: 59rem;
		bottom: -12rem;
		left: -13rem;
		transform: rotateX(180deg);
	}
	.meau-detail {
		height: 56rem;
		background: url(../../static/meau-bg.png) no-repeat center;
		background-size: 100%  100%;
		color: #48CBCC;
		line-height: 56rem;
		cursor: pointer;
	}
	.meau-detail.active {
		background: url(../../static/meau-bg-active.png) no-repeat center;
		background-size: 100%  100%;
		color: #FFFFFF;
	}
}

.title-icon {
	width: 17rem;
	height: 17rem;
}
.user-head {
	width: 52rem;
	height: 52rem;
	background: url(../../static/user-head-bg.png) no-repeat center;
	background-size: 100% 100%;
	img {
		width: 43rem;
		height: 43rem;
		border-radius: 50%;
	}
}
.close-icon {
	width: 23rem;
	height: 25rem;
}

// 主要列表样式
.table-box {
	
}
.data-box {
	height: 790rem;
	background: url(../../static/table-bg.png) no-repeat bottom right ;
	background-color: rgba(1, 169, 170, 0.1);
	background-size: 655px 327rem;
}
.form-ipt {
	background: rgba(1, 169, 170, 0.1);
	border: 1px solid #48CBCC;
	.ivu-input {
		border: none;
		color: #48CBCC;
		background-color: rgba(0,0,0,0)
	}
	.ivu-icon-ios-search,.ivu-input::placeholder {
		color: #48CBCC;
	}
	.ivu-input-group-append {
		button {
			background: rgba(1, 169, 170, 0.1);
		}
		background: rgba(1, 169, 170, 0.1);
		border: none;
		border-left: 1px solid #48CBCC;
	}
}
.ivu-table th {
	height: 60px!important;
	background-color: rgba(0, 0,0, .1)!important;
	border-bottom: none;
	color: #fff;
	font-size: 14rem;
	font-weight: bold;
}
.ivu-table td {
	height: 72rem;
	background: rgba(1, 169, 170, 0.2);
	border-bottom: 5px solid #111727;
	color: #fff;
	font-size: 14rem;
}
.ivu-table {
	background-color: rgba(0,0,0,0);
}
.ivu-table:before {
	background-color: rgba(0, 0,0, .1)!important;
}
.table-type {
	background-color: #5EB95E;
	height: 24rem;
	line-height: 24rem;
	display: inline-block;
}
.handle-edit, .handle-del {
	width: 70rem;
	height: 32rem;
	.f-14 {
		margin-left: 3rem;
	}
}
.handle-add {
	width: 99rem;
	height: 32rem;
	border: 1px solid #10BDC4;
	color: #10BDC4;
}
.handle-edit {
	border: 1px solid #74B8F4;
	color: #74B8F4;
}
.handle-del {
	border: 1px solid #F16252;
	color: #F16252;
}
// 弹窗
.model-pop {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 710rem;
	height: 478rem;
	background: url(../../static/data-bg.png) no-repeat center;
	background-size: 100% 100%;
}

.table-info-row {
	td {
		// border-bottom: none!important;
		background: rgba(1, 169, 170, 0.24)!important;
	}
}
.table-error-row {
	td {
		// border-bottom: none!important;
		background: rgba(1, 169, 170, 0.24)!important;
	}
}

// 弹窗样式
.model-lddetail {
	.content {
		border: 1px solid #027276;
		border-bottom: none;
	}
	width: 900rem;
	// height: 418rem;
	max-height: 700rem;
	overflow-y: auto;
	border: 1px solid #01A9AA;
	background: linear-gradient(360deg, #030909 0%, #004D53 100%);
	.title-text {
		width: 226rem;
		height: 43rem;
		line-height: 43rem;
		padding-left: 40rem;
		background: url(../../static/title-bg.png) no-repeat center;
		background-size: 100% 100%;
	}
	.client-title,.client-con {
		// width: 860px;
		height: 50rem;
		line-height: 50rem;
		background: rgba(1, 169, 170, 0.24);
		border-bottom: 1px solid #027276;
	}
	.client-con {
		background: rgba(1, 169, 170, 0.08);
	}
	.client-link {
		background: rgba(1, 169, 170, 0.08);
		border-bottom: 1px solid #027276;
	}
	.client-save {
		// width: 100px;
		height: 32rem;
		line-height: 32rem;
		top: 50%;
		transform: translateY(-50%);
		right: 30rem;
		// border: 1px solid #48CBCC;
		background: linear-gradient(360deg, rgba(1, 169, 170, 0) 0%, rgba(72, 203, 204, 0.5) 100%);
	}
}
.ivu-modal-content,.ivu-modal-body {
	background-color: rgba(0,0,0,0);
	padding: 0;
}
</style>
